import React, { useState } from "react";
import {
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Button,
} from "@mui/material";

import styles from "./DataTable.module.css";

function DataTable({
	data,
	onNextPage,
	onPreviousPage,
	onFirstPage,
	hasNextPage,
	hasPrevPage,
	onView,
	showActionBtn,
}) {
	const [clickedCellIndexes, setClickedCellIndexes] = useState({
		rowIndex: null,
		columnIndex: null,
	});
	if (data?.length === 0) {
		return (
			<div
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
				}}
			>
				No data is available
			</div>
		);
	}
	if (data?.data?.length === 0) {
		return (
			<div
				style={{
					width: "100%",
					height: "100%",
					display: "flex",
					justifyContent: "center",
				}}
			>
				No data is available
			</div>
		);
	}

	const handleRowClick = (rowIndex, columnIndex) => {
		if (
			data?.data[rowIndex][headers[columnIndex]]?.toString() !== undefined &&
			data?.data[rowIndex][headers[columnIndex]]?.toString() !== null
		) {
			navigator.clipboard
				.writeText(data?.data[rowIndex][headers[columnIndex]]?.toString())
				.then(
					() => {
						setClickedCellIndexes({
							rowIndex: rowIndex,
							columnIndex: columnIndex,
						});

						setTimeout(() => {
							setClickedCellIndexes({
								rowIndex: null,
								columnIndex: null,
							});
						}, 2000);
					},
					(error) => {
						console.error("Clipboard copy failed:", error);
					}
				);
		}
	};

	const headers =
		data && data?.data && data?.data[0] ? Object.keys(data?.data[0]) : [];

	if (showActionBtn && data?.data?.length>0 ) headers.unshift("Action");
	return (
		<>
			<TableContainer component={Paper}>
				<Table>
					<TableHead style={{ backgroundColor: "#f4f4f4" }}>
						<TableRow>
							{headers.map((header) => (
								<TableCell
									key={header}
									style={{
										border: "1px solid #ddd",
										fontWeight: "700",
										textAlign: "center",
									}}
								>
									{header}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{data?.data?.map((row, rowIndex) => (
							<TableRow
								key={rowIndex}
							>
								{headers.map((header, columnIndex) => (
									<TableCell
										key={header}
										style={{
											border: "1px solid #ddd",
											textAlign: "center",
											backgroundColor:
												clickedCellIndexes.rowIndex === rowIndex &&
												clickedCellIndexes.columnIndex === columnIndex
													? "green"
													: "white",
											cursor: header !== "Action" && "pointer",
											width: "fit-content",
										}}
										onClick={() => {
											if (header === "Action") {
												return;
											} else {
												handleRowClick(rowIndex, columnIndex);
											}
										}}
									>
										{header === "Action" ? (
											<button
												onClick={(e) => onView(row)}
												className={styles.viewBtn}
											>
												View
											</button>
										) : clickedCellIndexes.rowIndex === rowIndex &&
										  clickedCellIndexes.columnIndex === columnIndex ? (
											<span
												style={{
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													color: "#fff",
												}}
											>
												Copied to Clipboard
											</span>
										) : (
											row[header]?.toString()
										)}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{!hasPrevPage && !hasNextPage ? null : (
				<div className={styles.btnContainer}>
					{hasPrevPage && <Button onClick={onFirstPage}>&lt;&lt; First</Button>}
					{hasPrevPage && (
						<Button onClick={onPreviousPage}>&lt; Previous</Button>
					)}
					{hasNextPage && <Button onClick={onNextPage}>Next &gt;</Button>}
				</div>
			)}
		</>
	);
}

export default DataTable;
