import React from 'react';

import styles from './AppBar.module.css';
import back from '../../assets/back.png';

const AppBar = ({ editingExistingMessage, handleDeleteClick }) => {
  return (
    <div className={styles['appbar']}>
      <div className={styles['appbar-left']}>
        <img
          src={back}
          alt='back button'
          className={styles['back-button']}
          onClick={() => {
            window?.ReactNativeWebView?.postMessage('back');
          }}
        />
      </div>
      <h1 className={styles['title']}>Personal E-Message</h1>
      <div className={styles['appbar-right']}>
        {editingExistingMessage ? (
          <p className={styles['right-button']} onClick={handleDeleteClick}>
            Delete
          </p>
        ) : (
          <p
            className={styles['right-button']}
            onClick={() => {
              window?.ReactNativeWebView?.postMessage('skip');
            }}
          >
            Skip
          </p>
        )}
      </div>
    </div>
  );
};

export default AppBar;
