import React, { useEffect, useState } from 'react';
import { db } from '../../env/firebase';
import ClipLoader from 'react-spinners/ClipLoader';
import ChatroomTextInput from './ChatroomTextInput';
import ChatroomMessages from './ChatroomMessages';
import NavbarChat from '../feedscreen/NavbarChat';

import {
  fetchOlderMessagesAPICall,
  setUserNameAPICall,
  getMessagesAPICall,
} from '../../env/APImanager';

import '../../components/bootstrap.min.css';
import styles from './Chatroom.module.css';

const Chatroom = ({ match }) => {
  const feedID = match.params.id;
  const numOfMessagesPerLoad = 8;

  const [hasMore, setHasMore] = useState(true); // for the Load more button display

  const [messages, setMessages] = useState([]); // all the messages for each feed
  const [messagesLoading, setMessagesLoading] = useState(false); // for the spinner when the first 8 messages are being loaded
  const [loadingMore, setLoadingMore] = useState(false); // for the spinner when users click Load more button

  const [name, setName] = useState('');
  const [uid, setUid] = useState('');

  let search = window.location.search;
  let parameter = new URLSearchParams(search);

  const tokenId =
    parameter.get('tokenId') === null || parameter.get('tokenId') === 'null'
      ? null
      : parameter.get('tokenId');

  const appbarRes = parameter.get('appbar') || false;

  const appbar = appbarRes === 'true';

  useEffect(() => {
    window.scrollTo(0, 0);
    handleSetUserName();
    handleMessages();
  }, []);

  const handleMessages = async () => {
    let messagesList = [];

    let messagesRef = db
      .ref(`/social_feed_messages/${feedID}`)
      .orderByChild('sorter')
      .limitToFirst(numOfMessagesPerLoad);

    setMessagesLoading(true);

    const paginateKey = (Date.now() * -1).toString();
    messagesList = await getMessagesAPICall(feedID, paginateKey); // will get the first 8 messages from API call, not with listener

    setMessages(messagesList);
    setMessagesLoading(false);

    messagesRef.on('child_added', snapshot => {
      if (!snapshot.exists()) {
        setMessagesLoading(false);
        return null;
      } // do nothing and just return if there is no message

      let foundMessage = messagesList.find(
        message => message.id === snapshot.key
      );

      if (!foundMessage) {
        let obj = snapshot.val();
        obj['id'] = snapshot.key;
        messagesList.push(obj);
      }

      setMessages([]);
      setMessages(messagesList);
      setMessagesLoading(false);
    });

    return () => messagesRef.off();
  };

  const getOlderMessages = async () => {
    setLoadingMore(true);

    const paginateKey = (
      messages && messages[messages.length - 1].sorter + 1
    ).toString();

    const olderMessages = await fetchOlderMessagesAPICall(feedID, paginateKey);

    if (olderMessages.length < numOfMessagesPerLoad) {
      setHasMore(false);
    }

    setMessages([...messages, ...olderMessages]);

    setLoadingMore(false);
  };

  const handleSetUserName = async () => {
    const userRes = await setUserNameAPICall(tokenId);

    setName(userRes.data.data.name);
    setUid(userRes.data.data.uid);

    return true;
  };

  return (
    <>
      <NavbarChat appbar={appbar} />

      <section className={styles['main-comments']}>
        <ChatroomTextInput
          tokenid={tokenId}
          feedid={feedID}
          name={name}
          uid={uid}
          existingMessages={messages}
        />

        {messagesLoading && (
          <div className={styles['messages-loading']}>
            <ClipLoader size={40} color='#8585ff' />
          </div>
        )}
        <div id='comments' className={styles['comments']}>
          <div
            id='comments-scrollable-area'
            className={styles['comments-scrollable-area']}
          >
            <div className={styles['comments-area']}>
              {messages.length === 0 && !messagesLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <p className='mt-3'>This feed has no messages yet.</p>
                </div>
              ) : (
                messages
                  .sort((a, b) => {
                    return a.sorter - b.sorter;
                  })
                  .map(msg => (
                    <ChatroomMessages key={msg.creationTime} msg={msg} />
                  ))
              )}
            </div>

            <div className={styles['comments-load-more']}>
              {hasMore &&
                !messagesLoading &&
                messages.length >= numOfMessagesPerLoad && (
                  <button
                    className={styles['load-more-button']}
                    onClick={getOlderMessages}
                  >
                    {loadingMore ? (
                      <div className={styles['more-messages-loading']}>
                        <ClipLoader size={18} color='#fff' />
                      </div>
                    ) : (
                      'Load more'
                    )}
                  </button>
                )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chatroom;
