import React from "react";
import { Redirect } from "react-router-dom";

import styles from "./OrderHistory.module.css";
import history from "../../env/history";
import moment from "moment";
import { NoOrder } from "../layout/NoOrderHistory.Components";
import {
	GETConsumersDataByTokenID,
	GETOderReceivedDataByTokenID,
} from "../../env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";
import arrow from "../../assets/forward-90.png";

import NavBar from "../NavBar/NavBar";
import AppBar from "../AppBar/AppBar";
let search, params, tokenId, view, appbar;
var reducedBuildings1 = [];
//simulate data for testing
// var reducedBuildings1 = [
// 	{time_order_placed: 1648084629461,
// 	product_name:"Vibe Checkin Bouquet",
// 	sender_name:"Ying Yin",
// 	recipient_name:"Ying Yin Li",
// 	status: 3
// 	},{time_order_placed: 1648084629463,
// 		product_name:"Vibe Checkin Bouquet",
// 		sender_name:"Ying Yin",
// 		recipient_name:"Ying Yin Li",
// 		status: 1
// 	}];

class ConsumerHistory extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			isLoaded: false,
			items: [],
			users: [],
			view: "",
			refresh: false,
			open: false,
			filteredData: [],
			selectedStatus: "",
			glinkAutoRoute: null,
			data: [],
		};
	}
	container = React.createRef();

	updateConsumer = (value) => {
		this.setState({ view: value });
		setTimeout(() => {
			this.setState({ selectedStatus: "" });
		}, 100);
	};
	updateRecipient = (value) => {
		this.setState({ view: value });
		setTimeout(() => {
			this.setState({ selectedStatus: "" });
		}, 100);
	};

	componentDidUpdate(prevProps, prevState) {
		view = this.state.view;

		if (
			prevState.view !== this.state.view ||
			prevState.refresh !== this.state.refresh
		) {
			if (view === "consumer" && tokenId !== "null") {
				this.setState({
					isLoaded: false,
				});
				GETConsumersDataByTokenID(tokenId).then(
					(result) => {
						reducedBuildings1 = [];
						result.data.forEach((building) => {
							if (building.hasOwnProperty("order_id")) {
								reducedBuildings1.push(building);
							} else {
								building["order_id"] = "N/A";
								reducedBuildings1.push(building);
							}
						});
						let sortedData = reducedBuildings1.sort(function (a, b) {
							return b.time_order_placed - a.time_order_placed;
						});
						this.setState({
							isLoaded: true,
							items: sortedData,
							view,
							filteredData: sortedData,
							data: reducedBuildings1,
						});
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						this.setState({
							isLoaded: true,
							error,
						});
					}
				);
			} else if (view === "recipient" && tokenId !== "null") {
				this.setState({
					isLoaded: false,
				});

				GETOderReceivedDataByTokenID(tokenId).then((result) => {
					reducedBuildings1 = [];
					result.data.forEach((building) => {
						if (building.hasOwnProperty("order_id")) {
							reducedBuildings1.push(building);
						} else {
							building["order_id"] = "N/A";
							reducedBuildings1.push(building);
						}
					});

					let sortedData = reducedBuildings1.sort(function (a, b) {
						return b.time_order_placed - a.time_order_placed;
					});
					this.setState({
						isLoaded: true,
						items: sortedData,
						view,
						filteredData: sortedData,
						data: reducedBuildings1,
					});
				});
			} else {
				this.setState({
					isLoaded: true,
					items: [],
					filteredData: [],
				});
			}
		}
	}
	componentDidMount() {
		search = window.location.search;
		params = new URLSearchParams(search);
		tokenId = params.get("tokenId");
		view = params.get("view");
		this.setState({
			glinkAutoRoute: params.get("gLinkAutoRoute"),
		});

		if (view === undefined || view === null) {
			this.setState({
				view: "consumer",
			});
		} else {
			this.setState({
				view: view,
			});
		}
		appbar = params.get("appbar");

		if (appbar === null) {
			appbar = "true";
		}

		document.addEventListener("mousedown", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.handleClickOutside);
	}

	handleClickOutside = (event) => {
		if (
			this.container?.current &&
			!this.container?.current?.contains(event.target)
		) {
			this.setState({
				open: false,
			});
		}
	};
	viewButtonOnClick = (item) => {
		if (this.state.view === "recipient") {
			history.push(
				`/smartsend?orderId=${
					item.order_id
				}&tokenId=${tokenId}&app=${true}&view=${this.state.view}`
			);
		} else if (this.state.view === "consumer") {
			history.push(
				`/track?orderId=${
					item.order_id
				}&tokenId=${tokenId}&appbar=${true}&view=${this.state.view}`
			);
		}
	};
	handleUpdate = () => {
		// this.setState({ refresh: !this.state.refresh });
		this.setState({ selectedStatus: "" });
	};

	handleButtonClick = () => {
		this.setState({ open: !this.state.open });
	};

	handleFilter = (e, title) => {
		this.setState({ isLoaded: false });
		this.setState({ selectedStatus: title });
		const id = parseInt(e.target.id);
		if (id === 0) {
			this.setState({ filteredData: this.state.items });
			setTimeout(() => {
				this.setState({ isLoaded: true });
			}, 500);
		} else if (id) {
			let newData = this.state.items?.filter((d) => d.status === id);

			this.setState({ filteredData: newData });
			setTimeout(() => {
				this.setState({ isLoaded: true });
			}, 500);
		}

		this.setState({ open: false });
	};
	render() {
		const {
			error,
			isLoaded,
			filteredData,
			selectedStatus,
			glinkAutoRoute,
			data,
		} = this.state;
		if (glinkAutoRoute) {
			return (
				<Redirect
					to={`/glink?orderId=${glinkAutoRoute}&tokenId=${tokenId}&appbar=true&view=consumer`}
				/>
			);
		}
		if (!isLoaded) {
			return (
				<div className={styles.loader_consumer}>
					<ClipLoader color={"#8585ff"} size={100} />
				</div>
			);
		} else if (
			error ||
			(filteredData.length === 0 && isLoaded === true && data?.length === 0)
		) {
			return (
				<div>
					<AppBar
						handleButtonClick={this.handleButtonClick}
						open={this.state.open}
						container={this.container}
						handleUpdate={this.handleUpdate}
					/>
					<NavBar
						updateConsumer={this.updateConsumer}
						updateRecipient={this.updateRecipient}
						handleFilter={this.handleFilter}
						selectedStatus={selectedStatus}
					/>
					<NoOrder></NoOrder>
				</div>
			);
		} else if (
			error ||
			(filteredData.length === 0 && isLoaded === true && data?.length > 0)
		) {
			return (
				<div>
					<AppBar
						handleButtonClick={this.handleButtonClick}
						open={this.state.open}
						container={this.container}
						handleUpdate={this.handleUpdate}
					/>
					<NavBar
						updateConsumer={this.updateConsumer}
						updateRecipient={this.updateRecipient}
						handleFilter={this.handleFilter}
						selectedStatus={selectedStatus}
					/>
					<div className={styles.noOrders}>
						You don't have {selectedStatus} Orders to display
					</div>
				</div>
			);
		} else if (isLoaded === true) {
			return (
				<div className={styles.screen_name}>
					<AppBar
						handleButtonClick={this.handleButtonClick}
						open={this.state.open}
						container={this.container}
						handleUpdate={this.handleUpdate}
					/>

					<NavBar
						updateConsumer={this.updateConsumer}
						updateRecipient={this.updateRecipient}
						handleFilter={this.handleFilter}
						selectedStatus={selectedStatus}
					/>

					<section className={styles.card_holder}>
						{filteredData.map((item) => {
							return (
								<div
									className={styles.card_old}
									key={item.time_order_placed}
									onClick={() => this.viewButtonOnClick(item)}
								>
									<div>
										{this.state.view === "recipient" ? (
											<div className={styles.header}>
												<span className={styles.violet}>
													{item?.product_name?.toString()}
												</span>{" "}
												<span className={styles.violet}> from </span>{" "}
												<span className={styles.violet}>
													{item.sender_name}
												</span>
											</div>
										) : (
											<div className={styles.header}>
												<span className={styles.violet}>
													{item?.product_name?.toString()}
												</span>{" "}
												{item.recipient_name !== "" && !item?.isSenderToSelf ? (
													<>
														<span className={styles.violet}> to </span>{" "}
														<span className={styles.violet}>
															{item.recipient_name}
														</span>
													</>
												) : (
													<></>
												)}
											</div>
										)}

										<div className={styles.date}>
											<span> {item?.isCampaign ? "Redeemed on " : "Order placed on"}</span>
											{moment(new Date(item.time_order_placed * 1)).format(
												"MMMM Do, YYYY "
											)}
										</div>

										{this.state.view === "consumer" ? (
											<>
												<div className={styles.status}>
													<span>
														{" "}
														{item.status === 1
															? `Order awaiting ${
																	item.recipient_name === ""
																		? "recipient"
																		: item.recipient_name
															  }'s input`
															: item.status === 2
															? "Order is scheduled"
															: item.status === 3
															? "Order has been delivered"
															: item.status === 4
															? "Order has been cancelled"
															: item.status}{" "}
													</span>
												</div>
											</>
										) : null}
										{this.state.view === "recipient" ? (
											<>
												<div className={styles.status}>
													<span>
														{" "}
														{item.status === 1
															? "Click to enter your information"
															: item.status === 2
															? "Order is scheduled"
															: item.status === 3
															? "Order has been delivered"
															: item.status === 4
															? "Order has been cancelled"
															: item.status}{" "}
													</span>
												</div>
											</>
										) : null}
									</div>

									<div className={styles.track}>
										<img
											alt="track"
											onClick={() => this.viewButtonOnClick(item)}
											className={styles.track_button_order}
											src={arrow}
										/>
									</div>
								</div>
							);
						})}
					</section>
				</div>
			);
		}
	}
}

export default ConsumerHistory;
