import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import OpenInFullIcon from '@mui/icons-material/OpenInFullRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import EditIcon from '@mui/icons-material/Edit';
import HelpIcon from '@mui/icons-material/Help';

import Modal from './Modal';
import { selfieVariant } from '../utils';
import styles from './PostCard.module.css';

import FromEditCard from '../editcards/FromEditCard';
import ToEditCard from '../editcards/ToEditCard';
import MessageEditCard from '../editcards/MessageEditCard';
import PrewrittenMessagesCard from '../editcards/PrewrittenMessagesCard';
import SelfieEditCard from '../editcards/SelfieEditCard';
import DoodleEditCard from '../editcards/DoodleEditCard';

const PostCard = ({
  editMessage,
  prewrittenMessages,
  displayPrewrittenMessages,
  handleSave,
  inApp,
  selectedDesign,
}) => {

  // Full Screen Selfie
  const [selfieFull, setSelfieFull] = useState(false);

  const handleSelfieFull = () => {
    window.scrollTo(0, 0);
    setSelfieFull(true);
    document.addEventListener('mousedown', handleCloseSelfieFull);
  };

  const handleCloseSelfieFull = () => {
    window.scrollTo(0, 0);
    setSelfieFull(false);
    document.removeEventListener('mousedown', handleCloseSelfieFull);
  };

  // Message Modal
  const [messageModalOpen, setMessageModalOpen] = useState(false);

  const handleMessageModalClick = () => {
    setMessageModalOpen(!messageModalOpen);
  };

  // Edit Cards
  const [to, setTo] = useState(editMessage.to);
  const [toEditCardOpen, setToEditCardOpen] = useState(false);

  const handleToEditClick = () => {
    setToEditCardOpen(!toEditCardOpen);
  };

  const handleToChange = (newTo) => {
    setTo(newTo);
  };

  const [from, setFrom] = useState(editMessage.from);
  const [fromEditCardOpen, setFromEditCardOpen] = useState(false);

  const handleFromEditClick = () => {
    setFromEditCardOpen(!fromEditCardOpen);
  };

  const handleFromChange = (newFrom) => {
    setFrom(newFrom);
  };

  const [message, setMessage] = useState(editMessage.message);
  const [messageEditCardOpen, setMessageEditCardOpen] = useState(false);

  const handleMessageEditClick = () => {
    setMessageEditCardOpen(!messageEditCardOpen);
  };

  const handleMessageChange = (newMessage) => {
    setMessage(newMessage);
  };

  const [prewrittenMessagesCardOpen, setPrewrittenMessagesCardOpen] =
    useState(false);

  const handlePrewrittenMessagesClick = () => {
    setPrewrittenMessagesCardOpen(!prewrittenMessagesCardOpen);
  };

  const [selfie, setSelfie] = useState(editMessage.selfie);
  const [selfieEditCardOpen, setSelfieEditCardOpen] = useState(false);

  const handleSelfieEditClick = () => {
    setSelfieEditCardOpen(!selfieEditCardOpen);
  };

  const handleSelfieChange = (newSelfie) => {
    setSelfie(newSelfie);
  };

  const [doodle, setDoodle] = useState(editMessage.doodle);
  const [doodleEditCardOpen, setDoodleEditCardOpen] = useState(false);

  const handleDoodleEditClick = () => {
    setDoodleEditCardOpen(!doodleEditCardOpen);
  };

  const handleDoodleChange = (newDoodle) => {
    setDoodle(newDoodle);
  };

  // Privacy
  const [privacy, setPrivacy] = useState(true);
  const handlePrivacyToggleClick = () => {
    setPrivacy(!privacy);
  };

  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const handlePrivacyModalClick = () => {
    setPrivacyModalOpen(!privacyModalOpen);
  };

  // Saving
  const handleSaveButtonClick = async () => {
    const messageData = {
      senderName: from,
      recipientName: to,
      selfieBase64: selfie,
      drawingBase64: doodle,
      personalMessage: message,
      designId: selectedDesign ? selectedDesign.id : null,
    };
    handleSave(messageData, privacy);
    console.log(messageData);
  };

  return (
    <div>
      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'unset';
        }}
      >
        {fromEditCardOpen && (
          <FromEditCard
            handleClose={handleFromEditClick}
            initialValue={from}
            onValueChange={handleFromChange}
          />
        )}
        {toEditCardOpen && (
          <ToEditCard
            handleClose={handleToEditClick}
            initialValue={to}
            onValueChange={handleToChange}
          />
        )}
        {messageEditCardOpen && (
          <MessageEditCard
            handleClose={handleMessageEditClick}
            initialValue={message}
            onValueChange={handleMessageChange}
          />
        )}
        {prewrittenMessagesCardOpen && (
          <PrewrittenMessagesCard
            handleClose={handlePrewrittenMessagesClick}
            onValueChange={handleMessageChange}
            messages={prewrittenMessages}
          />
        )}
        {selfieEditCardOpen && (
          <SelfieEditCard
            handleClose={handleSelfieEditClick}
            initialValue={selfie}
            onValueChange={handleSelfieChange}
          />
        )}
        {doodleEditCardOpen && (
          <DoodleEditCard
            handleClose={handleDoodleEditClick}
            initialValue={doodle}
            onValueChange={handleDoodleChange}
          />
        )}
      </AnimatePresence>
      <div className={styles['post-card-main-container']}>
        <AnimatePresence
          initial={false}
          exitBeforeEnter={true}
          onExitComplete={() => {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'unset';
          }}
        >
          {messageModalOpen && (
            <Modal text={message} handleClose={handleMessageModalClick} />
          )}
          {privacyModalOpen && (
            <Modal
              text={
                'By making this Gesture public your message, selfie, and signature will be able to be viewed on the public G-Feed. You can make any post public or private by clicking on the “My Gestures” button on the G-Feed.'
              }
              handleClose={handlePrivacyModalClick}
            />
          )}
        </AnimatePresence>

        <div className={styles['post-card-container']} id='post-card-container'>

          <div className={styles['post-card-logo']}>
            <img
              className={styles['post-card-logo-img']}
              src={selectedDesign.designElements[1]}
              alt='post card logo'
            />
          </div>

          <div className={styles['post-card-names']}>
            <div className={styles['post-card-names-img']} style={{ backgroundImage: `url(${selectedDesign.designElements[2]})` }}>
              <p className={styles['message-to']}>
                Hi{' '}
                <span
                  className={styles['editable-field']}
                  onClick={handleToEditClick}
                  id='to-editable-field'
                >
                  <span className={styles['editable-field-text']}>
                    {to ? to : '_______'}
                  </span>
                </span>
                <EditIcon
                  className={styles['edit-button']}
                  onClick={handleToEditClick}
                  fontSize='small'
                />{' '}
                ,
              </p>
              <p className={styles['message-from']}>
                <span
                  className={styles['editable-field']}
                  onClick={handleFromEditClick}
                  id='from-editable-field'
                >
                  {from ? from : '_______'}
                </span>
                <EditIcon
                  className={styles['edit-button']}
                  onClick={handleFromEditClick}
                  fontSize='small'
                />{' '}
                sent you a Gesture
              </p>
            </div>
          </div>

          <div className={styles['post-card-selfie']}>
            <img
              className={styles['post-card-selfie-img']}
              src={selectedDesign.designElements[3]}
              alt='post card selfie'
            />
            <motion.div
              className={styles['message-selfie-container']}
              variants={selfieVariant}
              initial='standard'
              animate={selfieFull ? 'full' : 'standard'}
            >
              <img
                className={
                  selfieFull
                    ? styles['message-selfie-full']
                    : styles['message-selfie']
                }
                id='message-selfie'
                src={selfie}
                alt='selfie'
              />
            </motion.div>
            <OpenInFullIcon
              className={
                selfieFull
                  ? `${styles['selfie-fullscreen-button']} ${styles['disabled']}`
                  : styles['selfie-fullscreen-button']
              }
              id='selfie-fullscreen-button'
              fontSize='large'
              onClick={handleSelfieFull}
            />
            <EditIcon
              className={
                selfieFull
                  ? `${styles['selfie-edit-button']} ${styles['disabled']}`
                  : styles['selfie-edit-button']
              }
              id='selfie-edit-button'
              fontSize='large'
              onClick={handleSelfieEditClick}
            />
            {selfieFull && (
              <CloseIcon
                className={styles['selfie-close-button']}
                id='selfie-close-button'
                fontSize='large'
                onClick={handleCloseSelfieFull}
              />
            )}
          </div>

          <div className={styles['post-card-message']}>
            <div className={styles['post-card-message-img']} style={{
              backgroundImage: `url(${selectedDesign.designElements[4]})`,
            }}>
              {(message === undefined ||
                message === null ||
                message === '') && (
                  <p
                    id='message-text'
                    className={`${styles['message-text']} ${styles['editable-field']} ${styles['placeholder-message']}`}
                    onClick={handleMessageEditClick}
                  >
                    Type your message here
                    <EditIcon
                      className={styles['edit-button']}
                      onClick={handleMessageEditClick}
                      fontSize='small'
                    />
                  </p>
                )}
              {message !== '' && message?.length < 100 && (
                <p
                  id='message-text'
                  className={`${styles['message-text']} ${styles['editable-field']}`}
                  onClick={handleMessageEditClick}
                >
                  {message}
                  <EditIcon
                    className={styles['edit-button']}
                    onClick={handleMessageEditClick}
                    fontSize='small'
                  />
                </p>
              )}
              {message?.length >= 100 && (
                <>
                  <p
                    id='message-text'
                    className={`${styles['message-text']} ${styles['editable-field']}`}
                    onClick={handleMessageEditClick}
                  >
                    {message.substring(0, 100) + '...'}
                    <EditIcon
                      className={styles['edit-button']}
                      onClick={handleMessageEditClick}
                      fontSize='small'
                    />
                  </p>
                  <button
                    id='message-modal-open-button'
                    className={styles['message-modal-open-button']}
                    onClick={handleMessageModalClick}
                    disabled={selfieFull}
                  >
                    See Full Message
                  </button>
                </>
              )}
              {displayPrewrittenMessages && (
                <button
                  id='choose-prewritten-message-button'
                  className={styles['choose-prewritten-message-button']}
                  onClick={handlePrewrittenMessagesClick}
                >
                  Choose Pre-Written Message
                </button>
              )}
            </div>
          </div>

          <div className={styles['post-card-doodle']}>
            <img
              className={styles['post-card-doodle-img']}
              src={selectedDesign.designElements[5]}
              alt='post card doodle'
            />
            <img
              className={styles['message-doodle']}
              src={doodle}
              alt='doodle'
              onClick={handleDoodleEditClick}
            />
            <div
              className={styles['message-doodle-background']}
              onClick={handleDoodleEditClick}
            ></div>
            <EditIcon
              className={
                selfieFull
                  ? `${styles['doodle-edit-button']} ${styles['disabled']}`
                  : styles['doodle-edit-button']
              }
              id='doodle-edit-button'
              fontSize='medium'
              onClick={handleDoodleEditClick}
            />
          </div>
        </div>

        {inApp && (
          <div className={styles['privacy-section']}>
            <p>
              Make Your Message Public?
              <HelpIcon
                className={styles['privacy-help-button']}
                onClick={handlePrivacyModalClick}
              />
            </p>
            <input
              className={styles['privacy-toggle']}
              type='checkbox'
              checked={!privacy}
              onChange={handlePrivacyToggleClick}
            />
          </div>
        )}
        <button
          className={
            selfieFull
              ? `${styles['save-button']} ${styles['disabled']}`
              : styles['save-button']
          }
          id='save-button'
          onClick={handleSaveButtonClick}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default PostCard;
