import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './TrackNotifyRecipient.module.css'
const TrackNotifyReceipentModal = ({ show, setShow, recName, handleNotifyReceipant }) => {
  const isNotified = sessionStorage.getItem("notified")
  const handleNo = () => setShow(false);
  const handleYes = () => {
    if (isNotified !== "true")
      handleNotifyReceipant();
      setShow(false);
      }
return (
<Modal show={show}  animation={true} style={{marginTop:"40vh"}}>
    <Modal.Header  >
      <Modal.Title style={{ paddingLeft:"0px"}}>Send Notification</Modal.Title>
    </Modal.Header>
    {isNotified==="true"?<Modal.Body style={{
          display: "flex",
          justifyContent: "center",
        }}>Looks like the notification is already on the way. Please allow {recName} to respond before sending another notification.</Modal.Body>:<Modal.Body style={{
          display: "flex",
          justifyContent: "center",
        }}>Are you sure you want to notify {recName ? recName : "the recipient"}? This will send them an email/text reminding them to fill out their information for delivery</Modal.Body>}
    <Modal.Footer style={{
          display: "flex",
          justifyContent: "center",
        }}>
      {isNotified==="true"?<></>:<button
            className={styles['cancel-button']} onClick={()=>handleNo()}>
        Cancel
      </button>}
      <button
            className={styles['okay-button']} onClick={()=>handleYes()}>
        I Understand
      </button>
    </Modal.Footer>
  </Modal>
)}
export default TrackNotifyReceipentModal;