import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import NavBar from "../../../GLink/components/NavBar/NavBar";

let search, params, tokenId, view, appbar, orderId, pmId,source;

const PM = (props) => {
	search = window.location.search;
	params = new URLSearchParams(search);
	orderId = params.get("orderId");
	tokenId = params.get("tokenId");
	view = params.get("view");
	appbar = params.get("appbar");
    pmId = params.get("pmId");
	source=`/messages?tokenId=${pmId}&preview=true`;
    const [loaded, setLoaded] = useState(false);

	useEffect(() => {
        setLoaded(true);
	}, []);
	
    return (
        <div>
			{!loaded ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "45vh",
						}}
					>
						<ClipLoader size={100} color="#8585ff" />
					</div>
				) : <>
                {appbar === "true" ? <NavBar helpBtn={false}  backBtn={true} orderId={orderId} tokenId={tokenId} view={view} screen={"track"} header={"Personal Message"}/> : <div></div>}
                <iframe title="messages" src={source} width="100%" height="90%" style={{border: "none", overflow:'auto'}}></iframe>
                </>
                // src="/messages?tokenId=12881c8b-2d3e-4866-babe-51d3cd791c9d&preview=true"
				//src="https://secure.gesture.vip/messages?tokenId=12881c8b-2d3e-4866-babe-51d3cd791c9d"
                }
		</div>
    );
	
};

export default PM;