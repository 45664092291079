import React,{ useEffect} from 'react';
import {useHistory} from 'react-router-dom' 
import ClipLoader from "react-spinners/ClipLoader";
import { getUserByTokenId } from '../../env/API';
import {motion} from 'framer-motion'
import styles from './RouteSelector.module.css';
const RouteSelector = (props) =>{
    const search = window.location.search
    const params= new URLSearchParams(search)
    const tokenId = params.get("tokenId")
    // const appbar= params.get("appbar") || true
    const inapp = params.get("inapp") || false
    const {push }= useHistory();
    const getUser = async () =>{
        const res = await getUserByTokenId(tokenId)
        await setTimeout(() => {
            if(!res) push('/grunner/login')
            else if(!res?.data?.data.user) push("/grunner/signup/new/");
            else if(res?.data?.data?.user?.verificationState==="phoneVerification") push(`/grunner/signup/phone_verify/?tokenId=${tokenId}`);
            else if(res?.data?.data?.user?.verificationState==="addressSelection") push(`/grunner/signup/address_selection/?tokenId=${tokenId}`);

            else if(res?.data?.data?.user?.verificationState==="identityVerification") push(`/grunner/signup/identity_verification/start?tokenId=${tokenId}&inapp=${inapp}`);
            else if(res?.data?.data?.user?.verificationState==="backgroundCheck") push(`/grunner/signup/background_check/start/?tokenId=${tokenId}&inapp=${inapp}`);
            else if(res?.data?.data?.user?.verificationState==="payments_setup") push(`/grunner/signup/payments_setup/start/?tokenId=${tokenId}`);
            else if(res?.data?.data?.user?.verificationState==="pending")push(`/grunner/signup/complete_registration/?tokenId=${tokenId}&inapp=${inapp}`);
            else if(res?.data?.data?.user?.verificationState==="complete")push(`/grunner/signup/complete_registration/?tokenId=${tokenId}&inapp=${inapp}`);

        }, "100")
    }
    useEffect(()=>{
        getUser()
    },[])
    return(
        <>
            <motion.div className={styles["main-container"]}>
             <ClipLoader size={100} color="#8585ff"/>
            </motion.div>
        </>
    )
}
export default RouteSelector;