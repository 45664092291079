import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import styles from "./VendorHistory.module.css";
import { getAllOrdersByTokenId } from "../../env/APIManager.js";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VendorOrder from "./VendorOrder/VendorOrder";
import ClipLoader from "react-spinners/ClipLoader";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";
import emptyBinFile from "../../assets/fileinbox.png";
import InfiniteScroll from "react-infinite-scroll-component";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import StoreIcon from "@mui/icons-material/Store";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TaskIcon from "@mui/icons-material/Task";
import "../VendorHistory.css";

const VendorHistory = () => {
  const history = useHistory();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let tokenId = params.get("tokenId");
  const [orders, setOrders] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [loading, setLoading] = useState(false);
  const [sortButtonViewAll, setSortButtonViewAll] = useState(true);
  const [allOrders, setAllOrders] = useState([]);
  const [sortButtonPending, setSortButtonPending] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [sortButtonCompleted, setSortButtonCompleted] = useState(false);
  const [pageNum, setPageNum] = useState(30);
  const [sortButtonCanceled, setSortButtonCanceled] = useState(false);
  const getAllOrders = async () => {
    setLoading(true);
    const res = await getAllOrdersByTokenId(tokenId);

    if (res?.data?.code === "SUCCESS") {
      let ordersdata = res.data.orders.sort((order1, order2) => {
        return order2.timestamp - order1.timestamp;
      });
      setAllOrders(ordersdata);
      setOrders(
        ordersdata.slice(
          0,
          res.data.orders.length > 30 ? 30 : res.data.orders.length
        )
      );
    }
    setLoading(false);
  };
  const handleSearchonchange = (e) => {
    setSearchBar(e.target.value);
    
    let orderlist = sortButtonCanceled
      ? allOrders.filter((order) => order.status === 2)
      : sortButtonViewAll
      ? allOrders
      : sortButtonPending
      ? allOrders.filter((order) => order.status === 0)
      : sortButtonCompleted
      ? allOrders.filter((order) => order.status === 1)
      : [];
    setOrders(
      orderlist.filter((order) => {
        return order.recipient_name
          .toLowerCase()
          .includes(e.target.value.toLowerCase());
      })
    );
  };
  const [sticky, setSticky] = useState("heading-table");
  const [stickyBody, setStickyBody] = useState("inner-body-containter");
  
  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 600 ? "heading-table-fixed" : "heading-table";
    const stickybodyClass = scrollTop >= 600 ? "body-table-fixed" : "inner-body-containter"
    setSticky(stickyClass);
    setStickyBody(stickybodyClass)
    
  };

  useEffect(() => {
    getAllOrders();
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    }
  }, []);

  const handleOnClickViewAll = (e) => {
    setLoading(true);
    setSortButtonCanceled(false);
    setSortButtonPending(false);
    setSortButtonCompleted(false);
    setSortButtonViewAll(true);
    
    setOrders(
      allOrders.filter((order) => {
        
        let exists= e.clear?true:order.recipient_name
          .toLowerCase()
          .includes(searchBar.toLowerCase());
          
          return exists
        }).slice(0,pageNum)
    );
    if(allOrders.length>pageNum) {
      setHasMore(true)
    }
    setLoading(false);
  };
  const handleOnClickPending = (e) => {
    setLoading(true);
    setSortButtonCanceled(false);
    setSortButtonPending(true);
    setSortButtonCompleted(false);
    setSortButtonViewAll(false);
    
    setOrders(
      allOrders
        .filter((order) => order.status === 0)
        .filter((order) => {
          let exists= e.clear?true:order.recipient_name
          .toLowerCase()
          .includes(searchBar.toLowerCase());
          return exists
        }).slice(0,pageNum)
    );
    if(allOrders.length>pageNum) {
      setHasMore(true)
    }
    setLoading(false);
  };
  const handleOnClickCompleted = (e) => {
    setLoading(true);
    setSortButtonCanceled(false);
    setSortButtonPending(false);
    setSortButtonCompleted(true);
    setSortButtonViewAll(false);
    
    setOrders(
      allOrders
        .filter((order) => order.status === 1)
        .filter((order) => {
          let exists= e.clear?true:order.recipient_name
          .toLowerCase()
          .includes(searchBar.toLowerCase());
          return exists
        }).slice(0,pageNum)
    );
    if(allOrders.length>pageNum) {
      setHasMore(true)
    }
    setLoading(false);
  };
  const handleOnClickCanceled = (e) => {
    setLoading(true);
    setSortButtonCanceled(true);
    setSortButtonPending(false);
    setSortButtonCompleted(false);
    setSortButtonViewAll(false);
    setOrders(
      allOrders
        .filter((order) => order.status === 2)
        .filter((order) => {
          let exists= e.clear?true:order.recipient_name
          .toLowerCase()
          .includes(searchBar.toLowerCase());
          return exists
        }).slice(0,pageNum)
    );
    if(allOrders.length>pageNum) {
      setHasMore(true)
    }
    setLoading(false);
  };

  orders.sort((order1, order2) => {
    return order2.timestamp - order1.timestamp;
  });

  const handleOnClickOrderDetails = (data) => {
    history.push(
      "/vendor/order-details?orderId=" + data.id + "&tokenId=" + tokenId
    );
  };
  const handleOnClickClearSearchBar = (e) => {
    setSearchBar("");
    sortButtonCanceled && handleOnClickCanceled({clear:true});
    sortButtonViewAll && handleOnClickViewAll({clear:true});
    sortButtonPending && handleOnClickPending({clear:true});
    sortButtonCompleted && handleOnClickCompleted({clear:true});
  };
  const handleFetchingOrders = () => {
    let allOrderslist = allOrders
    if(searchBar.length>0){
      allOrderslist=allOrders.filter((order) => {
        let exists= order.recipient_name
        .toLowerCase()
        .includes(searchBar.toLowerCase());
        return exists

      })
      
    }
    let orderslist = allOrderslist.slice(0,pageNum);
    if (allOrderslist.length >= pageNum + 1) {
      orderslist = sortButtonCanceled
        ? allOrderslist.filter((order) => order.status === 2).slice(0, pageNum + 1)
        : sortButtonViewAll
        ? allOrderslist.slice(0, pageNum + 1)
        : sortButtonPending
        ? allOrderslist.filter((order) => order.status === 0).slice(0, pageNum + 1)
        : sortButtonCompleted
        ? allOrderslist.filter((order) => order.status === 1).slice(0, pageNum + 1)
        : [];
        setPageNum((prev) => prev + 1);
        } else {
        setHasMore(false);
      }
        setOrders(orderslist);
    return orderslist;
  }
  return (
    <div className={styles[loading?"container-loading":"container"]}>
      {loading ? (
        <div className={styles["spinner"]}>
          <ClipLoader size={100} color="#8585ff" />
        </div>
      ) : (
        <div className={styles["main-container"]}>
          <div className={styles["top-bar"]}>
            <div className={styles["top-bar-bar"]}>
              <a
                className={styles.redirect}
                // target="_blank"
                href="https://www.gesture.partners/account"
              >
                <KeyboardBackspaceIcon fontSize="large" />
              </a>
              <span className={styles["title"]}>Order History</span>
            </div>
          </div>

          <div className={styles[sticky]}>
            <div className={styles["organize-sort-bar"]}>
              <div className={styles["organize-sort-buttons"]}>
                <button
                  className={
                    sortButtonViewAll ? styles["active"] : styles["sort"]
                  }
                  onClick={handleOnClickViewAll}
                >
                  All
                </button>
                <button
                  className={
                    sortButtonPending ? styles["active"] : styles["sort"]
                  }
                  onClick={handleOnClickPending}
                >
                  Pending
                </button>
                <button
                  className={
                    sortButtonCompleted ? styles["active"] : styles["sort"]
                  }
                  onClick={handleOnClickCompleted}
                >
                  Completed
                </button>
                <button
                  className={
                    sortButtonCanceled ? styles["active"] : styles["sort"]
                  }
                  onClick={handleOnClickCanceled}
                >
                  Cancelled
                </button>
              </div>
              <TextField
                value={searchBar}
                className={styles["searchBox"]}
                onChange={handleSearchonchange}
                label={"Search customer"}
                sx={{
                  "& label": {
                    display: "inline",
                    width: "60%",
                    color: "#000000!important",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "#D0D0D0!important",
                    },
                  },
                }}
                // InputLabelProps={{shrink: false}}
                id="margin-none"
                // placeholder="Search customer"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleOnClickClearSearchBar}>
                        {searchBar?.length > 0 ? <CloseIcon /> : <></>}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <div className={styles["headers"]}>
              {orders.length > 0 ? (
                <>
                  <div
                    style={{
                      flex: "1.5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    <AccountBoxIcon
                      className={styles["header-orders-img"]}
                      
                    />
                    <span>Customer</span>
                  </div>
                  <div
                    style={{
                      flex: "2.5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    <StoreIcon
                      className={styles["header-orders-img"]}
                      
                    />
                    <span>Order</span>
                  </div>
                  <div
                    style={{
                      flex: "1.3",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    <ShoppingBagIcon
                      className={styles["header-orders-img"]}
                      
                    />
                    <span>Product</span>
                  </div>
                  <div
                    style={{
                      flex: "3.5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    <LocationOnIcon
                      className={styles["header-orders-img"]}
                      
                    />
                    <span>Address</span>
                  </div>
                  <div
                    style={{
                      flex: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                    <TaskIcon
                      className={styles["header-orders-img"]}
                      
                    />
                    <span>Status</span>
                  </div>
                </>
              ) : (
                <></>
              )}
              <div
                style={{
                  flex: "0.5",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              ></div>
            </div>
          </div>
          <div className={styles[stickyBody]}>
            <div className={styles["vendor-histor-body"]}>
              {orders?.length === 0 && (
                <div className={styles["no-orders-container"]}>
                  {sortButtonViewAll && (
                    <span>There are no orders to display</span>
                  )}
                  {sortButtonPending && (
                    <span>There are no pending orders to display</span>
                  )}
                  {sortButtonCompleted && (
                    <span>There are no completed orders to display</span>
                  )}
                  {sortButtonCanceled && (
                    <span>There are no canceled orders to display</span>
                  )}
                  <div className={styles["no-orders-img-container"]}>
                    <img
                      className={styles["no-orders-img"]}
                      src={emptyBinFile}
                      alt="Empty Bin"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className={styles["vendor-histor-body"]}>
              <InfiniteScroll
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                width="100%"
                dataLength={orders.length}
                next={handleFetchingOrders}
                hasMore={hasMore}
                loader={loading? <span>Loading...</span> : <></>}
              >
                {orders?.map((order, index) => {
                  return (
                    <VendorOrder
                      handleOnClickOrderDetails={handleOnClickOrderDetails}
                      order={order}
                      key={index}
                    />
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default VendorHistory;
