import { useState, useEffect } from "react";
import styles from "./TaskWindow.module.css";
import { getAllTasks } from "../../../env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FilterListIcon from "@mui/icons-material/FilterList";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { red } from "@mui/material/colors";

import CircleRoundedIcon from "@mui/icons-material/CircleRounded";

import SettingsSystemDaydreamSharpIcon from "@mui/icons-material/SettingsSystemDaydreamSharp";
import { Link } from "react-router-dom";

import { useHistory } from "react-router-dom";

export default function TaskWindow({
	// tokenId,
	handleClick,
	taskFocused,
	clearFocus,
	setUnfilteredTasks,
	selected,
	setSelected,
	panToGrunner,
}) {
	const [tasks, setTasks] = useState([]);
	const [loaded, setLoaded] = useState(false);
	const [tasksLoaded, setTasksLoaded] = useState(false);
	const [filterView, setFilterView] = useState(false);
	const [checked, setChecked] = useState({
		all: true,
		assigned: false,
		unassigned: false,
	});
	const [rerender, setRerender] = useState(false);
	const [intervalId, setIntervalId] = useState(null);

	const filterClick = async () => {
		setFilterView(!filterView);
	};

	const { push } = useHistory();

	const handleChange = (event) => {
		setChecked({
			all: false,
			assigned: false,
			unassigned: false,
			[event.target.name]: event.target.checked,
		});
	};

	const handleSelection = (item, index) => {
		if (isAssigned(item?.grunnerUid)) {
			panToGrunner(item?.grunnerUid);
		} else if (selected.index === index) {
			clearFocus();
			setSelected({ index: null });
		} else {
			setSelected({ index: index });
			handleClick(item);
		}
	};

	const isAssigned = (grunnerUid) => {
		return grunnerUid !== null && grunnerUid !== undefined ? true : false;
	};

	const assignment = (grunnerUid, firstName, lastName) => {
		const checkUndefined = (name) => {
			return name === undefined ? "Anon" : name;
		};
		return isAssigned(grunnerUid) ? (
			<div className={styles.assigned}>
				<CircleRoundedIcon fontSize="small" color="success" />
				<div style={{ marginLeft: "5px" }}>
					{checkUndefined(firstName)} {checkUndefined(lastName)}
				</div>
			</div>
		) : (
			// <span className={styles.unassigned}>Unassigned</span>
			<span className={styles.unassigned}>
				<CircleRoundedIcon fontSize="small" sx={{ color: red[500] }} />
			</span>
		);
	};

	const filterTasks = (tasks) => {
		const filterStatus = Object.keys(checked)
			.find((key) => checked[key] === true)
			.toString(); // In the object only on value will be "true", find it.

		var filteredTasks;
		if (filterStatus === "assigned") {
			filteredTasks = tasks?.filter(
				(task) => task.grunnerUid !== null && task.grunnerUid !== undefined
			);
		} else if (filterStatus === "unassigned") {
			filteredTasks = tasks?.filter(
				(task) => task.grunnerUid === null || task.grunnerUid === undefined
			);
		} else {
			filteredTasks = tasks;
		}
		setTasks(filteredTasks);
		setTasksLoaded(true);
		setLoaded(true);
	};

	const handleTaskCall = () => {
		let tokenId =
			localStorage.getItem("token") == null ||
			localStorage.getItem("token") === undefined ||
			localStorage.getItem("token") === ""
				? ""
				: JSON.parse(localStorage.getItem("token"));
		getAllTasks(tokenId)
			.then((resp) => {
				if (resp?.data?.code === "UNAUTHORIZED") {
					localStorage.clear();
					push({ pathname: "/logistics/login" });
					toast.error(
						"You have been logged out. This may have been caused by using more than one device or browser"
					);

					return;
				} else {
					return resp?.data?.grunners;
				}
			})
			.then((taskList) => {
				return taskList?.filter(
					(item) =>
						item?.orderId !== undefined &&
						item?.senderName !== undefined &&
						item?.recipientName !== undefined &&
						item?.id !== undefined
				);
			})
			.then((validTasks) => {
				setUnfilteredTasks(validTasks);
				filterTasks(validTasks);
			});
	};

	const clearOldInterval = () => {
		clearInterval(intervalId);
	};

	const handleSave = () => {
		setTasksLoaded(false);
		clearOldInterval();
		handleTaskCall();
		setRerender(!rerender);
		setFilterView(false);
	};

	function convertToReadable(ms) {
		// Some of the data coming through has been text so change into int.
		var s = `${new Date(parseInt(ms)).toLocaleDateString(`en-US`, {
			day: "numeric",
			month: "numeric",
			year: "numeric",
		})}`;
		// s+= `${new Date(parseInt(ms)).toLocaleString(`en-US`, { hour: 'numeric', minute: 'numeric', hour12: true })}`
		return s;
	}

	useEffect(() => {
		const taskInterval = setInterval(() => {
			handleTaskCall();
		}, 5000);

		setIntervalId(taskInterval);

		return () => {
			clearInterval(taskInterval);
		};
	}, [rerender]);

	const handleLinkClick = (e) => {
		e.stopPropagation();
	};

	return (
		<div className={styles.sidebar}>
			{!loaded ? (
				<div className={styles.loaderContainer}>
					<ClipLoader color="#6C40FC" size={50} />
				</div>
			) : (
				<>
					{!filterView ? (
						<>
							<div className={styles.appBar}>
								<div className={styles.appBarContainer}>
									<div className={styles.appBarTitle}>Tasks</div>
									<div className={styles.appBarIcon}>
										<div className={styles.buttonStyle}>
											<IconButton onClick={filterClick}>
												<FilterListIcon
													style={{ color: "black" }}
													fontSize="medium"
												/>
											</IconButton>
										</div>
									</div>
								</div>
							</div>
							<div className={styles.container}>
								{!tasksLoaded ? (
									<div className={styles.taskLoaderContainer}>
										<ClipLoader color="#6C40FC" size={50} />
									</div>
								) : (
									<div className={styles.tasksContainer}>
										{tasks?.map((item, index) => (
											<div
												key={index}
												className={
													taskFocused && selected.index === index
														? styles.selectedCard
														: styles.card
												}
												onClick={() => handleSelection(item, index)}
											>
												{taskFocused && selected.index === index && (
													<>
														<div className={[styles.assignHeader].join(" ")}>
															Assignment Mode
														</div>
														<div className={styles.assignMessage}>
															Click on a G-Runner to assign this task
														</div>
														<div className={styles.selectedDivider}></div>
													</>
												)}
												<div className={styles.itemHeader}>
													#{item?.orderId?.substring(item?.orderId?.length - 5)}
													<div>
														{assignment(
															item?.grunnerUid,
															item?.grunnerFirstName,
															item?.grunnerLastName
														)}
													</div>
												</div>
												<div className={styles.itemText}>
													<div>
														{item?.senderName}'s gift to {item?.recipientName}
													</div>
													<div>{item?.address}</div>
													{/* <div>{item.addressExtra}</div> */}
													<div>
														{item?.deliverBy
															? item.deliverBy
															: convertToReadable(item?.deliverByMS)}
													</div>
												</div>
												{/* <div className={styles.assignHeader}>
													{assignment(
														item?.grunnerUid,
														item?.grunnerFirstName,
														item?.grunnerLastName
													)}
													{item?.tag ? (
														<div
															style={{
																padding: "5px",
																border: "1px solid #000",
																borderRadius: "5px",
																backgroundColor: "#000",
															}}
														>
															{" "}
															<span className={styles.vendor}>{item?.tag}</span>
														</div>
													) : null}
												</div> */}

												<Link
													to={`/logistics/crm/order-details?id=${item?.orderId}&type=${item?.type}`}
													target="_blank"
													rel="noopener noreferrer"
													className={styles.link}
													onClick={handleLinkClick}
												>
													<SettingsSystemDaydreamSharpIcon fontSize="large" />
												</Link>
											</div>
										))}
									</div>
								)}
							</div>
						</>
					) : (
						<>
							<div className={styles.filterContainer}>
								<div className={styles.appBar}>
									<div className={styles.filterBarContainer}>
										<div className={styles.filterBarIcon}>
											<div className={styles.buttonStyle}>
												<IconButton onClick={filterClick}>
													<ArrowBackIcon
														style={{ color: "black" }}
														fontSize="medium"
													/>
												</IconButton>
											</div>
										</div>
										<div className={styles.filterBarTitle}>Filter</div>
									</div>
								</div>
								<div className={styles.taskTypesHeader}>Task Type</div>
								<div className={styles.checkBoxContainer}>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													name="all"
													checked={checked.all}
													onChange={handleChange}
												/>
											}
											label="All"
										/>
										<FormControlLabel
											control={
												<Checkbox
													name="assigned"
													checked={checked.assigned}
													onChange={handleChange}
												/>
											}
											label="Assigned"
										/>
										<FormControlLabel
											control={
												<Checkbox
													name="unassigned"
													checked={checked.unassigned}
													onChange={handleChange}
												/>
											}
											label="Unassigned"
										/>
									</FormGroup>
								</div>
								<div
									className={styles.filterBarBottom}
									onClick={() => {
										handleSave();
									}}
								>
									<div className={styles.filterBottomContainer}>
										<div className={styles.saveButtonText}>Save</div>
									</div>
								</div>
							</div>
						</>
					)}
					<div></div>
				</>
			)}
		</div>
	);
}
