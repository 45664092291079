import React from 'react';
import back_arrow from '../../assets/back_arrow.png';

import './AppBar.css';

const AppBar = () => {
  return (
    <>
      <div className='heading'>
        <div className='headingimg'>
          <img src={back_arrow} alt='back' className='back_btn'></img>{' '}
        </div>
        <span className='heading_text'>Messages</span>
        <div className='heading_space'></div>
      </div>
    </>
  );
};

export default AppBar;
