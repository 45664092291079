import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import styles from './DialogWindow.module.css';

export default function DialogWindow ({ taskId, data, removeTaskClick, setActive }) {
    const [open, setOpen] = useState(true);
   
    const handleClose = () => {
        setActive(null)
        setOpen(false);
    };
    const handleConfirmation = () => {
        removeTaskClick();
        handleClose();
    }
    
    return (
        <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <div className={styles.title}>
                Are you sure you want to remove <span className={styles.bold}>{taskId}</span> from <span className={styles.bold}>{data.grunner?.firstName} {data.grunner?.lastName}</span>?
                </div>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleConfirmation} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}