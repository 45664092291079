import React, { Component } from "react";
import PropTypes from "prop-types";
import ClipLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import styles from "../layout/setDate.module.css";
import phonestyles from "./verifyPhone.module.css";
import PModal from "./PModal";
import { POSTSendSMS, POSTSmartSendByssID } from "../../env/APIMAnager";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";

const notify = (text) =>
	toast.error(text, {
		position: "top-center",
		autoClose: 5000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		secureVerifyHeader:null
	});

function toggleModal(t) {
	t.setState((prevState) => ({ openModal: !prevState.openModal }));
}
let header = "";
let link = "";
const PNF = require("google-libphonenumber").PhoneNumberFormat;
const phoneUtil =
	require("google-libphonenumber").PhoneNumberUtil.getInstance();
const AsYouTypeFormatter = require("google-libphonenumber").AsYouTypeFormatter;
const formatter = new AsYouTypeFormatter("US" || "CA");
function is_numeric(str) {
	return /^\d+$/.test(str);
}

export default class VerifyPhoneModal extends Component {
	state = {
		phoneNumber: "",
		sent: false,
		isverified: true,
		email: "",
		openModal: false,
		recName: "",
		displayVerify: false,
		infoModal: false,
	};

	componentDidUpdate() {
		var t = this;
		if (this.props.show === true) {
			window.onclick = function (event) {
				if (event.target.id === "phoneModal") {
					//t.reset();
					t.props.onClose();
				}
			};
		}
	}

	// reset=()=>{
	//   document.getElementById('phoneNum').value="";
	//   this.setState({
	//     phoneNumber:""
	//   });
	//   document.getElementById('smsNum').value="";
	//   document.getElementById('emailAddress').value="";
	// }

	handleSendSMS = () => {
		var re = /\S+@\S+\.\S+/;
		var valid_email = re.test(this.state.email);
		if (this.state.recName?.trim()?.length === 0) {
			notify("Please add your name.");
		} else if (!valid_email) {
			notify("Invalid email address.");
		} else {
			this.setState(
				{
					sent: true,
				},
				() => {
					let isValid = false;
					if (this.state.phoneNumber !== "") {
						isValid =
							phoneUtil.isValidNumberForRegion(
								phoneUtil.parse(this.state.phoneNumber, "US"),
								"US"
							) ||
							phoneUtil.isValidNumberForRegion(
								phoneUtil.parse(this.state.phoneNumber, "CA"),
								"CA"
							);
					}

					if (!isValid) {
						notify(
							"This phone number is invalid. Please enter a new number and try again."
						);
						this.setState({
							sent: false,
						});
					} else {
						var phoneNum = phoneUtil.format(
							phoneUtil.parseAndKeepRawInput(this.state.phoneNumber, "US"),
							PNF.E164
						);
						POSTSendSMS(phoneNum).then(
							(result) => {
								this.setState({
									phoneNumber: phoneNum,
									sent: false,
									displayVerify: true,
								});
							},
							(error) => {
								this.setState({
									sent: false,
									error,
								});
							}
						);
					}
				}
			);
		}
	};

	handleOnChange = (e) => {
		let val = "";
		for (let i = 0; i < e.target.value.length; i++) {
			if (is_numeric(e.target.value[i])) {
				val = formatter.inputDigit(e.target.value[i]);
			}
		}
		this.setState(
			{
				phoneNumber: val,
			},
			() => {
				document.getElementById("phoneNum").value = val;
			}
		);
		formatter.clear();
	};

	handleEmailOnChange = (e) => {
		this.setState({
			email: e.target.value,
		});
	};
	handleNameChange = (e) => {
		this.setState({
			recName: e.target.value,
		});
	};

	handleSubmit = () => {
		var re = /\S+@\S+\.\S+/;
		var valid_email = re.test(this.state.email);
		if (!valid_email) {
			notify("Invalid email address.");
		} else if (this.state.recName?.trim()?.length === 0) {
			notify("Please add your name.");
		} else {
			this.setState(
				{
					isverified: false,
				},
				() => {
					var ssobject = this.props.submit(
						document.getElementById("smsNum").value,
						this.state.phoneNumber,
						this.state.email?.trim(),
						this.state.recName?.trim()
					);
					let secureVerifyHeader = this.state.secureVerifyHeader || ssobject[2];
					POSTSmartSendByssID(ssobject[0], ssobject[1], secureVerifyHeader).then((response) => {
						const { result, secureHeader } = response;
						if (result?.code === "SUCCESS") {
							this.setState(
								{
									isverified: true,
								},
								() => {
									this.props.complete();
								}
							);
							//go to thank you page
						} else {
							if(secureHeader){
								this.setState({secureVerifyHeader:secureHeader})
							}
							this.setState(
								{
									isverified: true,
								},
								() => {
									notify(result?.message);
								}
							);
						}
					});

					// this.setState({
					//   isverified: true,
					// },()=>{
					//   this.props.complete();
					// });
				}
			);
		}
	};

	handleOpenInfoIcon = () => {
		this.setState({ infoModal: true });
	};
	handleCloseInfoModal = () => {
		this.setState({ infoModal: false });
	};

	render() {
		const { show, children } = this.props;

		return (
			<div
				id="phoneModal"
				className={show ? styles.main_container : styles.notShow}
			>
				<div className={phonestyles.modal}>
					<div className={styles.header}>
						<h1 className={styles.header_text}> {children} </h1>
						<button
							className={styles.exit_btn}
							onClick={() => {
								this.props.onClose();
							}}
						>
							X
						</button>
					</div>

					<div className={phonestyles.section_container}>
						<p className={phonestyles.section_title}>
							Please provide your name, email address, and verify your phone
							number in order to receive your gift.
						</p>
					</div>

					<div className={phonestyles.section_container}>
						<div
							style={{ width: "100%", display: "flex", alignItems: "center" }}
						>
							<p
								onClick={this.handleOpenInfoIcon}
								className={phonestyles.info_title}
							>
								Name
							</p>
							<InfoIcon
								onClick={this.handleOpenInfoIcon}
								className={phonestyles.infoIcon}
							/>
						</div>

						<div className={phonestyles.phoneNum_wrap}>
							<input
								onChange={this.handleNameChange}
								className={phonestyles.email_input}
								type="text"
								id="emailAddress"
								placeholder="Enter your full name"
								autoComplete="off"
							></input>
						</div>
					</div>
					<div className={phonestyles.section_container}>
						<p className={phonestyles.info_title}>Email Address</p>
						<div className={phonestyles.phoneNum_wrap}>
							<input
								onChange={this.handleEmailOnChange}
								className={phonestyles.email_input}
								type="text"
								id="emailAddress"
								placeholder="Enter your email address"
								autoComplete="off"
							></input>
						</div>
					</div>
					<div className={phonestyles.section_container}>
						<p className={phonestyles.info_title}>Phone Number</p>
						<div className={phonestyles.phoneNum_wrap}>
							<div className={phonestyles.phoneNum}>
								<input
									maxLength="16"
									onChange={this.handleOnChange}
									className={phonestyles.phone_input}
									type="text"
									id="phoneNum"
									placeholder="Enter your phone number"
									autoComplete="off"
									onKeyPress={(e) => {
										e.key === "Enter" && e.preventDefault();
									}}
								></input>
							</div>
						</div>
					</div>
					{this.state.sent === false ? (
						<div
							className={
								this.state.displayVerify
									? phonestyles.sendbtn
									: phonestyles.sendbtn2
							}
						>
							<button
								className={phonestyles.Btn}
								onClick={() => this.handleSendSMS()}
							>
								Send SMS Code
							</button>
						</div>
					) : (
						<div
							className={
								this.state.displayVerify
									? phonestyles.sendbtn
									: phonestyles.sendbtn2
							}
						>
							<button className={phonestyles.Btn}>
								<ClipLoader color={"#00000"} size={20} />
							</button>
						</div>
					)}
					{this.state.displayVerify ? (
						<div className={phonestyles.section_container}>
							<p className={phonestyles.info_title}>Verify SMS</p>
							<div className={phonestyles.phoneNum_wrap}>
								<input
									className={phonestyles.sms_input}
									type="text"
									id="smsNum"
									placeholder="Enter the SMS code number"
									autoComplete="off"
									onKeyPress={(e) => {
										e.key === "Enter" && e.preventDefault();
									}}
								></input>
							</div>

							{this.state.isverified === true ? (
								<button
									className={phonestyles.Btn}
									onClick={() => {
										this.handleSubmit();
									}}
								>
									Verify and Send Me My Gift
								</button>
							) : (
								<button className={phonestyles.Btn}>
									<ClipLoader color={"#00000"} size={30} />
								</button>
							)}
							<p className={phonestyles.policy_text}>
								By clicking "Verify and Send Me My Gift" I represent that I have
								read and agree to the{" "}
								<span
									className={phonestyles.underline}
									onClick={(e) => {
										toggleModal(this, e);
										header = "Gesture Terms of Service";
										link = "https://help.gesture.vip/m-terms-of-use/";
									}}
								>
									Gesture Terms of Service
								</span>{" "}
								and{" "}
								<span
									className={phonestyles.underline}
									onClick={(e) => {
										toggleModal(this, e);
										header = "Privacy Policy";
										link = "https://help.gesture.vip/m-privacy-policy/";
									}}
								>
									Privacy Policy
								</span>
								.
							</p>
						</div>
					) : null}
					<PModal
						show={this.state.openModal}
						onClose={(e) => toggleModal(this, e)}
						link={link}
					>
						{header}
					</PModal>
				</div>
				{this.state.infoModal ? (
					<div id="myModal" className={phonestyles.modalContainer}>
						<div className={phonestyles.contentContainer}>
							<CancelIcon
								className={phonestyles.closeIcon}
								fontSize="medium"
								onClick={this.handleCloseInfoModal}
							/>
							<p className={phonestyles.modalText}>
								The recipient's full name is required for delivery
								verification purposes.
							</p>
						</div>
					</div>
				) : null}
			</div>
		);
	}
}

VerifyPhoneModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	show: PropTypes.bool.isRequired,
};
