import React from "react";
import styles from './RefreshBar.module.css';

import refreshLogo from "../../assets/refresh.png"

class RefreshBar extends React.Component {

  WhereTo=()=>{
       console.log(window.location.pathname);
       var s=window.location.pathname ;
       const len=s.length
    return s.substring(1,len);
  }
  Refresh=()=>{
    window.location.reload();
  }

  render() {
    return(    <div
      className={styles.refreshbar}  
    >
      <div
        className={styles.bar} 
      >
        <button  
          className={styles.refresh_button}
          // onClick={this.Refresh}
          onClick={() => this.props.handleUpdate()}>          
          <img src={refreshLogo} className={styles.refreshLogo} alt="refresh logo" />
        </button>
      </div>
     
    </div>)

}}

export default RefreshBar;