import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import AppBar from "../signup/appbar/AppBar";
import { getBackgroundCheckUrl } from "../../env/API";
import styles from "./BackgroundCheck.module.css";

import CancelIcon from "@mui/icons-material/Cancel";

const BackgroundCheck = () => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	const tokenId = params.get("tokenId");
	const inapp = params.get("inapp") || false;

	// const appbar = params.get("appbar") || true;

	const [screenLoading, setScreenLoading] = useState(false);

	const [webviewUrl, setWebviewUrl] = useState(null);

	const [modal, setModal] = useState(false);

	const handleOpenModal = () => {
		setModal(true);
	};

	const handleCloseModal = () => {
		setModal(false);
	};

	let history = useHistory();

	useEffect(() => {
		fetchAllData();
	}, []);

	const fetchAllData = async () => {
		setScreenLoading(true);
		await fetchBackgroundCheckInfo();
		setScreenLoading(false);
	};

	const fetchBackgroundCheckInfo = async () => {
		setWebviewUrl(null);
		const res = await getBackgroundCheckUrl(tokenId);
		if (res?.data?.url) {
			setWebviewUrl(res.data.url);
		}
	};

	window.addEventListener(
		"message",
		(event) => {
			if (event.data === "complete") {
				history.push(
					`/grunner/signup/complete_registration/?tokenId=${tokenId}&inapp=${inapp}`
				);
			}
		},
		false
	);
	return (
		<>
			{screenLoading ? (
				<div className={styles["screen-loading"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<>
					{/* {appbar == "false" ? (
						<> </>
					) : ( */}
					<AppBar
						disable
						appName="Background Check"
						goBack={() =>
							history.push(
								`/grunner/signup/identity_verification/start/?tokenId=${tokenId}&inapp=${inapp}`
							)
						}
						goNext={() =>
							history.push(
								`/grunner/signup/complete_registration/?tokenId=${tokenId}&inapp=${inapp}`
							)
						}
					/>
					{/* )} */}
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							height: "100%",
							overflowY: "none",
						}}
					>
						{modal ? (
							<div className={styles.permissionContainer}>
								<div className={styles.infoContainer}>
									<div
										style={{
											display: "flex",
											width: "100%",
											justifyContent: "flex-end",
										}}
									>
										<CancelIcon
											className={styles.closeIcon}
											fontSize="medium"
											onClick={handleCloseModal}
										/>
									</div>
									<div style={{ fontFamily: "Mulish", paddingBottom: "5px" }}>
										Please enable third-party cookies to view this form
									</div>
								</div>
							</div>
						) : null}
						{!modal ? (
							<div className={styles.permissionContainer}>
								<span
									onClick={handleOpenModal}
									className={styles.cookiePermission}
								>
									<p
										style={{
											opacity: "100% !important",
											fontWeight: 700,
											margin: 0,
											padding: 0,
										}}
									>
										{" "}
										Can’t fill out the form?
									</p>
								</span>
							</div>
						) : null}

						{webviewUrl && (
							<div className={styles.iframeContainer}>
								<iframe
									title="Background Check"
									src={webviewUrl}
									className={styles["content-container"]}
								></iframe>
							</div>
						)}
					</div>
				</>
			)}
		</>
	);
};

export default BackgroundCheck;
