import React, { Component } from 'react';
import { Router, Switch, Redirect, Route } from 'react-router-dom';

// import Vendor from './projects/VendorHistory/components/VendorHistory.Component'

import ConsumerHistory from './projects/OrderHistory/components/OrderHistory/OrderHistory.Component';
import SmartSend from './projects/SmartSend/components/SmartSend/SmartSend.Component';
import history from './projects/OrderHistory/env/history';
import Track from './projects/Track/components/Track/Track';
import GLink from './projects/GLink/components/GLink/GLink';
import FeedScreen from './projects/Gfeed/components/feedscreen/FeedScreen';
import Chatroom from './projects/Gfeed/components/chatroom/Chatroom';
import WebChat from './projects/WebChat/components/WebChat';
import PM from './projects/Track/components/personalMessage/PM';
import Logistics from './projects/LogisticsDashboard/components/LogisticScreen/LogisticsScreen';
import AdminLogIn from './projects/LogisticsDashboard/components/AdminLogin/AdminLogin';

import Card from './projects/Messages/components/card/Card';
import MessageEdit from './projects/MessageEdit/components/messageedit/MessageEdit';
import PromoCode from './projects/PromoCode/components/PromoCode';
import VerifyEmail from './projects/VerifyEmail/components/verifyemail/VerifyEmail';
import IdentityVerification from './projects/GRunner/components/IdentityVerification/IdentityVerification';
import PendingVerification from './projects/GRunner/components/PendingVerification/PendingVerification';
import BackgroundCheck from './projects/GRunner/components/backgroundcheck/BackgroundCheck';
import BackgroundCheckComplete from './projects/GRunner/components/backgroundcheck/complete/BackgroundCheckComplete';
import PaymentsSetup from './projects/GRunner/components/paymentssetup/PaymentsSetup';
import PaymentsSetupComplete from './projects/GRunner/components/paymentssetup/complete/PaymentsSetupComplete';
import SignUp from './projects/GRunner/components/signup/SignUp'
import PhoneVerify from './projects/GRunner/components/PhoneVerify/PhoneVerify'
import TaskList from './projects/GRunner/components/Tasks/TaskList'
import AddressSelection from './projects/GRunner/components/AddressSelection/AddressSelection.jsx'
import RouteSelector from './projects/GRunner/components/RouteSelector/RouteSelector'
import CompleteRegistration from './projects/GRunner/components/completedRegistration/CompleteRegistration'
import Task from './projects/GRunner/components/Tasks/Task/Task.jsx'
import LogIn from './projects/GRunner/components/Login/Login'
import ChooseDeliveryOption from './projects/GRunner/components/ChooseDeliveryOption/ChooseDeliveryOption'
import TaskPhoto from './projects/GRunner/components/Tasks/TaskPhoto/TaskPhoto';
import PaymentOption from './projects/GRunner/components/PaymentOption/PaymentOption'
import PS from './projects/GRunner/components/PolicyScreens/PS'
import TOS from './projects/GRunner/components/PolicyScreens/TOS';
import DeleteAccount from './projects/GRunner/components/DeleteAccount/DeleteAccount'
import VendorHistory from './projects/Vendor/components/VendorHistory/VendorHistory'
import VendorOrderDetails from './projects/Vendor/components/VendorHistory/VendorOrderDetails/VendorOrderDetails.jsx'
import TaskAgeVerification from './projects/GRunner/components/Tasks/TaskAgeVerification/TaskAgeVerification';
import OrderDetails from './projects/CRM/components/OrderDetails';
import CRM_Main_Page from './projects/CRM/components/CRM_Main_Page';
import UserDetails from './projects/CRM/components/consumer-user/UserDetails';
import BusinessUserDetails from './projects/CRM/components/business-user/BusinessUserDetails';
import mainPaymentPage from './projects/Stripe/mainPaymentPage';
import ResetPassword from './projects/GRunner/components/ResetPassword/ResetPassword.js';
export default class App extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path='/'>
            <Redirect to='/history' />
          </Route>
          {/* <Route path="/history-vendors" exact component={Vendor} /> */}
          <Route path='/logistics/login' exact component={AdminLogIn} />
          <Route path='/logistics/map' exact component={Logistics} />
          <Route path='/history' exact component={ConsumerHistory} />
          <Route path='/smartsend' exact component={SmartSend} />
          <Route path='/track' exact component={Track} />
          <Route path='/glink' exact component={GLink} />
          <Route path='/webchat' exact component={WebChat} />
          <Route exact path='/feed/:tokenId?' component={FeedScreen} />
          <Route exact path='/feed/:id/messages' component={Chatroom} />
          <Route path='/messages' exact component={Card} />
          <Route path='/personal-message' exact component={PM} />
          <Route path='/message-edit' exact component={MessageEdit} />
          <Route exact path='/promo/:code' component={PromoCode} />
          <Route exact path='/promo/' component={PromoCode} />
          <Route exact path='/verify-email' component={VerifyEmail} />
          <Route path='/grunner/login/:inapp?' component={LogIn} />
          <Route path='/grunner/reset-password' component={ResetPassword} />
          <Route exact path='/grunner/route/:tokenId?/:inapp?' component={RouteSelector} />
          <Route exact path='/grunner/tasks/:tokenId?' component={TaskList} />
          <Route exact path='/grunner/tasks/view/detail/:taskId?/:tokenId?' component={Task} />
          <Route exact path='/grunner/tasks/view/age-verification' component={TaskAgeVerification} />
          <Route exact path='/grunner/tasks/view/photo' component={TaskPhoto} />
          <Route path='/grunner/signup/new/:inapp?' component={SignUp} />
          <Route exact path='/grunner/signup/identity_verification/start/:tokenId?/:inapp?' component={IdentityVerification} />
          <Route exact path='/grunner/signup/pending_verification/:tokenId?/:inapp?' component={PendingVerification} />
          <Route exact path='/grunner/signup/background_check/start/:tokenId?/:inapp?' component={BackgroundCheck} />
          <Route exact path='/grunner/signup/background_check/complete/:tokenId?/:inapp?' component={BackgroundCheckComplete} />
          <Route exact path='/grunner/signup/payments_setup/start/:tokenId?' component={PaymentsSetup} />
          <Route exact path='/grunner/signup/payments_setup/complete/:tokenId?' component={PaymentsSetupComplete} />
          <Route exact path='/grunner/signup/phone_verify/:tokenId?' component={PhoneVerify} />
          <Route exact path='/grunner/signup/address_selection/:tokenId?' component={AddressSelection} />
          <Route exact path='/grunner/signup/complete_registration/:tokenId?' component={CompleteRegistration} />
          <Route exact path='/grunner/tasks/view/completion_options/:taskId?/:tokenId?' component={ChooseDeliveryOption} />
          <Route exact path = '/grunner/payment_options:tokenId?' component={PaymentOption}/>
          <Route exact path = '/grunner/privacy_policy' component={PS}/>
          <Route exact path = '/grunner/terms_of_use' component={TOS}/>
          <Route exact path = '/grunner/delete_account:tokenId?' component={DeleteAccount}/>
          <Route exact path = '/vendor/order-history:tokenId?' component={VendorHistory}/>
          <Route exact path = '/vendor/order-details:orderId?/:tokenId?' component={VendorOrderDetails}/>
          <Route exact path = '/logistics/crm' component={CRM_Main_Page}/>
          <Route exact path = '/logistics/crm/order-details/:id?' component={OrderDetails}/>
          <Route exact path = '/logistics/crm/consumer-user/:id?' component={UserDetails}/>
          <Route exact path = '/logistics/crm/business-user/:id?' component={BusinessUserDetails}/>
          <Route path='/cc_input' exact component={mainPaymentPage} />

          
        </Switch>
      </Router>
    );
  }
}