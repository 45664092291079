import React, { useState, useRef } from "react";

import AppBar from "./AppBar";
import styles from "./TaskPhoto.module.css";
import Compressor from "compressorjs";
import { convertBlobToBase64, errorToastOptions } from "../../../util/utils";

import { saveImage } from "../../../env/API";
import CancelIcon from "@mui/icons-material/Cancel";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import ClipLoader from "react-spinners/ClipLoader";

function TaskPhoto() {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const tokenId = params.get("tokenId");
  const taskId = params.get("taskId");
  const linkingUri = params.get("linkingUri")

  let taskImage = useRef(null);

  const [image, setImage] = useState(null);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const handleOpenModal = () => {
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  const handleChange = (e) => {
    const fileList = e.target.files;
    let file = null;

    for (let i = 0; i < fileList.length; i++) {
      if (fileList[i].type.match(/^image\//)) {
        file = fileList[i];
        break;
      }
    }

    if (file !== null) {
      new Compressor(file, {
        quality: 0.6,
        success(result) {
          convertBlobToBase64(result).then((imagease64) => {
            setImage(imagease64);
            console.log(imagease64);
          });
        },
      });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await saveImage(image, tokenId, taskId);
    console.log(response);
    if (response?.data?.code === "SUCCESS") {
      history.push(
        `/grunner/tasks/view/completion_options?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
      );
    } else {
      if (
        response?.data?.code === "UNAUTHORIZED_USER" ||
        response?.data?.code === "TASK_NOT_FOUND"
      )
        toast.error("the task has been removed from your task list.");
      else {
        toast.error(
          "There was an error, please try again later.",
          errorToastOptions
        );
      }
      setTimeout(() => {
        if (
          response?.data?.code === "UNAUTHORIZED" ||
          response?.data?.code === "UNAUTHORIZED_USER" ||
          response?.data?.code === "TASK_NOT_FOUND" ||
          response?.data?.code === "MISSING_PARAMETERS"
        ) {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({ code: "EXIT", message: response?.data?.code })
          );
          window.location.href = `${linkingUri}?code="EXIT"&message=${response?.data?.code}`;
        }
      }, 5000);
      setLoading(false);
    }
  };
  return (
    <>
      <AppBar
        onClickAction={() => {
          history.push(
            `/grunner/tasks/view/detail?taskId=${taskId}&tokenId=${tokenId}&linkingUri=${linkingUri}`
          );
        }}
        title={"Take Drop Off Photo"}
      />
      <ToastContainer />

      {loading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader size={50} color="#8585ff" />
        </div>
      ) : (
        <div className={styles.mainContainer}>
          <div className={styles.instructionsContainer}>
            <h3 className={styles.instructionsTitle}>Instructions</h3>
            <p className={styles.instructionsP}>
              Please take a clear photo of your drop off. Make sure all the
              products are shown.
            </p>
          </div>

          {image ? (
            <div className={styles.imageMainContainer}>
              <span className={styles.hrLine}></span>

              <div className={styles.imageContainer}>
                <h3 className={styles.instructionsTitle}>Image Preview</h3>

                <img
                  id="selfie-preview"
                  className={styles.image}
                  src={image}
                  alt="selfie preview"
                />
              </div>
              <label htmlFor="selfie-upload" className={styles.label}>
                Take Another Photo
              </label>
              <input
                id="selfie-upload"
                className={styles.input}
                type="file"
                accept="image/*"
                capture="(change)=getFile($event)"
                onChange={handleChange}
                ref={taskImage}
              />
              <span className={styles.hrLine}></span>
            </div>
          ) : null}

          {!image ? (
            <>
              <span onClick={handleOpenModal} className={styles.imgPermission}>
                Can’t take a photo?
              </span>
              <label htmlFor="selfie-upload" className={styles.label}>
                Take Photo
              </label>
              <input
                id="selfie-upload"
                className={styles.input}
                type="file"
                accept="image/*"
                capture="(change)=getFile($event)"
                onChange={handleChange}
                ref={taskImage}
              />
            </>
          ) : (
            <button onClick={handleSubmit} className={styles.label}>
              {" "}
              Submit Photo{" "}
            </button>
          )}
        </div>
      )}

      {modal ? (
        <div id="myModal" className={styles.modalContainer}>
          <div className={styles.contentContainer}>
            <CancelIcon
              className={styles.closeIcon}
              fontSize="medium"
              onClick={handleCloseModal}
            />
            <p className={styles.modalText}>
              If you can't take a photo please give Gesture access to your
              camera from your phone's settings.
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TaskPhoto;
