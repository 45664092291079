import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/CloseRounded';
import SignatureCanvas from 'react-signature-canvas';
import { HexColorPicker } from 'react-colorful';

import { emptyBase64Image, slideUp } from '../utils';
import styles from './DoodleEditCard.module.css';

const DoodleEditCard = ({ handleClose, initialValue, onValueChange }) => {
  const [penColor, setPenColor] = useState('#000000');
  let sigCanvas = {};

  useEffect(() => {
    if (initialValue !== emptyBase64Image) {
      sigCanvas.fromDataURL(initialValue, {
        width: window.innerWidth < 500 ? window.innerWidth : 500,
        height: window.innerWidth < 500 ? window.innerWidth / 2.5 : 500 / 2.5,
      });
    }
  }, []);

  const handleClear = () => {
    sigCanvas.clear();
  };

  const handleSubmit = () => {
    if (sigCanvas.isEmpty()) {
      onValueChange(emptyBase64Image);
    } else {
      onValueChange(sigCanvas.toDataURL('image/png'));
    }
    handleClose();
  };

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className={styles['edit-card']}
      variants={slideUp}
      initial='hidden'
      animate='visible'
      exit='exit'
      onAnimationStart={(definition) => {
        if (definition === 'visible') {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        }
      }}
    >
      <CloseIcon
        className={styles['close-button']}
        fontSize='large'
        onClick={handleClose}
      />
      <div className={styles['content-container']}>
        <h1 className={styles['header-text']}>Edit Doodle</h1>
        <HexColorPicker
          color={penColor}
          onChange={setPenColor}
          className={styles['pen-color-picker']}
        />
        <div className={styles['canvas-container']}>
          <SignatureCanvas
            penColor={penColor}
            canvasProps={{
              className: styles['signature-canvas'],
              width: window.innerWidth < 500 ? window.innerWidth : 500,
              height:
                window.innerWidth < 500 ? window.innerWidth / 2.5 : 500 / 2.5,
            }}
            ref={(ref) => {
              sigCanvas = ref;
            }}
          />
          <button className={styles['clear-button']} onClick={handleClear}>
            Clear
          </button>
        </div>
      </div>
      <button className={styles['save-button']} onClick={handleSubmit}>
        Save
      </button>
    </motion.div>
  );
};

export default DoodleEditCard;
