import React from "react";
import { TextField } from "@mui/material";
import styles from "./InputField.module.css";

const InputField = (props) => {
  const handleChange = (event) => {
    props.setValue(event.target.value);
  };
  return (
   <>
    
    <div className={styles["field"]}>
      <TextField
      disabled={props.disabled}
        required={props.requiredField}
        id="standard-text"
        label={`${props.field}`}
        value={props.value}
        onChange={handleChange}
        maxLength="80"
        multiline
        
        variant="standard"
        fullWidth={props.fullWidth}
        type={props.type}
        error={props.error?true:false}
        helperText={props.error}
        onKeyDown={props.onKeyDown}
        onClick={props.onClickFocus?props.onClickFocus:console.log()}
        InputProps={{
          style: { width: props.width},
        }}
      />
      </div>
    </>
  );
};
export default InputField;
