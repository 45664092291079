import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ContainerTitle from "./ContainerTitle/ContainerTitle";
import { motion } from "framer-motion";
import InputField from "./InputFields/InputField";
import styles from "./SignUp.module.css";
import desktopStyling from "../DesktopStyles.module.css";
import { createGRunnerUser } from "../../env/API";
import ClipLoader from "react-spinners/ClipLoader";
import validator from "validator";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/logo.png";

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z]+$/;
const SignUp = () => {
	const [userFname, setUserFname] = useState("");
	const [userLname, setUserLname] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");

	const [toastActive, setToastActive] = useState(false);
	const { push } = useHistory();
	const [loading, setLoading] = useState(false);

	let search = window.location.search;
	let params = new URLSearchParams(search);
	const inapp = params.get("inapp") || false;

	const handleGRunnerUserCreation = async (userData) => {
		let userInfo = {
			name: userData.fName + " " + userData.lName,
			firstName: userData.fName,
			lastName: userData.lName,
			email: userData.email,
			password: userData.password,
		};
		const res = await createGRunnerUser(
			userInfo.name,
			userInfo.firstName,
			userInfo.lastName,
			userInfo.email,
			userInfo.password
		);
		console.log(res?.data);

		if (res?.data?.code === "SUCCESS") {
			push({
				pathname: `/grunner/signup/phone_verify/?tokenId=${res.data.data.user.tokenId}&inapp=${inapp}`,
				state: res.data.data.user.tokenId,
			});
		} else if (res?.data?.code === "ERROR") {
			toast.error(res?.data?.data?.message);
			setLoading(false);
			setToastActive(true);
		} else {
			toast.error("There was an error during registration.");
			setLoading(false);
			setToastActive(true);
		}
	};

	const handleNext = (event) => {
		const gRunnerUserData = {
			fName: userFname.trim(),
			lName: userLname.trim(),
			email: userEmail.trim(),
			password: userPassword.trim(),
		};

		setUserEmail(gRunnerUserData.email);
		setUserPassword(gRunnerUserData.password);

		setLoading(true);

		let errorMessages = {};
		errorMessages.userFname = gRunnerUserData.fName
			? gRunnerUserData.fName
				? validator.isAlpha(gRunnerUserData.fName)
					? ""
					: "Enter Your First Name."
				: "Enter Your First Name."
			: "First Name is required.";
		errorMessages.userLname = gRunnerUserData.lName
			? gRunnerUserData.lName
				? validator.isAlpha(gRunnerUserData.lName)
					? ""
					: "Enter Your Last Name."
				: "Enter Your First Name."
			: "Last Name is required.";
		errorMessages.userEmail = gRunnerUserData.email
			? validator.isEmail(gRunnerUserData.email)
				? ""
				: "Email is not valid."
			: "Email is required.";
		errorMessages.userPassword = gRunnerUserData.password
			? gRunnerUserData.password.length > 5
				? ""
				: "Password must be at least 6 characters long."
			: "Password is required.";

		let toastMessages = [];
		if (errorMessages.userFname.length > 0) {
			toastMessages.push(
				errorMessages.userFname ? errorMessages.userFname : ""
			);
		}
		if (errorMessages.userLname.length > 0) {
			toastMessages.push(
				errorMessages.userLname ? errorMessages.userLname : ""
			);
		}
		if (errorMessages.userEmail.length > 0) {
			toastMessages.push(
				errorMessages.userEmail ? errorMessages.userEmail : ""
			);
		}
		if (errorMessages.userPassword.length > 0) {
			toastMessages.push(
				errorMessages.userPassword ? errorMessages.userPassword : ""
			);
		}

		if (toastMessages.length > 0) {
			for (let toastMessage of toastMessages) {
				if (toastMessage) {
					toast.error(toastMessage);
				}
			}
			setLoading(false);
			setToastActive(true);
		} else {
			handleGRunnerUserCreation(gRunnerUserData);
		}
	};

	return (
		<>
			{loading ? (
				<div className={styles["loading"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<div className={desktopStyling.mainContainer}>
					{inapp !== "true" && <img alt="logo" className={styles.logo} src={logo} />}

					<motion.div className={styles["main-container"]}>
						<div>
							<ContainerTitle title="Sign Up" />
							<div className={styles["grunner-input-field"]}>
								<InputField
									value={userFname}
									setValue={setUserFname}
									field={"First Name"}
									fullWidth={true}
									width={"100%"}
									type={"text"}
									onKeyDown={(event) => {
										if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
											event.preventDefault();
										}
									}}
								/>
							</div>
							<div className={styles["grunner-input-field"]}>
								<InputField
									value={userLname}
									setValue={setUserLname}
									field={"Last Name"}
									fullWidth={true}
									width={"100%"}
									type={"alpha"}
									onKeyDown={(event) => {
										if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
											event.preventDefault();
										}
									}}
								/>
							</div>
							<div className={styles["grunner-input-field"]}>
								<InputField
									value={userEmail}
									setValue={setUserEmail}
									field={"Email"}
									fullWidth={true}
									width={"100%"}
									type={"email"}
								/>
							</div>
							<div className={styles["grunner-input-field"]}>
								<InputField
									value={userPassword}
									setValue={setUserPassword}
									field={"Password"}
									fullWidth={true}
									width={"100%"}
									type={"password"}
								/>
							</div>
						</div>
						{toastActive ? <ToastContainer /> : <></>}

						<button className={styles["next-button"]} onClick={handleNext}>
							Next
						</button>
						{/* {inapp === "true" ? (
							<div className={styles["signup-signin-div"]}>
								<p>
									{" "}
									or{" "}
									<button
										className={styles["singup-signin-div-button"]}
										onClick={() => push(`/grunner/login?inapp=${inapp}`)}
									>
										<span className={styles["signup-signin-div-button-text"]}>
											{" "}
											Log In
										</span>
									</button>
								</p>
							</div>
						) : (
							<div className={styles["signup-signin-div"]}></div>
						)} */}
						{/* {inapp === "true" ? (
							<div className={styles.policyContainer}>
								By clicking “Next,” I agree to the{" "}
								<a className={styles.link} onClick={() => redirectTOS()}>
									Independent Contractor Agreement
								</a>{" "}
								and have read and agree to the{" "}
								<a className={styles.link} onClick={() => redirectPP()}>
									G-Runner Privacy Policy.
								</a>
							</div>
						) : ( */}
							<div className={styles.policyContainer}>
								By clicking “Next,” I agree to the{" "}
								<a
									href="https://help.gesture.vip/m-ica-terms-of-use/"
									className={styles.link}
									target="_blank" rel="noreferrer"
								>
									Independent Contractor Agreement
								</a>{" "}
								and have read and agree to the{" "}
								<a
									className={styles.link}
									href="https://help.gesture.vip/m-grunner-privacy-policy/"
									target="_blank" rel="noreferrer"
								>
									G-Runner Privacy Policy.
								</a>
							</div>
						{/* )} */}
					</motion.div>
				</div>
			)}
		</>
	);
};

export default SignUp;
