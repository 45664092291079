import { mainAPIUrl } from '../../../settings';

const baseURL = `${mainAPIUrl}/gservices_3/ss`;


export const GETSmartSendDataByssID = async (ssid) => {
  //const url= "https://us-central1-gesture-dev.cloudfunctions.net/gservices/ss/smartsend/smartsend-19aac7e0-79e3-42fb-af04-c3cbc62b5c3c";
  const url = `${baseURL}/smartsend/${ssid}`;
  const data = await fetch(url);
  const json = await data.json();
  return json;
}

export const GETSmartSendDataByOrderId = async (orderId) => {
  //const url= "https://us-central1-gesture-dev.cloudfunctions.net/gservices/ss/orders/123";
  const url = `${baseURL}/orders/v2/${orderId}`;
  const data = await fetch(url);
  const json = await data.json();
  const secureVerifyHeader = data?.headers?.get('X-Secure-Verify');
  return { result: json, secureVerifyHeader };
}

export const GETOffsetByLatLon = async (lat, lon, productId, state,country, orderId) => {
  //const url= "https://us-central1-gesture-dev.cloudfunctions.net/gservices/ss/zoning/v2?lat=2.1&lng=1.2";
  const url = `${baseURL}/zoning/v2?lat=${lat}&lng=${lon}&productId=${productId}&state=${state}&country=${country}&orderId=${orderId}`;
  const data = await fetch(url);
  const json = await data.json();
  return json;
}

export const POSTSmartSendByssID = async (ssid, ssdata, secureVerifyHeader) => {
  //const url= "https://us-central1-gesture-dev.cloudfunctions.net/gservices/ss/smartsend/testssid/complete";
  const url = `${baseURL}/smartsend/v2/${ssid}/complete`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'X-Secure-Verify': secureVerifyHeader 
    },
    body: JSON.stringify(ssdata)
  });
  const secureHeader = data?.headers?.get('X-Secure-Verify');
  const json = await data.json();
  return { result: json, secureHeader };
}

export const POSTSendSMS = async (phoneNumber) => {
  //const url= "https://us-central1-gesture-dev.cloudfunctions.net/gservices/ss/verify";
  const url = `${baseURL}/verify`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ phoneNumber: phoneNumber })
  });
  const json = await data.json();
  return json;
}

export const POSTDeclinedLocation = async (obj) => {
  const url = `${baseURL}/declined_location`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  });
  const json = await data.json();
  return json;
}


