import { mainAPIUrl } from "../../../settings";

const URL = `${mainAPIUrl}/gservices_3`;


export function GETUserToken(tokenId) {
	const url = `${URL}/chat/user/${tokenId}`;
	const data = fetch(url)
		.then(async (response) => {
			console.log("response", response);
			if (response?.ok) {
				return response.json();
			}
			else if (!response?.ok) {
				return response.json();
			}
			// throw new Error("Something went wrong");
		})
		.then((res) => {
			return res;
		})
		.catch((err) => {
			console.log(err);
		});
	return data;
}
