import { mainAPIUrl } from '../../../settings';

const URL = `${mainAPIUrl}/gservices_3/ss`

export function getLink(orderId) {
  const url = `${URL}/${orderId}/smartSendLink`;
  const data = fetch(url)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => { return res })
  return data;
}