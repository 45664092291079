import React from "react";

import styles from "./AppBar.module.css";
import back from "../assets/back.png";

const AppBar = () => {
	return (
		<div className={styles.appbar}>
			<div className={styles.backBtn}>
				<img
					src={back}
					alt="back button"
					className={styles.backBtnImage}
					onClick={() => {
						window?.ReactNativeWebView?.postMessage("back");
					}}
				/>
			</div>
			<h1 className={styles.title}>Add Your Card</h1>
		</div>
	);
};

export default AppBar;
