import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CreditCardInput from "./CreditCardInput";
import {getStripeKey} from "./env/APIManager"

const stripeKey = getStripeKey()

const stripePromise = loadStripe(stripeKey);

function mainPaymentPage() {
	return (
		<Elements stripe={stripePromise}>
            <CreditCardInput />
		</Elements>
	);
}

export default mainPaymentPage;
