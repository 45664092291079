import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import Feed from "./Feed";
import NavbarFeed from "./NavbarFeed";
import Floater from "../layout/Floater";
import {
	fetchFeedsAPICall,
	fetchOlderFeedsAPICall,
	fetchPrivateFeedsAPICall,
	getUserIdAndNameAPICall,
} from "../../env/APImanager";

import "./FeedScreen.css";

const FeedScreen = () => {
	const [feeds, setFeeds] = useState([]);
	const [loadingFeeds, setLoadingFeeds] = useState(false);
	const [loadingMoreFeeds, setLoadingMoreFeeds] = useState(false);
	const [hasMore, setHasMore] = useState(true);

	const [uid, setUid] = useState("");

	let search = window.location.search;
	let parameter = new URLSearchParams(search);
	const tokenId =
		parameter.get("tokenId") === null || parameter.get("tokenId") === "null"
			? null
			: parameter.get("tokenId");

	const appbar = parameter.get("appbar") || false;

	const os = parameter.get("os") || null;

	const appbarDisplay = appbar === "true";

	const [showPrivate, setShowPrivate] = useState(false);

	const feedStorageItems = JSON.parse(sessionStorage.getItem("feedStorage"));

	useEffect(() => {
		// window.scrollTo(0, 0)

		getUserIdAndName();

		if (os === "android" && tokenId) {
			fetchPrivateFeeds();
		} else {
			fetchFeeds();
		}
	}, []);

	useEffect(() => {
		handleGetScrollPosition();
	}, [feeds]);

	const fetchFeeds = async () => {
		setLoadingFeeds(true);
		setFeeds([]);
		let currentFeeds = [];

		if (
			feedStorageItems &&
			feedStorageItems.length > 0 &&
			feedStorageItems[0] === feeds[0] // if there is no new feed element in feeds state arr
		) {
			currentFeeds = feedStorageItems;
		} else {
			currentFeeds = await fetchFeedsAPICall(tokenId);
		}

		setFeeds(currentFeeds);
		sessionStorage.setItem("feedStorage", JSON.stringify(currentFeeds));

		setLoadingFeeds(false);
		setShowPrivate(false);
	};

	const fetchPrivateFeeds = async () => {
		setLoadingFeeds(true);
		setFeeds([]);

		const currentFeeds = await fetchPrivateFeedsAPICall(tokenId);

		setFeeds(currentFeeds);

		setLoadingFeeds(false);
		setShowPrivate(true);
	};

	const getUserIdAndName = async () => {
		const userRes = await getUserIdAndNameAPICall(tokenId);

		setUid(userRes.data.data.uid);
	};

	const getOlderFeeds = async () => {
		setLoadingMoreFeeds(true);

		sessionStorage.setItem("scrollPosition", window.pageYOffset);

		// const paginateKey = (feeds[feeds.length - 1].sorter + 1).toString()
		const paginateKey = (
			(feeds[feeds.length - 1].creationTime - 1) *
			-1
		).toString();

		const olderFeeds = await fetchOlderFeedsAPICall(paginateKey, tokenId);

		let newFeeds = [...feeds, ...olderFeeds];

		setFeeds(newFeeds);

		sessionStorage.setItem("feedStorage", JSON.stringify(newFeeds));

		setLoadingMoreFeeds(false);

		if (olderFeeds.length === 0) setHasMore(false);
	};

	const handleSaveScrollPosition = (e) => {
		sessionStorage.setItem("scrollPosition", window.pageYOffset);
	};

	const handleGetScrollPosition = () => {
		const scrollPosition = sessionStorage.getItem("scrollPosition");

		if (typeof parseInt(scrollPosition) === "number") {
			const parsedPosition = parseInt(scrollPosition);
			window.scrollTo(0, parsedPosition);
		}
	};

	return (
		<section className="feedsscreen-container">
			{appbarDisplay && tokenId && os !== "android" && (
				<NavbarFeed
					appbar={appbar}
					fetchFeeds={fetchFeeds}
					fetchPrivateFeeds={fetchPrivateFeeds}
					tokenid={tokenId}
					showPrivate={showPrivate}
				/>
			)}

			{tokenId &&
				(!appbarDisplay || appbarDisplay === null || appbarDisplay === false) &&
				os !== "android" && (
					<Floater
						appbar={appbar}
						fetchFeeds={fetchFeeds}
						fetchPrivateFeeds={fetchPrivateFeeds}
						tokenid={tokenId}
						showPrivate={showPrivate}
					/>
				)}

			<div>
				<section>
					{loadingFeeds && (
						<div className="feeds-loading">
							<ClipLoader size={50} color="#8585ff" />
						</div>
					)}

					{feeds
						.sort((a, b) => {
							// sorts the array by creationTime
							// the latest feed displays on top
							return b.creationTime - a.creationTime;
						})
						.map((feed) => (
							<Feed
								key={feed.id}
								feed={feed}
								tokenid={tokenId}
								userid={uid}
								handleSaveScrollPosition={handleSaveScrollPosition}
							/>
						))}

					{!loadingFeeds && hasMore && !showPrivate && (
						<button
							className="load-more-btn load-more-feeds"
							onClick={getOlderFeeds}
						>
							{loadingMoreFeeds ? (
								<div className="more-feeds-loading">
									<ClipLoader size={18} color="#fff" />
								</div>
							) : (
								"Load more"
							)}
						</button>
					)}
				</section>
			</div>
		</section>
	);
};

export default FeedScreen;
