import { mainAPIUrl } from '../../../settings';

const URL = `${mainAPIUrl}/grunnerv3`;


export function getAllGRunners(tokenId) {
    const url = `${URL}/admin/commands/grunners/all/${tokenId}`
    const data = fetch(url)
        .then((response) => {
            return response.json()
        })
        .then((response) => (response))
        .catch(error => {
            console.log(error)
            return null
        })
    return data
}

export function getGRunner(grunnerId, tokenId) {
    const url = `${URL}/admin/commands/grunners/single/${grunnerId}/${tokenId}`
    const data = fetch(url)
        .then((response) => {
            return response.json()
        })
        .then((response) => (response))
        .catch(error => {
            console.log(error)
            return null
        })

    return data
}

export function getAllTasks(tokenId) {
    const url = `${URL}/admin/commands/tasks/all/${tokenId}`
    const data = fetch(url)
        .then((response) => {
            return response.json()
        })
        .then((response) => (response))
        .catch(error => {
            console.log(error)
            return null
        })

    return data
}

export function userLogin (email, password) {
    const url = `${URL}/admin/users/login`
    const data = fetch(url, {
        headers: {
            'Content-Type': 'application/json'
        },
        method:'POST',
        body: JSON.stringify({email:email, password:password})
    })
    .then((response) => {
        return response.json()
    })
    .then((response) => (response))
    .catch(error => {
        console.log(error)
        return null
    })

    return data
}

export function addTask(taskId, grunnerId, tokenId) {
    const url = `${URL}/admin/commands/tasks/connection/${tokenId}`
    const data = fetch(url, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({taskId: taskId, grunnerId: grunnerId})
    })
    .then((response) => {
        return response.json()
    })
    .then((response) => (response))
    .catch(error => {
        console.log(error)
        return null
    })

    return data
}

export function removeTask(taskId, grunnerId, tokenId) {
    const url = `${URL}/admin/commands/tasks/removal/${tokenId}`
    const data = fetch(url, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: 'POST',
        body: JSON.stringify({taskId: taskId, grunnerId: grunnerId})
    })
    .then((response) => {
        return response.json()
    })
    .then((response) => (response))
    .catch(error => {
        console.log(error)
        return null
    })

    return data
}

export function getRunnerHistory(grunnerId, tokenId) {
    const url = `${URL}/admin/commands/grunners/history/single/${grunnerId}/${tokenId}`
    const data = fetch(url)
        .then((response) => {
            return response.json()
        })
        .then((response) => (response))
        .catch(error => {
            console.log(error)
            return null
        })

    return data
}

export function getPickupPoints(tokenId) {
    const url = `${URL}/admin/commands/pickup_points/all/${tokenId}`
    const data = fetch(url)
        .then((response) => {
            return response.json()
        })
        .then((response) => (response))
        .catch(error => {
            console.log(error)
            return null
        })

    return data
}