import React from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/CloseRounded';

import Backdrop from './Backdrop';
import { dropIn } from '../utils';
import styles from './Modal.module.css';

const Modal = ({ text, handleClose }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={styles['modal']}
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
        onAnimationStart={(definition) => {
          if (definition === 'visible') {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
          }
        }}
      >
        <CloseIcon
          className={styles['close-button']}
          fontSize='large'
          onClick={handleClose}
        />
        <div className={styles['text-container']}>
          <p className={styles['text']}>{text}</p>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default Modal;
