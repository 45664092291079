import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./InputField.module.css";

const InputField = (props) => {
  const handleChange = (event) => {
    if (props.type === "password") {
      props.setValue(event.target.value.trim());
    } else {
      props.setValue(event.target.value)
    }
  };
  const handleClear = (event) => {
    props.setValue("");
  };
  return (
    <>
      <div className={styles["field"]}>
        <TextField
          required
          id="standard-text"
          label={`${props.field}`}
          value={props.value}
          onChange={handleChange}
          maxLength="50"
          variant="standard"
          fullWidth={props.fullWidth}
          type={props.type}
          helperText={props.error}
          onKeyDown={props.onKeyDown}
          InputProps={{
            style: { width: props.width },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear text"
                  onClick={handleClear}
                  edge="end"
                >
                  {" "}
                  {props.value ? (
                    <CancelIcon />
                  ) : (
                    <CancelIcon color="disabled" sx={{ opacity: 0 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};
export default InputField;
