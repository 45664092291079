import React from 'react';
import { motion } from 'framer-motion';
import Confetti from 'react-confetti';

import PostCard from './PostCard';
import './Animation.css';
import giftBox from '../../assets/GiftBox.gif';

const Animation = ({ message, fallbackAssets }) => {
  const MotionPostCard = motion(PostCard);

  const giftBoxVariant = {
    show: { opacity: 1 },
    hidden: {
      opacity: 0,
      transition: {
        delay: 1.6,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  };

  const occasionTextVariant = {
    hidden: { y: '100vh', fontSize: '0px', opacity: 0 },
    show: {
      y: '0',
      fontSize: '50px',
      opacity: [null, 1, 1, 0],
      transition: {
        fontSize: {
          delay: 1.1,
        },
        y: {
          delay: 1.1,
        },
        opacity: {
          delay: 1.1,
          duration: 2.1,
          times: [0, 0.1, 0.9, 1],
        },
      },
      transitionEnd: {
        display: 'none',
      },
    },
  };

  const postCardVariant = {
    hidden: { display: 'none', opacity: 0 },
    show: {
      display: 'block',
      opacity: 1,
      transition: { delay: 3.2, duration: 1, ease: 'easeInOut' },
    },
  };

  return (
    <div className='animation-container'>
      <Confetti recycle={false} width={window.innerWidth - 10} />
      <div className='post-card-animation-container'>
        <MotionPostCard
          message={message}
          fallbackAssets={fallbackAssets}
          preview={false}
          variants={postCardVariant}
          initial='hidden'
          animate='show'
        />
      </div>
      <div className='giftbox-animation-container'>
        <motion.img
          className='gift-box'
          src={giftBox}
          alt='gift box'
          variants={giftBoxVariant}
          initial='show'
          animate='hidden'
        />
        <motion.h1
          className='occasion-text'
          variants={occasionTextVariant}
          initial='hidden'
          animate='show'
        >
          {message.from} sent you a Gesture!
        </motion.h1>
      </div>
    </div>
  );
};

export default Animation;
