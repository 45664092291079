import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './PickUpConfirmationModal.module.css'
const PickUpConfirmationModal = ({ show,setShow,confirmation,setConfirmation,taskId,handleConfirmation
}) =>{
  
  
    const handleNo = () => setShow(false);
    const handleYes = () => {
      handleConfirmation();
      setShow(false);
    }
return (
<Modal show={show} animation={true} style={{marginTop:"40vh"}}>
  <Modal.Body style={{
          display: "flex",
          backgroundColor:"#fff",
          justifyContent: "center",
        }}>
          Are you sure you want to pick up?
        </Modal.Body>
    <Modal.Footer style={{
          display: "flex",
          backgroundColor:"#fff",
          justifyContent: "center",
        }}>
      <button
            className={styles['cancel-button']} onClick={()=>handleNo()}>
        Cancel
      </button>
      <button
            className={styles['okay-button']} onClick={()=>handleYes()}>
        Confirm
      </button>
    </Modal.Footer>
  </Modal>
)}
export default PickUpConfirmationModal;