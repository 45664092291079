import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/CloseRounded';

import { convertBlobToBase64, slideUp } from '../utils';
import styles from './SelfieEditCard.module.css';
import pstyles from './AskPermission.module.css';
import Compressor from 'compressorjs';
import SearchImageCard from './SearchImageCard'
import {POSTTrackDownload} from '../../env/APImanager'
import close from '../../assets/close.png'


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
const SelfieEditCard = ({ handleClose, initialValue, onValueChange }) => {
  const [selfie, setSelfie] = useState(initialValue);
  const { height } = useWindowDimensions();

  let selfieInputElement = useRef(null);

  const handleChange = (e) => {
    setdownload_location("")
    const fileList = e.target.files;
    let file = null;


		for (let i = 0; i < fileList.length; i++) {
			if (fileList[i].type.match(/^image\//)) {
				file = fileList[i];
				break;
			}
		}

		if (file !== null) {
			new Compressor(file, {
				quality: 0.6,
				success(result) {
					convertBlobToBase64(result).then((selfieBase64) => {
						setSelfie(selfieBase64);
					});
				},
			});
		}
	};

const handleViewBtn =()=>{
  var modal = document.getElementById("myModal");
  modal.style.display = "flex";
}

const handleCloseBtn =()=>{
  var modal = document.getElementById("myModal");
  console.log(modal)
  modal.style.display = "none";
}

  const handleSubmit = () => {
    onValueChange(selfie);
    if (download_location){
      POSTTrackDownload({download_location})
      .then(
        (result)=>{
          console.log('success')
        },
        (error) => {
          console.log('error')
        }
      );
    }
    handleClose();
  };

  const [searchImgCardOpen, setSearchImgCardOpen] = useState(false);

  const handlesearchImgClick = () => {
    setSearchImgCardOpen(!searchImgCardOpen);
  };

  const handlesearchImgChange = (newImg) => {
    setSelfie(newImg);
  };

  const [download_location, setdownload_location] = useState("");

  const updateDownloadLocation=(downloadurl)=>{
    setdownload_location(downloadurl);
  }

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className={styles['edit-card']}
      variants={slideUp}
      initial='hidden'
      animate='visible'
      exit='exit'
      onAnimationStart={(definition) => {
        if (definition === 'visible') {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        }
      }}
    >
      <div className={styles['content-container']}>
        <div className={styles['header']}>
          <CloseIcon
            className={styles['close-button']}
            fontSize='large'
            onClick={handleClose}
          />
          <h1 className={styles['header-text']}>Edit Image</h1>
          {selfie!==initialValue? 
          <button className={styles['save-button']} onClick={handleSubmit}>
            Save
          </button>:<></>}
        </div>
        {selfie!==initialValue? 
        <>
        <img
          id='selfie-preview'
          className={styles['selfie-preview']}
          src={selfie}
          alt='selfie preview'
          onClick={() => selfieInputElement.current.click()}
        />
        <div className={styles['custom-image-upload-position1']}>
            <span onClick={handleViewBtn} className={height<=600 ? styles['ask-selfie1']:styles['ask-selfie']} style={{width:'95%'}}>
              Can’t take a selfie?
            </span>
            <label for='selfie-upload' className={ height<=600 ? styles['custom-image-upload1']:styles['custom-image-upload']}>
            Choose From Gallery/Camera
            </label>
            <label onClick={handlesearchImgClick} className={ height<=600 ? styles['custom-image-upload1']:styles['custom-image-upload']}>
            Search Other Images
            </label>
        </div>
        </>  
        :<div className={styles['custom-image-upload-position']}>
            <span onClick={handleViewBtn} className={styles['ask-selfie']}>
              Can’t take a selfie?
            </span>
            <label for='selfie-upload' className={styles['custom-image-upload']}>
            Choose From Gallery/Camera
            </label>
            <label className={styles['custom-image-upload']} onClick={handlesearchImgClick}>
            Search Other Images
            </label>
        </div>}
        <input
          id='selfie-upload'
          className={styles['default-image-upload']}
          type='file'
          accept="image/*"
          onChange={handleChange}
          ref={selfieInputElement}
        />
      </div>

      <div id="myModal" className={pstyles['edit-card']}>
					<div className={pstyles['content-box']}>
            <div className={pstyles['close-box']}>
              <img
                alt="close button"
                src={close}
                className={pstyles['close']}
                onClick={handleCloseBtn}
              ></img>
            </div>
              <p className={pstyles['text-box']}>If you can't take a selfie/picture please give Gesture access to your camera from your phone's settings. This will allow you to take a picture for the Personalized Message screen.</p>		
					</div>
      </div>

      {searchImgCardOpen && (
          <SearchImageCard
            handleClose={handlesearchImgClick}
            onValueChange={handlesearchImgChange}
            setDownloadLocation={updateDownloadLocation}
          />
      )}
    </motion.div>
  );
};

export default SelfieEditCard;
