import React, { useState, useEffect } from "react";
import {
	fetchEditMessageAPICall,
	createMessageAPICall,
	fetchOccasionMessagesAPICall,
	getPostCardDesigns,
} from "../../env/APImanager";
import { AnimatePresence } from "framer-motion";
import ClipLoader from "react-spinners/ClipLoader";
import ReactJoyride from "react-joyride";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";

import AppBar from "../appbar/AppBar";
import PostCard from "./PostCard";
import styles from "./MessageEdit.module.css";
import { emptyBase64Image, errorToastOptions } from "../utils";
import DeleteModal from "./DeleteModal";
import defaultSelfie from "../../assets/default-selfie.png";
import postCardLogo from "../../assets/post-card-logo.png";
import postCardNames from "../../assets/post-card-names.png";
import postCardSelfie from "../../assets/post-card-selfie.png";
import postCardMessage from "../../assets/post-card-message.png";
import postCardDoodle from "../../assets/post-card-doodle.png";
import DesignEditCard from "../editcards/DesignEditCard";

const MessageEdit = () => {
	const [editMessage, setEditMessage] = useState({});
	const [editingExistingMessage, setEditingExistingMessage] = useState(false);
	const [prewrittenMessages, setPrewrittenMessages] = useState([]);
	const [displayPrewrittenMessages, setDisplayPrewrittenMessages] =
		useState(true);
	const [loadingData, setLoadingData] = useState(false);
	const [saveLoading, setSaveLoading] = useState(false);
	const [postCardDesigns, setPostCardDesigns] = useState({});
	const [selectedDesign, setSelectedDesign] = useState({
		designElements: {
			1: postCardLogo,
			2: postCardNames,
			3: postCardSelfie,
			4: postCardMessage,
			5: postCardDoodle,
		},
	});
	const [useFallbackAssets, setUseFallbackAssets] = useState(true);

	let search = window.location.search;
	let params = new URLSearchParams(search);
	const editTokenId = params.get("editTokenId");
	const inapp = params.get("inapp") || false;
	const occasionId = params.get("occasionId");
	let recipientName = params.get("recipientName");
	let senderName = params.get("senderName");
	const tokenId = params.get("userTokenId");

	const [joyride, setJoyride] = useState({
		run: false,
		steps: [
			{
				title: "Welcome to Gesture's Personalized Message Editor!",
				target: "body",
				placement: "center",
				content: (
					<p>
						Press Next to start the guided tour to create your personalized
						message.
					</p>
				),
				disableBeacon: true,
			},
			{
				title: "Recipient Name",
				target: "#to-editable-field",
				content: <p>Here you can add the recipient name.</p>,
			},
			{
				title: "Sender Name",
				target: "#from-editable-field",
				content: <p>Here you can add the sender name.</p>,
			},
			{
				title: "Add Selfie",
				target: "#selfie-edit-button",
				content: (
					<p>
						Tap this button to take a selfie or choose a photo from your gallery
					</p>
				),
			},
			{
				title: "Preview Selfie",
				target: "#selfie-fullscreen-button",
				content: <p>Tap this button to preview the full image.</p>,
			},
			{
				title: "Message",
				target: "#message-text",
				content: <p>Here you can add the message.</p>,
			},
			{
				title: "Doodle",
				target: "#doodle-edit-button",
				content: (
					<p>
						Here you can open our doodle editor and draw a custom doodle to
						personalize your message.
					</p>
				),
			},
			{
				title: "Save",
				target: "#save-button",
				content: (
					<p>
						When you are ready to save this personalized message, tap this
						button and see the final preview. Enjoy creating your personalized
						message!
					</p>
				),
			},
		],
	});

	useEffect(() => {
		window.scrollTo(0, 0);
		fetchAllData();
		document.body.classList.add(styles["message-background"]);

		return () => {
			document.body.classList.remove(styles["message-background"]);
		};
	}, []);

	const fetchPostCardDesigns = async () => {
		setUseFallbackAssets(true);
		setPostCardDesigns({});
		const availableDesigns = await getPostCardDesigns(tokenId);
		if (availableDesigns) {
			setUseFallbackAssets(false);
			setPostCardDesigns(availableDesigns);
			if (availableDesigns.defaultDesign) {
				const designToSelect = availableDesigns.postCardDesignSections
					.map((section) => section.designs)
					.flat(1)
					.find((design) => design.id === availableDesigns.defaultDesign);
				setSelectedDesign(designToSelect);
			} else if (
				availableDesigns.postCardDesignSections &&
				availableDesigns.postCardDesignSections[0].designs
			) {
				setSelectedDesign(
					availableDesigns.postCardDesignSections[0].designs[0]
				);
			}
		}
	};

	const fetchEditMessage = async () => {
		setEditMessage({});
		let currentMessage;
		if (editTokenId && editTokenId !== "null" && editTokenId !== "undefined") {
			currentMessage = await fetchEditMessageAPICall(editTokenId);
		}

		if (!editTokenId || !currentMessage) {
			if (!senderName || senderName === "null" || senderName === "undefined") {
				senderName = "";
			}
			if (
				!recipientName ||
				recipientName === "null" ||
				recipientName === "undefined"
			) {
				recipientName = "";
			}
			setEditMessage({
				from: senderName,
				to: recipientName,
				message: "",
				selfie: defaultSelfie,
				doodle: emptyBase64Image,
			});
		} else {
			setEditingExistingMessage(true);
			setEditMessage(currentMessage);
			if (currentMessage.designElements) {
				setSelectedDesign({
					designElements: currentMessage.designElements,
					previewImage: currentMessage.previewImage,
				});
			}
		}
	};

	const fetchOccasionMessages = async () => {
		if (!occasionId || occasionId === "null" || occasionId === "undefined") {
			setDisplayPrewrittenMessages(false);
		} else {
			setPrewrittenMessages([]);
			const currentMessages = await fetchOccasionMessagesAPICall(occasionId);
			if (currentMessages == null) {
				setDisplayPrewrittenMessages(false);
			} else {
				setPrewrittenMessages(currentMessages);
				setJoyride({
					run: joyride.run,
					steps: [
						...joyride.steps.slice(0, 6),
						{
							title: "Pre-Written Messages",
							target: "#choose-prewritten-message-button",
							content: (
								<p>
									Here you can choose a message from some pre-written messages.
								</p>
							),
						},
						...joyride.steps.slice(6),
					],
				});
			}
		}
	};

	const fetchAllData = async () => {
		setLoadingData(true);
		await fetchPostCardDesigns();
		await fetchEditMessage();
		await fetchOccasionMessages();

		setLoadingData(false);

		if (localStorage.getItem("viewed-joyride") !== "true") {
			setJoyride({ ...joyride, run: !editTokenId });
			localStorage.setItem("viewed-joyride", "true");
		}
	};

	const handleSave = async (messageData, privacy) => {
		if (!messageData.senderName) {
			toast.error("Sender name is required.", errorToastOptions);
		} else if (
			!messageData.personalMessage &&
			messageData.selfieBase64 === defaultSelfie &&
			messageData.drawingBase64 === emptyBase64Image
		) {
			toast.error(
				"Please add a message or a selfie or a doodle to proceed.",
				errorToastOptions
			);
		} else {
			setSaveLoading(true);
			if (editTokenId) {
				messageData.editTokenId = editTokenId;
			}
			const messageId = await createMessageAPICall(messageData, occasionId);
			if (messageId === null) {
				setSaveLoading(false);
				toast.error(
					"Something went wrong, please try again later.",
					errorToastOptions
				);
			} else {
				setSaveLoading(false);
				if (inapp === "true") {
					const params = {
						pmId: messageId,
						privacy: privacy,
					};
					window?.ReactNativeWebView?.postMessage(JSON.stringify(params));
				} else {
					window.location.href = `/messages?tokenId=${messageId}`;
				}
			}
		}
	};

	const handleDesignChange = (newDesign) => {
		setSelectedDesign(newDesign);
	};
	const [designEditCardOpen, setDesignEditCardOpen] = useState(false);

	const handleDesignEditClick = () => {
		setDesignEditCardOpen(!designEditCardOpen);
	};
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const handleDeleteModalClick = () => {
		setDeleteModalOpen(!deleteModalOpen);
	};
	const hideBeacon = styled.div`
		display: none;
	`;
	return (
		<section className={styles["message-card-container"]}>
			{saveLoading && (
				<div className={styles["save-loading"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			)}
			{loadingData ? (
				<div className={styles["message-loading"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<>
					<ReactJoyride
						steps={joyride.steps}
						run={joyride.run}
						continuous
						showProgress
						showSkipButton
						beaconComponent={hideBeacon}
						styles={{
							options: {
								arrowColor: "#eee",
								backgroundColor: "#eee",
								primaryColor: "#2b08a0",
								textColor: "#1F1E1E",
								width: 500,
								zIndex: 1000,
								height: "70px",
							},
							tooltipTitle: {
								fontSize: "16",
								color: "#1F1E1E",
								fontFamily: "Mulish",
								paddingTop: "10px",
							},
							tooltipContent: {
								fontSize: 14,
								color: "#1F1E1E",
								fontFamily: "Mulish",
							},
							buttonSkip: {
								fontSize: 14,
								color: "#1F1E1E",
								fontFamily: "Mulish",
								fontWeight: "600",
							},
							buttonClose: {
								padding: "10px",
							},
							buttonBack: {
								fontSize: 14,
								fontFamily: "Mulish",
								fontWeight: "600",
							},
							buttonNext: {
								fontSize: 14,
								width: "25%",
								height: "auto",
								color: "#fff",
								backgroundColor: "#2b08a0",
								fontFamily: "Mulish",
								outline: "none",
							},
						}}
					/>
					<ToastContainer />
					<AnimatePresence
						initial={false}
						exitBeforeEnter={true}
						onExitComplete={() => {
							window.scrollTo(0, 0);
							document.body.style.overflow = "unset";
						}}
					>
						{deleteModalOpen && (
							<DeleteModal handleClose={handleDeleteModalClick} />
						)}
						{designEditCardOpen && (
							<DesignEditCard
								handleClose={handleDesignEditClick}
								initialValue={postCardDesigns.postCardDesignSections}
								onValueChange={handleDesignChange}
							/>
						)}
					</AnimatePresence>
					{inapp === "true" && (
						<AppBar
							editingExistingMessage={editingExistingMessage}
							handleDeleteClick={handleDeleteModalClick}
						/>
					)}
					{useFallbackAssets === false && (
						<div className={styles["design-edit-card-opener"]}>
							<div
								className={styles["preview-container"]}
								onClick={handleDesignEditClick}
							>
								<img
									alt="Postcard Design"
									className={styles["preview-image-line"]}
									src={selectedDesign.previewImage}
								></img>
								<button
									className={styles["change-button"]}
									id="change-button"
									onClick={handleDesignEditClick}
								>
									Change Design
								</button>
							</div>
						</div>
					)}

					<PostCard
						editMessage={editMessage}
						prewrittenMessages={prewrittenMessages}
						displayPrewrittenMessages={displayPrewrittenMessages}
						handleSave={handleSave}
						inApp={inapp}
						selectedDesign={selectedDesign}
					/>
				</>
			)}
		</section>
	);
};

export default MessageEdit;
