import { userAPIUrl } from "../../../settings";
import axios from "axios";

const baseURL = `${userAPIUrl}/g3`;


export async function updateDefaultCard(paymentMethodId, nickName, idToken) {
	const url = baseURL + `/users/${idToken}/cc`;

	const postObj = {
		paymentMethodId,
		nickName,
	};
	try {
		const response = await axios.post(url, postObj, {
			headers: {
				"Content-Type": "application/json",
			},
		});

		return response.data;
	} catch (e) {
		return e.response;
	}
}

export function getStripeKey() {
	return process.env.REACT_APP_STRIPE_KEY;
}
