import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./NavBar.module.css";

let search, params, tokenId, view, orderId, refreshbar;

function NavBar(props) {
	search = window.location.search;
	params = new URLSearchParams(search);
	orderId = params.get("orderId");
	tokenId = params.get("tokenId");
	view = params.get("view");
	refreshbar = params.get("refreshbar");
	let consumerView = `/history?orderId=${orderId}&tokenId=${tokenId}&app=${true}&view=consumer&refreshbar=${refreshbar}`;
	let recipientView = `/history?orderId=${orderId}&tokenId=${tokenId}&app=${true}&view=recipient&refreshbar=${refreshbar}`;
	return (
		<section className={styles.headerTitles}>
			<div className={styles.flexContainer}>
				<div className={styles.linksContainer}>
					<NavLink
						onClick={() => props.updateConsumer("consumer")}
						to={consumerView}
						activeClassName={
							view === "consumer" || view === undefined || view === null
								? styles.activeClass
								: styles.link
						}
						className={styles.link}
					>
						<h3 className={styles.headerTitle}>SENT</h3>
					</NavLink>
					<NavLink
						onClick={() => props.updateRecipient("recipient")}
						activeClassName={
							view === "recipient" ? styles.activeClass : styles.link
						}
						className={styles.link}
						to={recipientView}
					>
						<h3 className={styles.headerTitle}>RECEIVED</h3>
					</NavLink>
				</div>
				
				<div className={styles.filtersContainer}>
					<div className={styles.box}>
						<span 
						className={			
							(props.selectedStatus === "" || props.selectedStatus === "All") ? styles.activeFilter :styles.filter
						}
						id={0}
						onClick={(e) => props.handleFilter(e, "All")}>All</span>
						<span 
						className={			
							props.selectedStatus === "Pending" ? styles.activeFilter :styles.filter
						} 
						id={1}
						onClick={(e) => props.handleFilter(e, "Pending")}>Pending</span>
						<span 
						className={			
							props.selectedStatus === "Scheduled" ? styles.activeFilter :styles.filter
						} 
						id={2}
						onClick={(e) => props.handleFilter(e, "Scheduled")}>Scheduled</span>
						<span 
						className={			
							props.selectedStatus === "Delivered" ? styles.activeFilter :styles.filter
						} 
						id={3}
						onClick={(e) => props.handleFilter(e, "Delivered")}>Delivered</span>
						<span 
						className={			
							props.selectedStatus === "Cancelled" ? styles.activeFilter :styles.filter
						} 
						id={4}
						onClick={(e) => props.handleFilter(e, "Cancelled")}>Cancelled</span>
					</div>
				</div>
			</div>
		</section>
	);
}

export default NavBar;
