import axios from 'axios';
import { mainAPIUrl } from '../../../settings';

const baseURL = `${mainAPIUrl}/gservices_3/messages`;

// Card.js
export const fetchMessageAPICall = async (tokenId) => {
  const url = `${baseURL}/${tokenId}`;

  if (tokenId == null) {
    return null;
  } else {
    const res = await axios.get(url);
    const currentMessage = res.data.data;

    return currentMessage;
  }
};
