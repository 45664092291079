import React from 'react';
import { motion } from 'framer-motion';

import Backdrop from './Backdrop';
import styles from './ConfirmModal.module.css';

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

const ConfirmModal = ({ verifyData }) => {
  return (
    <Backdrop>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={styles['modal']}
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
        onAnimationStart={(definition) => {
          if (definition === 'visible') {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
          }
        }}
      >
        {verifyData.logo && (
          <img
            src={verifyData.logo}
            alt={`${verifyData.title} logo`}
            className={styles['brand-logo']}
          />
        )}
        {verifyData.title ? (
          <>
            <h1 className={styles['heading']}>
              {
                verifyData?.greetingForVerifiedEmail ||
                `Welcome ${verifyData.title} Employee!`
              }
            </h1>
            <div className={styles['text-container']}>
              <p className={styles['text']}>
                {
                  verifyData?.messageForVerifiedEmail ||
                  `Your email has been verified, now you can see new products and
                  discounted prices for ${verifyData.title} employees.`
                }
              </p>
            </div>
          </>
        ) : (
          <div className={styles['text-container']}>
            <p className={styles['text']}>Your email has been verified.</p>
          </div>
        )}
        <div className={styles['buttons-container']}>
          <button
            className={styles['okay-button']}
            onClick={() => {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({
                  emailVerified: verifyData.emailVerified,
                  discountedPrice: verifyData.discountedPrice,
                  command: verifyData.command,
                })
              );
            }}
          >
            Back to the app
          </button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default ConfirmModal;
