import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import styles from "./CompleteRegistration.module.css";
import { getUserByTokenId } from "../../env/API";

import appStore from "../../assets/app-store-link.png";
import playStore from "../../assets/play-store-link.png";
import logo from "../../assets/logo.png";

const G_RUNNER_LOGIN_DEEP_LINK = process.env.REACT_APP_G_RUNNER_LOGIN_DEEP_LINK;

const CompleteRegistration = () => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const tokenId = params.get("tokenId");
	const inapp = params.get("inapp") || false;

	console.log(tokenId);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [loading, setLoading] = useState(false);
	const [isPending, setIsPending] = useState(false);

	useEffect(() => {
		getUser(tokenId);
	}, []);

	const getUser = async (tokenId) => {
		setLoading(true);
		const res = await getUserByTokenId(tokenId);
		if (res?.data?.code === "SUCCESS") {
			// Redirect user to app if inapp is true
			if (inapp) {
				return redirectUserToApp();
			}
			setFirstName(res?.data?.data?.user?.firstName);
			setLastName(res?.data?.data?.user?.lastName);
			handlePending(res?.data?.data?.user);
			setLoading(false);
		} else {
			setLoading(false);
		}
		console.log(res);
	};

	function handlePending(user) {
		if (user?.verificationState !== "pending") {
			return setIsPending(false);
		}

		setIsPending(true);
		window?.ReactNativeWebView?.postMessage?.(
			JSON.stringify({ command: "complete" })
		);
	}

	function redirectUserToApp() {
		window.location.href = `${G_RUNNER_LOGIN_DEEP_LINK}?tokenId=${tokenId}`;
	}

	return (
		<>
			{loading ? (
				<div className={styles["screen-loading"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<div
					style={{
						width: "100%",
						minHeight: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{inapp === "true" ? (
						<>
							<div className={styles.mainContainer}>
									<img className={styles.logo} src={logo} alt="Logo"/>
									{!isPending ?
										<>
											<div className={styles.text}>
												Thank you {firstName ? firstName : null}{" "}
												{lastName ? lastName : null} for signing up as a Gesture G-Runner.{" "}
											</div>
											<div className={styles.text}>
												Return to the app to start delivering orders.
											</div>
										</>
										:
										<>
											<div className={styles.text}>
												Verification is underway and may take some time.
											</div>
											<div className={styles.text}>
												Please check the G-Runner app later for updates.
											</div>
										</>
									}
							</div>
						</>
					) : (
						<div className={styles.mainContainer}>
							<img className={styles.logo} src={logo} alt="Logo"/>
							{!isPending ?
								<>
									<div className={styles.text}>
										Thank you {firstName ? firstName : null}{" "}
										{lastName ? lastName : null} for signing up with Gesture{" "}
									</div>
									<div className={styles.text}>
										{" "}
										Download G-Runner app to start delivering orders.
									</div>
								</>
								:
								<>
									<div className={styles.text}>
										Verification is underway and may take some time.
									</div>
									<div className={styles.text}>
										Download G-Runner app for latest updates.
									</div>
								</>
							}
							<div className={styles.text}>The app is available on: </div>

							<div className={styles.links}>
								<a
									target="_blank"
									href="https://gesturegrunner.page.link/complete" rel="noreferrer"
								>
									<img
										className={styles.storeLogos}
										src={playStore}
										alt="play store link"
									/>
								</a>
								<a
									target="_blank"
									href="https://gesturegrunner.page.link/complete" rel="noreferrer"
								>
									<img
										className={styles.storeLogos}
										src={appStore}
										alt="app store link"
									/>
								</a>
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export default CompleteRegistration;
