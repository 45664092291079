import noOrder from '../../assets/no-order-new.png';
import styles from './NoOrderHistory.module.css'


export function NoOrder(){
return(
 
    <div className={styles.outer}>
    <div className={styles.noorderhistory} >
    </div>
        <div><img src={noOrder} alt="noOrder" className={styles.noOrderimg} ></img></div>
   

    {/* <div className="noorderHistory-new">
        <div><img src={test} alt="noOrder" className="noOrderimg" ></img></div>
    </div> */}
    </div>
   
)
}