import React from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/CloseRounded';
import AddIcon from '@mui/icons-material/Add';

import { slideUp } from '../utils';
import styles from './PrewrittenMessagesCard.module.css';

const PrewrittenMessagesCard = ({ handleClose, onValueChange, messages }) => {
  const handleSubmit = (message) => {
    onValueChange(message);
    handleClose();
  };

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className={styles['edit-card']}
      variants={slideUp}
      initial='hidden'
      animate='visible'
      exit='exit'
      onAnimationStart={(definition) => {
        if (definition === 'visible') {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        }
      }}
    >
      <CloseIcon
        className={styles['close-button']}
        fontSize='large'
        onClick={handleClose}
      />
      <div className={styles['content-container']}>
        <h1 className={styles['header-text']}>Pre-Written Messages</h1>
        <p>
          Don’t know what to say? We got you. Here are some creative pre-written
          messages specific to this vibe.
        </p>
        {messages.map(
          (message, index) =>
            message && (
              <button
                key={index}
                className={styles['prewritten-message']}
                onClick={() => handleSubmit(message)}
              >
                <AddIcon
                  className={styles['message-add-icon']}
                  onClick={handleClose}
                />
                {message}
              </button>
            )
        )}
      </div>
    </motion.div>
  );
};

export default PrewrittenMessagesCard;
