import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
// import { timeSince } from '../../components/utils/utils'
// import { timeSince } from '../../utils/utils'
import { timeSince } from '../utils';
// import EditFeedModal from '../layout/modals/EditFeedModal'
import EditFeedModal from '../layout/EditFeedModal';

const Feed = ({ feed, tokenid, userid, handleSaveScrollPosition }) => {
  const [feedID, setFeedID] = useState(feed.id); // to pass feed id to modal
  const [feedOwnerUid, setFeedOwnerUid] = useState(feed.ownder_id);
  const [modalShow, setModalShow] = useState(false);

  const [showMore, setShowMore] = useState(false);
  const maxDisplayTextLength = 140;
  // const isMessageTooLong = feed.message.length > 500

  let search = window.location.search;
  let parameter = new URLSearchParams(search);
  const appbarRes = parameter.get('appbar') || false;

  const appbar = appbarRes === 'true';

  // let search = window.location.search
  // let parameter = new URLSearchParams(search)
  // const tokenId = parameter.get('tokenId') // composerName = 'Misbah' if tokenId === '123' else 'Anthony' go check FeedScreen.js line 16

  const handleModalGetFeedID = data => {
    setFeedID(data.id);
    setFeedOwnerUid(data.owner_id);
    setModalShow(true);
  };

  const handleShowMore = e => {
    e.preventDefault();
    setShowMore(true);
  };

  /*

 if text.length > 120
    if showMore === true
      display the whole text
    else
      display text + ... + Show more
else
  display the whole text

*/

  return (
    <>
      {' '}
      <EditFeedModal
        uid={userid}
        show={modalShow}
        tokenid={tokenid}
        ispublic={feed.sorter === '~' ? false : true}
        feedid={feedID}
        feedowneruid={feedOwnerUid}
        onHide={() => setModalShow(false)}
      />
      <Card key={feed.id}>
        <Card.Header className='feed-post-header'>
          <p className='post-fromto-info my-1 text-center'>
            <span className='post-fromto'>{feed.from}</span> sent a Gesture to{' '}
            <span className='post-fromto'>{feed.to}</span>
          </p>

          <span>
            <button
              className='edit-button'
              onClick={() => handleModalGetFeedID(feed)}
            >
              <i className='fas fa-ellipsis-h'></i>
            </button>
          </span>
        </Card.Header>

        {feed.main_image ? (
          <Card.Body className='feed-post-body'>
            <img
              src={feed.main_image}
              alt={`A Gesture sent from ${feed.from} to ${feed.to}`}
              className='feed-post-main-image'
            />

            <div
              className={
                showMore ? 'message-doodle-showMore' : 'message-doodles'
              }
            >
              {feed.message === ""||!feed.message ? <></>:
              <div className='post-message text-center'>
              <i className='fas fa-quote-left' />

              <p className='m-0'>
                {feed.message?.length > maxDisplayTextLength ? (
                  showMore ? (
                    <em>
                      {feed.message?.split('\n').map((item, index) => {
                        return (
                          <>
                            <span key={index}>
                              {item}
                              <br />
                            </span>
                          </>
                        );
                      })}
                    </em>
                  ) : (
                    <>
                      <em>
                        <span>
                          {feed.message?.substring(0, maxDisplayTextLength) +
                            '...'}
                        </span>
                      </em>
                      <small
                        onClick={handleShowMore}
                        style={{
                          fontStyle: 'italic',
                          cursor: 'pointer',
                          color: '#aaa',
                        }}
                      >
                        {' '}
                        Show more
                      </small>
                    </>
                  )
                ) : (
                  <em>
                    {feed.message?.split('\n').map((item, index) => {
                      return (
                        <>
                          <span key={index}>
                            {item}
                            <br />
                          </span>
                        </>
                      );
                    })}
                  </em>
                )}
              </p>

              <i className='fas fa-quote-right' />
            </div>}
              <div className='post-sub-image'>
                <img
                  src={feed.sub_image}
                  alt={'A doodle created by ' + feed.from}
                  className='feed-post-sub-image'
                />
              </div>
            </div>
          </Card.Body>
        ) : (
          <Card.Body className='feed-post-body'>
            <div
              className={
                showMore ? 'message-doodle-showMore' : 'message-doodles'
              }
            >
              {feed.message === ""||!feed.message ? <></>:
              <div className='post-message text-center'>
                <i className='fas fa-quote-left' />
                <p className='m-0'>
                  {feed.message.length > maxDisplayTextLength ? (
                    showMore ? (
                      <em>
                        {feed.message.split('\n').map((item, index) => {
                          return (
                            <>
                              <span key={index}>
                                {item}
                                <br />
                              </span>
                            </>
                          );
                        })}
                      </em>
                    ) : (
                      <>
                        <em>
                          <span>
                            {feed.message.substring(0, maxDisplayTextLength) +
                              '...'}
                          </span>
                        </em>
                        <small
                          onClick={handleShowMore}
                          style={{
                            fontStyle: 'italic',
                            cursor: 'pointer',
                            color: '#aaa',
                          }}
                        >
                          {' '}
                          Show more
                        </small>
                      </>
                    )
                  ) : (
                    <em>
                      {feed.message.split('\n').map((item, index) => {
                        return (
                          <>
                            <span key={index}>
                              {item}
                              <br />
                            </span>
                          </>
                        );
                      })}
                    </em>
                  )}
                </p>
                <i className='fas fa-quote-right' />
              </div>}

              <div className='post-sub-image'>
                <img
                  src={feed.sub_image}
                  alt={'A doodle created by ' + feed.from}
                  className='feed-post-sub-image'
                />
              </div>
            </div>
          </Card.Body>
        )}

        <Card.Footer className='feed-post-footer'>
          <div className='post-timestamp'>
            <small>{timeSince(feed.creationTime)}</small>{' '}
          </div>

          <div className='post-chatroom-btn'>
            <Link
              to={`/feed/${feed.id}/messages?tokenId=${tokenid}&appbar=${appbar}`}
              className='post-chatroom-button'
              onClick={handleSaveScrollPosition}
            >
              <i className='fas fa-comments'></i>
            </Link>
          </div>
        </Card.Footer>
      </Card>
    </>
  );
};

export default Feed;
