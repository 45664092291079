import React from 'react';
import styles from './VendorOrder.module.css'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
const VendorOrder =({order, handleOnClickOrderDetails})=>{
    return(
        <>
            <div  onClick={()=>handleOnClickOrderDetails(order)} className={styles["Vendor-order-container"]}>
                <div className={styles["Vendor-order"]} style={{display:"flex",flexDirection:"column",flex:"1.5",textAlign:"center"}}>
                    <span>{order?.recipient_name}</span>
                    <span>{order?.recipient_phone}</span>
                </div>
                <div className={styles["Vendor-order"]} style={{display:"flex",flexDirection:"column",flex:"2.5",textAlign:"center"}}>
                    
                    <span>#{order?.id?.substr(-6)}</span>
                    <span>${order?.total_charge}</span>
                    <span>{new Date(order.timestamp).toLocaleDateString("en-US")+" "+new Date(order.timestamp).toLocaleTimeString("en-US")}</span>
                </div>
                <div style={{flexDirection:"column",flex:"1.3",display:"flex",textAlign:"center"}}>
                    <span>{order?.product_name}</span>
                </div>
                <div  style={{flexDirection:"column",flex:"3.5",display:"flex",textAlign:"center"}}>
                    <span>{order?.dropoff}<br/>{order?.apt?order.apt:"N/A"}</span>
                </div>
                <div  style={{flexDirection:"column",flex:"1",display:"flex",textAlign:"center"}}>
                    <span>{order?.status === 0?"Pending":order?.status === 1?"Completed":order?.status === 2?"Canceled":""}</span>
                    </div>
                <div  style={{flexDirection:"column",flex:"0.5",display:"flex",textAlign:"center"}}>
                <ArrowForwardIosIcon/>
                </div>
            </div>
        </>
    )
}
export default VendorOrder;