import { mainAPIUrl } from '../../../settings';
import axios from 'axios';


const URL = `${mainAPIUrl}/gservices_3`;

export function GETVendorDataByOrderID(orderId) {
  const url = `${URL}/vendors/orders/${orderId}`;
  const data = fetch(url)
    .then((response) => {
      if (response.ok) {

        return response.json();
      }
    })
    .then((res) => {

      return res
    })

  return data;
}

export function GETConsumerDataByOrderID(orderId) {
  const url = `${URL}/tracker/orders/${orderId}`;
  const data = fetch(url)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => { return res })
  return data;
}
export async function notifyReceipiantOfSmartSendOrder(orderId,tokenId) {
  const url = `${URL}/ss/smartsend/notify/${orderId}/${tokenId}`
  try{
    const res = await axios.post(url);
    console.log(res)
    return res;
  }catch(err){
    return null
  }
}