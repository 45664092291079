// import { prodLink, devLink } from '../../../APIManager_'
import { mainAPIUrl } from '../../../settings';

const baseURL = `${mainAPIUrl}/gservices_3`;


export function GETVendorDataByOrderID(orderId) {
  const url = `${baseURL}/vendors/orders/${orderId}`;
  //const url="https://us-central1-yourgestureapp.cloudfunctions.net/gservices/vendors/orders/00534658-f4c0-499b-865a-f0947cbdf4cb";
  const data = fetch(url)
    .then((response) => {
      if (response.ok) {

        return response.json();
      }
    })
    .then((res) => {

      return res
    })

  return data;

}
// export  function POSTConsumersByOrderID(orderId) {
//   const url = `${URL}/orders/${orderId}/notification `;

// return url;}

export function GETConsumersDataByTokenID(token) {
  //const url= "https://us-central1-gesture-dev.cloudfunctions.net/track/user123/orders";
  const url = `${baseURL}/history/${token}/orders`;
  const data = fetch(url)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => { return res })
  return data;
}

export function GETOderReceivedDataByTokenID(token) {
  //const url= "https://us-central1-yourgestureapp.cloudfunctions.net/gservices/consumers/12312312312/orders_received";
  const url = `${baseURL}/history/${token}/orders_received`;
  const data = fetch(url)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => { return res })
  return data;
}


export function GETConsumerDataByOrderID(orderId) {
  const url = `${baseURL}/history/orders/${orderId}`;
  //const url="https://us-central1-yourgestureapp.cloudfunctions.net/gservices/consumers/orders/BIRCHA19-11220229-D4FF"
  const data = fetch(url)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => { return res })
  return data;
}

export function GETVendorsDataByTokenID(token) {
  //const url= "https://us-central1-gesture-dev.cloudfunctions.net/track/user123/orders"
  const url = `${baseURL}/history/${token}/orders`;

  const data = fetch(url)
    .then((response) => {
      if (response.ok) {

        return response.json();
      }
    })
    .then((res) => {

      return res
    })

  return data;
}

export function GETRunnersDataByRunnerID(runnerId) {
  const url = `${baseURL}/runners/${runnerId}`;
  const data = fetch(url)
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((res) => { return res })
  return data;
}


export function GETSmartSendSchedule(orderId) {
  const url = `${baseURL}/smartsend/schedule`;
  //const url="https://us-central1-yourgestureapp.cloudfunctions.net/gservices/vendors/orders/00534658-f4c0-499b-865a-f0947cbdf4cb";
  const data = fetch(url)
    .then((response) => {
      if (response.ok) {

        return response.json();
      }
    })
    .then((res) => {

      return res
    })
  console.log(data);
  return data;

}