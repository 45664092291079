import React from 'react';
import { Dropdown } from 'react-bootstrap';

import './NavbarFeed.css';

const NavbarFeed = ({
  appbar,
  fetchFeeds,
  fetchPrivateFeeds,
  tokenId,
  showPrivate,
}) => {
  let appbarCSS = 'main-header-feed';
  let spaceBelowNavbarFeedCSS = 'space-below-navbar-feed';

  if (appbar) {
    appbarCSS = 'main-header-feed-show';
    spaceBelowNavbarFeedCSS = 'space-below-navbar-feed-show';
  }

  const resetScrollAndFetchPublic = () => {
    sessionStorage.removeItem('scrollPosition');
    fetchFeeds();
  };

  const resetScrollAndFetchPrivate = () => {
    sessionStorage.removeItem('scrollPosition');
    fetchPrivateFeeds();
  };

  return (
    <>
      <header className={appbarCSS}>
        <div className='main-header-feed-content'>
          <img
            //src='https://sendagesture.com/static/media/New_Gesture_Logo.cfb01162.png'
            src='https://static.wixstatic.com/media/1e4717_b818d70ab4f349ceb2197b49f276fa52~mv2.png/v1/fill/w_57,h_57,al_c,q_85,usm_0.66_1.00_0.01/Gesture%20Logo-03.webp'
            alt='Gesture Logo'
          />

          <Dropdown>
            <Dropdown.Toggle variant='success' id='dropdown-basic-button'>
              <span className='material-icons'>filter_list</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className='dropdown-options'
                onClick={resetScrollAndFetchPrivate}
                disabled={tokenId === null || showPrivate}
                checked={showPrivate}
                style={{ color: showPrivate ? '#8585ff' : '#aaa' }}
              >
                My posts
              </Dropdown.Item>

              <Dropdown.Item
                className='dropdown-options'
                onClick={resetScrollAndFetchPublic}
                disabled={!showPrivate}
                checked={!showPrivate}
                style={{ color: !showPrivate ? '#8585ff' : '#aaa' }}
              >
                Public posts
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className={spaceBelowNavbarFeedCSS}></div>
    </>
  );
};

export default NavbarFeed;
