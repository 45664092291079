import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { handleReportSubmitAPICall } from '../../env/APImanager';
import './ReportModal.css';

const ReportModal = props => {
  const [text, setText] = useState('');

  const handleReportSubmit = async e => {
    e.preventDefault();

    await handleReportSubmitAPICall(props, text);

    props.onHide();
    window.location.reload();
  };

  return (
    <Modal
      {...props}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      animation={false}
      className='report-modal-gfeed'
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <p className='modal-title'>Report Post</p>
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={handleReportSubmit} className='report-modal-form-gfeed'>
        <Modal.Body>
          <div className='report-post-container'>
            <div className='report-post-text-area'>
              <div className='input'>
                <textarea
                  rows='10'
                  cols='30'
                  type='text'
                  id='input'
                  value={text}
                  onChange={e => setText(e.target.value)}
                  className='input-text'
                  placeholder='What seems to be the matter?'
                />
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className='report-footer-area'>
          <div className='report-post-buttons'>
            <button
              className='report-post-each-button report-post-submit'
              style={{ backgroundColor: '#8585ff' }}
            >
              Report
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ReportModal;
