import React, { useState } from "react";

import styles from "./AppBar.module.css";
import BackButton from "@mui/icons-material/ArrowBackIosNewOutlined";
import Modal from "react-bootstrap/Modal";

const AppBar = ({ appName, goBack, goNext, disable }) => {
	const [showModal, setShowModal] = useState(false);

	const handleClick = () => {
		setShowModal(true);
	};
	return (
		<div className={styles["appbar"]}>
			<div className={styles["appbar-left"]}>
				{disable ? null : (
					<BackButton
						alt="back button"
						className={styles["back-button"]}
						onClick={goBack}
					/>
				)}
			</div>
			<h1 className={styles["title"]}>{appName}</h1>
			<div className={styles["appbar-right"]}>
				{goNext && (
					<p className={styles["right-button"]} onClick={handleClick}>
						Skip
					</p>
				)}
			</div>

			<Modal
				show={showModal}
				animation={true}
				style={{ marginTop: "40vh", paddingRight: "0" }}
			>
				<Modal.Body>
					<div
						style={{
							display: "flex",
							backgroundColor: "#fff",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							padding: "10px !important",
						}}
					>
						<div
							style={{
								fontFamily: "Mulish",
								fontSize: "18px",
								fontWeight: "700",
							}}
						>
							Are you sure you want to skip?
						</div>
						<div
							style={{
								fontFamily: "Mulish",
								fontSize: "15px",
								fontWeight: "600",
								alignItems: "center",
							}}
						>
							{" "}
							You can complete your registration in the G-Runner app by clicking
							on 'Complete Registration' from the menu
						</div>
					</div>
				</Modal.Body>

				<Modal.Footer
					style={{
						display: "flex",
						backgroundColor: "#fff",
						justifyContent: "center",
					}}
				>
					<button
						className={styles.cancelBtn}
						onClick={() => setShowModal(false)}
					>
						Cancel
					</button>
					<button
						className={styles.confirmBtn}
						onClick={() => {
							setShowModal(false);
							goNext();
						}}
					>
						Confirm
					</button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

export default AppBar;
