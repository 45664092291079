import { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import styles from "./InfoWindow.module.css";
import ClipLoader from "react-spinners/ClipLoader";
import barStyles from "../TaskWindow/TaskWindow.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import HistoryIcon from "@mui/icons-material/History";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DialogWindow from "../DialogWindow/DialogWindow";
import {
	getGRunner,
	addTask,
	removeTask,
	getRunnerHistory,
} from "../../../env/APIManager";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function InfoWindow({
	runnerId,
	tokenId,
	taskFocused,
	clearFocus,
	taskId,
	setAssignedTasks,
}) {
	const [data, setData] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [tasksLoaded, setTasksLoaded] = useState(false); // True if the tasks window section is ready.
	const [identity, setIdentity] = useState("");
	const [background, setBackground] = useState("");
	const [idVerified, setIDVerified] = useState(false);
	const [bgVerified, setBGVerified] = useState(false);
	const [tasks, setTasks] = useState(null);
	const [activeDialog, setActiveDialog] = useState(null);
	const [historyView, setHistoryView] = useState(false);
	const [taskHistory, setTaskHistory] = useState(null);
	const [historyLoaded, setHistoryLoaded] = useState(false);

	const identityCheck = (data) => {
		var status;
		if (data?.grunner?.identityStatus === undefined) {
			status = "Not Completed";
		} else if (data?.grunner?.identityStatus?.gestureStatus) {
			status = "Completed and Approved";
			setIDVerified(true);
		} else {
			status = "Completed and Not Approved";
		}
		return status;
	};

	const { push } = useHistory();

	const backgroundCheck = (data) => {
		var status;
		if (data?.grunner?.backgroundCheckStatus === undefined) {
			status = "Not Completed";
		} else if (data?.grunner?.backgroundCheckStatus?.gestureStatus) {
			status = "Completed and Approved";
			setBGVerified(true);
		} else {
			status = "Completed and Not Approved";
		}
		return status;
	};

	const runnerTasks = (data) => {
		if (data?.tasks?.length > 2) {
			data?.tasks?.sort(
				(a, b) => a?.acceptDenyRequestTime - b?.acceptDenyRequestTime
			);
		}

		// assigned tasks is set here to display the opened GRunner's task recipients on the map.
		setAssignedTasks(data?.tasks);

		const availableTasks = data?.tasks?.map((item, index) => (
			<div
				key={index}
				className={[barStyles.card, styles.cardOverride].join(" ")}
			>
				<div className={styles.cancelContainer}>
					<div
						className={[barStyles.itemHeader, styles.orderContainer].join(" ")}
					>
						{item?.orderId}
					</div>
					<div className={styles.iconButton}>
						<div className={styles.buttonStyle}>
							<IconButton
								size="small"
								onClick={() => confirmRemoval(item?.id, runnerId, data)}
							>
								<CloseIcon fontSize="small" sx={{ color: "black" }} />
							</IconButton>
						</div>
					</div>
				</div>

				<div className={[barStyles.itemText, styles.itemsContainer].join(" ")}>
					<div>
						{item?.senderName}'s gift to {item?.recipientName}
					</div>
					<div>{item?.address}</div>
					{/* <div>{item.addressExtra}</div> */}
					<div>
						{item?.deliverBy
							? item.deliverBy
							: convertToReadable(item?.deliverByMS)}
					</div>
				</div>
			</div>
		));

		return availableTasks;
	};

	const runnerTaskHistory = (tasks) => {
		if (tasks?.length > 2) {
			tasks?.sort((a, b) => b.acceptDenyRequestTime - a.acceptDenyRequestTime);
		}
		const taskHistory =
			tasks?.length > 0 ? (
				tasks?.map((item, index) => (
					<div
						key={index}
						className={[barStyles.card, styles.cardOverride].join(" ")}
					>
						<div
							className={[barStyles.itemHeader, styles.orderContainer].join(
								" "
							)}
						>
							{item?.orderId}
						</div>
						<div
							className={[barStyles.itemText, styles.itemsContainer].join(" ")}
						>
							<div>
								{item?.senderName}'s gift to {item?.recipientName}
							</div>
							<div>
								Delivery Completed on {convertToReadable(item?.deliveredTime)}
							</div>
							<div>Delivery Status: {item?.coTitle}</div>
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={item?.dropOffPhoto}
							>
								<div className={styles.viewImage}>
									View Image
									<OpenInNewIcon></OpenInNewIcon>
								</div>
							</a>
						</div>
					</div>
				))
			) : (
				<div className={[barStyles.card, styles.cardOverride].join(" ")}>
					<div className={barStyles.itemText}>
						This grunner hasn't completed any tasks yet!
					</div>
				</div>
			);
		return taskHistory;
	};

	const refreshTasks = (result) => {
		if (result?.code === "SUCCESS") {
			getGRunner(runnerId, tokenId).then((response) => {
				setTasks(runnerTasks(response?.data));
				setTasksLoaded(true);
			});
		} else if (result?.code === "UNAUTHORIZED") {
			localStorage.clear();
			push({ pathname: "/logistics/login" });
			toast.error(
				"You have been logged out. This may have been caused by using more than one device or browser"
			);
		} else {
			console.log(`Something when wrong Error: ${result?.code}`);
		}
	};

	const addTaskClick = async (taskId, runnerId) => {
		setTasksLoaded(false);
		const result = await addTask(taskId, runnerId, tokenId);
		refreshTasks(result);
		clearFocus(true);
	};

	const removeTaskClick = async (taskId, runnerId) => {
		setTasksLoaded(false);
		const result = await removeTask(taskId, runnerId, tokenId);
		refreshTasks(result);
		clearFocus(true);
	};

	const confirmRemoval = (taskId, runnerId, data) => {
		setActiveDialog(
			<DialogWindow
				taskId={taskId}
				data={data}
				removeTaskClick={() => removeTaskClick(taskId, runnerId)}
				setActive={setActiveDialog}
			/>
		);
	};

	const historyClick = async () => {
		setHistoryView(!historyView);
		setHistoryLoaded(false);
	};

	const getHistory = (runnerId) => {
		historyClick();
		getRunnerHistory(runnerId, tokenId).then((response) => {
			setTaskHistory(runnerTaskHistory(response?.data?.tasks));
			setHistoryLoaded(true);
		});
	};

	const handleGrunnerCall = () => {
		getGRunner(runnerId, tokenId)
			.then((response) => {
				setData(response?.data);
				return response?.data;
			})
			.then((data) => {
				setIdentity(identityCheck(data));
				setBackground(backgroundCheck(data));
				setTasks(runnerTasks(data));
				setTasksLoaded(true);
			})
			.then(() => setLoaded(true));
	};

	function convertToReadable(ms) {
		// Some of the data coming through has been text so change into int.
		var s = `${new Date(parseInt(ms)).toLocaleDateString(`en-US`, {
			day: "numeric",
			month: "numeric",
			year: "numeric",
		})}`;
		// s+= `${new Date(parseInt(ms)).toLocaleString(`en-US`, { hour: 'numeric', minute: 'numeric', hour12: true })}`
		return s;
	}

	useEffect(() => {
		setLoaded(false);
		setIDVerified(false);
		setBGVerified(false);
		const taskInterval = setInterval(() => {
			handleGrunnerCall();
		}, 5000);

		return () => {
			clearInterval(taskInterval);
		};
	}, [runnerId]);

	return (
		<>
			{activeDialog}
			<div className={styles.sidebar}>
				{!loaded ? (
					<div className={styles.loaderContainer}>
						<ClipLoader color="#6C40FC" size={50} />
					</div>
				) : (
					<>
						{!historyView ? (
							<>
								<div className={styles.container}>
									<div className={styles.appBar}>
										<div className={styles.appBarContainer}>
											<div className={styles.appBarTitle}>G-Runner</div>
											<div className={styles.appBarIcon}>
												<div className={styles.buttonStyle}>
													<IconButton onClick={() => getHistory(runnerId)}>
														<HistoryIcon
															style={{ color: "black" }}
															fontSize="medium"
														/>
													</IconButton>
												</div>
											</div>
										</div>
									</div>
									<div className={styles.card}>
										<div className={styles.itemHeader}>
											Personal Information
										</div>
										<div className={styles.itemText}>
											<div>
												{data?.grunner?.firstName} {data?.grunner?.lastName}
											</div>
											<div>{data?.grunner?.email}</div>
											<div>{data?.grunner?.phone}</div>
										</div>
									</div>
									<div className={styles.card}>
										<div className={styles.itemHeader}>Address</div>
										<div className={styles.itemText}>
											<div>{data?.grunner?.address?.formatted}</div>
											<div>{data?.grunner?.address?.extra}</div>
										</div>
									</div>
									<div className={styles.card}>
										<div className={styles.itemHeader}>
											Identity Verification
										</div>
										<div className={styles.row}>
											<div className={styles.itemText}>{identity}</div>
											{idVerified && <CheckCircleIcon color="success" />}
										</div>
									</div>
									<div className={styles.card}>
										<div className={styles.itemHeader}>Background Check</div>
										<div className={styles.row}>
											<div className={styles.itemText}>{background}</div>
											{bgVerified && <CheckCircleIcon color="success" />}
										</div>
									</div>
									<div className={styles.divider}>
										<Divider />
									</div>
									<div
										className={styles.area}
										onClick={
											taskFocused
												? () => {
														addTaskClick(taskId, runnerId);
												  }
												: null
										}
									>
										{taskFocused ? (
											<div className={styles.tasksOverlay} />
										) : null}
										<div className={styles.taskWindow}>
											<div className={styles.taskHeader}>Tasks</div>
											<div className={styles.tasksContainer}>
												{tasksLoaded ? (
													tasks
												) : (
													<div className={styles.taskLoaderContainer}>
														<ClipLoader color="#6C40FC" size={50} />
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</>
						) : (
							<>
								<div className={styles.historyBar}>
									<div className={styles.historyBarContainer}>
										<div className={styles.historyBarIcon}>
											<div className={styles.buttonStyle}>
												<IconButton onClick={historyClick}>
													<ArrowBackIcon
														style={{ color: "black" }}
														fontSize="medium"
													/>
												</IconButton>
											</div>
										</div>
										<div className={styles.historyBarTitle}>History</div>
									</div>
								</div>
								<div className={styles.historyContainer}>
									<div className={styles.tasksContainer}>
										{historyLoaded ? (
											taskHistory
										) : (
											<div className={styles.historyLoaderContainer}>
												<ClipLoader color="#6C40FC" size={50} />
											</div>
										)}
									</div>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</>
	);
}
