import ClipLoader from "react-spinners/ClipLoader";
import React, { useState, useEffect } from "react";
import { getPaymentOptionsStatistics } from "../../env/API";
import styles from "./PaymentOption.module.css";

const PaymentsOption = () => {
	const [loading, setLoading] = useState(true);
	const [statistics, setStatistics] = useState({
		paymentScheduleString:
			"Please set up your payment information in the Menu Page.",
		totalEarned: "0.00",
		numberOfTasks: 0,
	});
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const tokenId = params.get("tokenId");

	const callGetPaymentOptionsInfo = async () => {
		setLoading(true);
		await getPaymentOptionsStatistics(tokenId).then((res) => {
			if (res?.data?.code === "SUCCESS") {
				setStatistics(res.data?.data?.statistics);
			}
		});
		setLoading(false);
	};
	useEffect(() => {
		setLoading(true);
		callGetPaymentOptionsInfo();
	}, []);
	const handleSendEmailNow = (event) => {
		window?.ReactNativeWebView?.postMessage(
			JSON.stringify({ command: "send email" })
		);
	};
	return (
		<>
			{loading ? (
				<div className={styles["spinner"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<>
					<div className={styles["main-container"]}>
						{/* <div className={styles["tasks-container"]}> */}
						<div className={styles["tasks-text-container"]}>
							<span className={styles["tasks-text-title"]}>
								Number of Tasks Completed
							</span>
							<span className={styles["tasks-text"]}>
								You have completed {statistics.numberOfTasks} task(s).
							</span>
						</div>
						<div className={styles["tasks-text-container"]}>
							<span className={styles["tasks-text-title"]}>Total Earned</span>
							<span className={styles["tasks-text"]}>
								You have earned ${statistics.totalEarned} as a G-Runner.
							</span>
						</div>
						<div className={styles["tasks-text-container"]}>
							<span className={styles["tasks-text-title"]}>
								Payment Schedule
							</span>
							<span className={styles["tasks-text"]}>
								{statistics.paymentScheduleString}
							</span>
						</div>
						{/* </div> */}
						<button
							className={styles["next-button"]}
							onClick={handleSendEmailNow}
						>
							Send A Support Email{" "}
						</button>
					</div>
				</>
			)}
		</>
	);
};
export default PaymentsOption;
