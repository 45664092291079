import axios from 'axios';
import { mainAPIUrl } from '../../../settings';

const baseURL = `${mainAPIUrl}/social`;


const clearCacheAndRefresh = () => {
  const scrollPosition = sessionStorage.getItem('scrollPosition');
  const feedStorage = sessionStorage.getItem('feedStorage');

  if (scrollPosition) sessionStorage.removeItem('scrollPosition');
  if (feedStorage) sessionStorage.removeItem('feedStorage');

  return;
};

// FeedScreen.js
export const fetchFeedsAPICall = async tokenId => {
  let url =
    !tokenId || tokenId === null
      ? `${baseURL}/feed`
      : `${baseURL}/feed?tokenId=${tokenId}`;

  const res = await axios.get(url);
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();
  const currentFeeds = res.data.data;

  return currentFeeds;
};

export const fetchPrivateFeedsAPICall = async tokenId => {
  let url = `${baseURL}/feed/${tokenId}`;

  const res = await axios.get(url);
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();
  const currentFeeds = res.data.data;

  return currentFeeds;
};

export const fetchOlderFeedsAPICall = async (paginateKey, tokenId) => {
  let url =
    !tokenId || tokenId === null
      ? `${baseURL}/feed?paginateKey=${paginateKey}`
      : `${baseURL}/feed?tokenId=${tokenId}&paginateKey=${paginateKey}`;

  const res = await axios.get(url);
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();

  const olderFeeds = res.data.data.sort((a, b) => {
    return b.creationTime - a.creationTime;
  });

  return olderFeeds;
};

export const getUserIdAndNameAPICall = async tokenId => {
  const userRes = await axios.get(`${baseURL}/tokens/${tokenId}`);
  if (userRes.data.code !== 'SUCCESS') clearCacheAndRefresh();

  if (!userRes) return;

  return userRes;
};

// Chatroom.js
export const getMessagesAPICall = async (feedID, paginateKey) => {
  const res = await axios.get(
    `${baseURL}/content/${feedID}/messages?paginateKey=${paginateKey}`
  );
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();

  const messages = res.data.data.messages.sort((a, b) => {
    return a.sorter - b.sorter;
  });

  const data = [...messages];
  console.log('returning messages');

  return data;
};

export const fetchOlderMessagesAPICall = async (feedID, paginateKey) => {
  const res = await axios.get(
    `${baseURL}/content/${feedID}/messages?paginateKey=${paginateKey}`
  );
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();

  const olderMessages = res.data.data.messages.sort((a, b) => {
    return a.sorter - b.sorter;
  });

  let newOlderMessages = [...olderMessages];

  for (let i = 0; i < newOlderMessages.length; i++) {
    let newKeyValuePairs = {
      profilePic: 'https://i.imgur.com/f5f4aJl.jpg',
      sticker: 'https://i.imgur.com/PWxBAx4.png',
    };
    newOlderMessages[i] = { ...newOlderMessages[i], ...newKeyValuePairs };
  }

  // return olderMessages

  return newOlderMessages;
};

export const setUserNameAPICall = async tokenId => {
  const userRes = await axios.get(`${baseURL}/tokens/${tokenId}`);
  if (userRes.data.code !== 'SUCCESS') clearCacheAndRefresh();

  if (!userRes) return;

  return userRes;
};

// ChatroomTextInput.js
export const postMessageAPICall = async (feedid, newMessage) => {
  const res = await axios.post(
    `${baseURL}/content/${feedid}/messages`,
    newMessage
  );
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();

  return res;
};

// EditFeedModal.js
export const handleFeedPrivacyAPICall = async props => {
  const res = await axios.post(
    `${baseURL}/content/${props.feedid}/privacy?tokenId=${props.tokenid}`,
    { privacy: props.ispublic }
  );
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();

  return res;
};

// ReportModal.js
export const handleReportSubmitAPICall = async (props, text) => {
  const res = await axios.post(
    `${baseURL}/content/${props.feedid}/report?tokenId=${props.tokenid}`,
    { text, uid: props.tokenid }
  );
  if (res.data.code !== 'SUCCESS') clearCacheAndRefresh();

  return res;
};
