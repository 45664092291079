import React, { useState } from "react";
import InputField from "./InputFields/InputField";
import { motion } from "framer-motion";
import ContainerTitle from "./ContainerTitle/ContainerTitle";
import AppBar from "./appbar/AppBar";
import { useHistory } from "react-router-dom";
import InputFieldAddress from "./InputFields/InputFieldAddress";
import { updateUserAddress } from "../../env/API";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import styles from "./AddressSelection.module.css";
import desktopStyling from "../DesktopStyles.module.css";

const AddressSelection = (props) => {
	const { push } = useHistory();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const tokenId = params.get("tokenId");
	const inapp = params.get("inapp") || false;
	const [address, setAddress] = useState({
		Address: "",
		selectedAddress: {
			address1: "",
			address2: "",
			locality: "",
			state: "",
			country: "",
			postcode: "",
			lat: "",
			lng: "",
		},
	});
	const [address2, setAddress2] = useState("");
	const [city, setCity] = useState("");
	const [state, setState] = useState("");
	const [country, setCountry] = useState("");
	const [zipCode, setZipCode] = useState("");
	const [lng, setLng] = useState("");
	const [lat, setLat] = useState("");
	const [loading, setLoading] = useState(false);

	const [addressAPIActive, setAddressAPIActive] = useState(true);

	const handleOnClick = () => {
		setLoading(true);
		setAddressAPIActive(!addressAPIActive);
		setLoading(false);
	};
	const handleSubmitAddress = async () => {
		setLoading(true);
		const res = await updateUserAddress(tokenId, {
			city,
			extra: address2,
			state,
			zipCode,
			country,
			formatted: address.Address,
			lat,
			lng,
		});
		if (res?.data?.code === "SUCCESS") {
			push({
				pathname: `/grunner/signup/identity_verification/start/?tokenId=${tokenId}&inapp=${inapp}`,
				// state: res.data.data.user,
			});
		} else {
			toast.error("Error! please try again later.");
			setLoading(false);
		}
	};
	const handleAddressSubmit = async (s, lonLat) => {
		setLoading(true);
		let string = "";
		if (address.selectedAddress) {
			if (
				s.address1 !== address.selectedAddress.address1 &&
				address.date !== "Please Select" &&
				address.time !== "Please Select"
			) {
				setAddress({
					updated: true,
					date: "Please Select",
					time: "Please Select",
				});
			}
		}
		if (s.address2 === "") {
			setAddress2(s.address2);
			setCity(s.locality);
			setState(s.state);
			setCountry(s.country);
			setZipCode(s.postcode);
			setAddress({
				selectedAddress: {
					address1: s.address1,
					address2: "",
					locality: s.locality,
					state: s.state,
					postcode: s.postcode,
					country: s.country,
				},
			});
			string =
				s.address1 +
				", " +
				s.locality +
				", " +
				s.state +
				" " +
				s.postcode +
				", " +
				s.country;
		} else {
			setAddress({
				selectedAddress: {
					address1: s.address1,
					address2: s.address2,
					locality: s.locality,
					state: s.state,
					postcode: s.postcode,
					country: s.country,
				},
			});
			string =
				s.address1 +
				", " +
				s.address2 +
				", " +
				s.locality +
				", " +
				s.state +
				" " +
				s.postcode +
				", " +
				s.country;
		}
		setAddress({
			Address: string,
			lonLat: lonLat,
			selectedAddress: {
				address1: s.address1,
				address2: "",
				locality: s.locality,
				state: s.state,
				postcode: s.postcode,
				country: s.country,
			},
			isLoaded: false,
		});
		if (address?.selectedAddress) {
			setAddress2(s.address2);
			setCity(s.locality);
			setState(s.state);
			setCountry(s.country);
			setZipCode(s.postcode);
			setLat(lonLat.lat);
			setLng(lonLat.lng);
		}
		setLoading(false);
	};
	const resetAddress = (address) => {
		setAddress({
			Address: "",
			selectedAddress: {
				address1: "",
				address2: "",
				locality: "",
				state: "",
				country: "",
				postcode: "",
				lat: "",
				lng: "",
			},
		});
	};
	return (
		<div className={desktopStyling.mainContainer}>
			{addressAPIActive ? (
				<AppBar
					onClickAction={() => {
						push({
							pathname: `/grunner/signup/phone_verify/?tokenId=${tokenId}&inapp=${inapp}`,
						});
					}}
					title={"Address Selection"}
				/>
			) : (
				<></>
			)}
			{loading ? (
				<div className={styles["loading"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<>
					<motion.div className={styles["main-container"]}>
						{addressAPIActive ? (
							<>
								<div>
									<ContainerTitle
										title={
											"Please Select an Address so we can send you a Gesture pay card."
										}
									/>
									<InputField
										// disabled={true}
										value={address.Address}
										setValue={resetAddress}
										field={
											address.Address ? "Address" : "Click to Select Address"
										}
										requiredField={true}
										fullWidth={true}
										width={"100%"}
										// error={errors.userLname}
										type={"alpha"}
										onClickFocus={handleOnClick}
									/>

									<InputField
										disabled={false}
										requiredField={false}
										value={address2}
										setValue={setAddress2}
										field={
											address2 ? "2nd Address" : "Apt.,Unit,Suite, or Floor"
										}
										fullWidth={true}
										width={"100%"}
										// error={errors.userLname}
										type={"alpha"}
									/>
								</div>
								{address.Address ? (
									<button
										className={styles["next-button"]}
										onClick={handleSubmitAddress}
									>
										Next
									</button>
								) : (
									<button
										disabled
										className={styles["disabled-next-button"]}
										onClick={handleSubmitAddress}
									>
										Next
									</button>
								)}
							</>
						) : (
							<>
								<InputFieldAddress
									value={address.selectedAddress}
									setValue={setAddress}
									field={"Street Address"}
									fullWidth={true}
									onAddressSelect={handleAddressSubmit}
									width={"100%"}
									// error={errors.userLname}
									toggle={handleOnClick}
									type={"alpha"}
								/>
							</>
						)}
					</motion.div>
				</>
			)}
			<ToastContainer />
		</div>
	);
};
export default AddressSelection;
