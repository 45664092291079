import React, { useState } from "react";
import styles from "./ResetPasswordStyle.module.css";
import { resetPassword } from "../../env/API";
import ClipLoader from "react-spinners/ClipLoader";
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import desktopStyling from "../DesktopStyles.module.css";
import ContainerTitle from "../Login/ContainerTitle/ContainerTitle";
import InputField from "../Login/InputFields/InputField";

export function ResetPassword() {
	const [password, setPassword] = useState("");
	const [updated, setUpdated] = useState(false);
	const [loadingWheel, setLoadingWheel] = useState(false);

	let search = window.location.search;
	let params = new URLSearchParams(search);
	const token = params.get("tokenId");
	const email = params.get("email");

	async function submit() {
		if (!(password?.length > 5)) {
			return toast.error("Password must be at least 6 characters long.");
		}
		setLoadingWheel(true);

		const result = await resetPassword({ password, token }).catch(e => e);

		setLoadingWheel(false);
		const message = result?.data?.message || result?.data?.data?.message;

		if (result?.data?.code === "SUCCESS") {
			toast.success(message || "Password updated successfully.");
			setUpdated(true);
			return;
		}

		toast.error(message || "An error occurred. Please try again.");
	};

	if (loadingWheel) {
		return <div className={styles["loading-wheel"]}><ClipLoader size={100} color="#8585ff" /></div>;
	}

	if (updated) {
		return <div className={desktopStyling.mainContainer}>
			<ToastContainer />
			<div className={styles["successful-main-container"]}>
				<div>
					<ContainerTitle title="Reset Password" />
					<h2>Password updated successfully!</h2>
					<p>You can return to the app and sign in with your new password.</p>
				</div>
			</div>
		</div >;
	}

	return <div className={desktopStyling.mainContainer}>
		<motion.div className={styles["main-container"]}>
			<div>
				<ContainerTitle title="Reset Password" />
				{email && <div className={styles["grunner-input-field"]}>
					<InputField
						value={email}
						field="Email"
						disabled={true}
						fullWidth={true}
						width="100%"
						type="email"
					/>
				</div>}
				<div className={styles["grunner-input-field"]}>
					<InputField
						value={password}
						setValue={setPassword}
						field="New Password"
						fullWidth={true}
						width="100%"
						type="password"
					/>
				</div>
			</div>
			<ToastContainer />
			<button className={styles["next-button"]} onClick={submit}>
				Update Password
			</button>
			<div className={styles["signup-signin-div"]}></div>
			<div className={styles.policyContainer}></div>
		</motion.div>
	</div>;
};

export default ResetPassword;
