import React from 'react';
import Modal from 'react-bootstrap/Modal';
import styles from './DeleteAccountModal.module.css'
const DeleteAccountModal = ({isDeleted,show,setShow,handleDeletion}) =>{
  
  
    const handleNo = () => setShow(false);
    const handleYes = () => {
      
      handleDeletion();
      
      }
return (
<Modal show={show} animation={true} style={{marginTop:"40vh"}}>
    {/* <Modal.Header  >
      <Modal.Title style={{ paddingLeft:"0px"}}>Send Notification</Modal.Title>
    </Modal.Header> */}
    {!isDeleted?<Modal.Body style={{
          display: "flex",
          backgroundColor:"#fff",
          justifyContent: "center",
        }}>Are you sure you want to delete your account?</Modal.Body>:<Modal.Body  style={{
          display: "flex",
          paddingTop: "30px",
          paddingBottom: "30px",
          backgroundColor:"#fff",
          justifyContent: "center",
        }}>Your deletion request has been submitted.</Modal.Body>}
      {isDeleted===true?<></>:
    <Modal.Footer style={{
          display: "flex",
          backgroundColor:"#fff",
          justifyContent: "center",
        }}>
      <button
            className={styles['cancel-button']} onClick={()=>handleNo()}>
        Cancel
      </button>
      <button
            className={styles['okay-button']} onClick={()=>handleYes()}>
        Confirm
      </button>
    </Modal.Footer>
      }
  </Modal>
)}
export default DeleteAccountModal;