import React, { Component } from "react";
import PropTypes from 'prop-types';
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import styles from './setDate.module.css';

function convertDateFormat (date){
  date = new Date(date);
  var month = date.getMonth() + 1; //months from 1-12
  var day = date.getDate();
  var year = date.getFullYear();
  return {year:year, month:month, day:day};
}

function startDate (offset){
  var startDate = new Date();
  var numberOfDaysToAdd = offset;
  startDate.setDate(startDate.getDate() + numberOfDaysToAdd); 
  return convertDateFormat(startDate.getTime());
}

const notify = () => toast.error('Please select a time.', {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  });

export default class setDateModal extends Component {

  state ={
    timeSelected: false,
    date: startDate(this.props.offset),
    time: "",
    showCalender: false
  }

  componentDidMount(){
    if(this.props.offset>0){
      this.setState({
        showCalender: true
      })
    }
  }

  componentDidUpdate() {
    var t=this;
    if(this.props.show===true){
      window.onclick = function(event) {
        if (event.target.id === "modal") {
          t.props.onClose();
        }
     }
    }
  }

  showCalender = () =>{
    if(document.getElementById("futureOption").style.backgroundColor==="rgb(140, 132, 253)"){
      document.getElementById("futureOption").style.color="#2B08A0";
      document.getElementById("futureOption").style.backgroundColor="white";
    }else{
      document.getElementById("futureOption").style.color="white";
      document.getElementById("futureOption").style.backgroundColor="#2B08A0";
    }
    if(document.getElementById("beforenoonOption")){
      document.getElementById("beforenoonOption").style.color="#2B08A0";
      document.getElementById("beforenoonOption").style.backgroundColor="white";
    }else{
      document.getElementById("within2hrOption").style.color="#2B08A0";
      document.getElementById("within2hrOption").style.backgroundColor="white";
    }
    this.setState(
      prevState => ({ showCalender: !prevState.showCalender,
        timeSelected: false}),
      ()=>{
      var elmnt = document.getElementById("inmodal");
      if(elmnt.scrollTop!==null ||elmnt.scrollTop!==undefined){
        elmnt.scrollTop = 150;
      }
    });
  }

  onChange = selectedDate =>{
    this.setState({
      date: selectedDate,
      timeSelected:false
    },()=>{
      if(!this.sameDay(selectedDate)){
        var option1=document.getElementById("option1");
        var option2=document.getElementById("option2");
        option1.style.color="#2B08A0";
        option1.style.backgroundColor="white";
        option2.style.color="#2B08A0";
        option2.style.backgroundColor="white";
      }else{
        if(this.before10am(selectedDate)){
          document.getElementById("beforenoonOption").style.color="#2B08A0";
          document.getElementById("beforenoonOption").style.backgroundColor="white";
        }else{
          document.getElementById("within2hrOption").style.color="#2B08A0";
          document.getElementById("within2hrOption").style.backgroundColor="white";
        }
        if(document.getElementById("futureOption")&&this.state.showCalender===true){
          document.getElementById("futureOption").style.color="white";
          document.getElementById("futureOption").style.backgroundColor="#2B08A0";
        }else{
          document.getElementById("futureOption").style.color="#2B08A0";
          document.getElementById("futureOption").style.backgroundColor="white";
        }
      }
    });
  }

  saveChange = () =>{
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    const dateNames = ["1st", "2nd","3rd","4th","5th","6th","7th","8th","9th","10th",
    "11th","12th","13th","14th","15th","16th","17th","18th","19th","20th","21st","22nd",
    "23rd","24th","25th","26th","27th","28th","29th","30th","31st"];
    if(document.getElementById("futureOption")&&this.state.showCalender===true){
      document.getElementById("futureOption").style.color="white";
      document.getElementById("futureOption").style.backgroundColor="#2B08A0";
    }
    if(this.state.timeSelected){
      var date=this.state.date.month+"/"+this.state.date.day+"/"+this.state.date.year;
      var date_text=monthNames[this.state.date.month-1]+" "+dateNames[this.state.date.day-1]
      +" "+this.state.date.year;
      this.props.setDate(date);
      this.props.setDateText(date_text);
      this.props.setTime(this.state.time);
      this.props.onClose();
    }else{
      notify();
    }
  }

  //reset all the elements when the modal is closed
  // refreshPage = async() => {
  //   var option1=document.getElementById("option1");
  //   var option2=document.getElementById("option2");
  //   option1.style.color="#8C84FD";
  //   option1.style.backgroundColor="white";
  //   option2.style.color="#8C84FD";
  //   option2.style.backgroundColor="white";
  //   await this.setState(
  //     { timeSelected: false,
  //       date: convertDateFormat(this.props.intendedDate),
  //       time: "",
  //       offset: this.props.offset,
  //       intendedDate: convertDateFormat(this.props.intendedDate),
  //     })
  // }

  selectTime = async (option,notPicked, time) =>{
    var pickedOption=document.getElementById(option);
      pickedOption.style.color="white";
      pickedOption.style.backgroundColor="#2B08A0";
      var otherOption=document.getElementById(notPicked);
      if(otherOption){
        otherOption.style.color="#2B08A0";
        otherOption.style.backgroundColor="white";
      }
      this.setState({
        time: time,
        timeSelected: true
      });
      if(option==="beforenoonOption"||option==="within2hrOption"){
        this.setState({
          showCalender: false
        });
      }
  }

  sameDay = date =>{
    var month = date.month; //months from 1-12
    var day = date.day;
    var year = date.year;
    var today= new Date();
    var selectedDate=month+"/"+day+"/"+year;
    today = (today.getMonth()+1)+'/'+today.getDate()+'/'+today.getFullYear();
    if (today === selectedDate){
      return true;
    }
    return false;
  }

  before10am = date =>{
    var now= new Date();
    var time= new Date(date.year, date.month-1, date.day, 10, 0, 0);
    if (now < time){
      return true;
    }else{
      return false;
    }
  }

  handleExit =()=>{
    this.props.onClose();
  }

  render() {
    const {
      show,
      children,
    } = this.props

    const{
      date,
    } = this.state

    return (
      <div id="modal" className={show ? styles.main_container: styles.notShow}>
        <div id="inmodal" className={styles.modal}>
          <div className={styles.header}>
            <h1 className={styles.header_text}> {children} </h1>
            <button className={styles.exit_btn} onClick={()=>{this.handleExit()}}>X</button>
          </div>
          <div className={styles.section_container}>
            <h2 className={styles.section_title}>Note</h2>
            <p className={styles.section_content}>Gesture does our best to deliver within the time frame selected but cannot guarantee this 100% of the time.</p>
            <p className={styles.section_content}>Based on your selected delivery location, here are your earliest delivery options:</p>
          </div>
          <div className={styles.section_container}>
            <h2 className={styles.section_title}>Please Select Date and Time</h2>
            {this.sameDay(date) === false ? 
              <div className={styles.timeOptions_container}>
                <button id="option1" className={styles.timeOption_btn} onClick={()=>this.selectTime("option1","option2","10am-2pm")}><p className={styles.selected_date}> {new Date(this.state.date.year,this.state.date.month-1,this.state.date.day,0,0,0).toLocaleString('en-US', {
                day: 'numeric', 
                year: 'numeric', 
                month: 'long', 
            })} </p> <p className={styles.selected_date}>10am - 2pm </p></button>
                <button id="option2" className={styles.timeOption_btn} onClick={()=>this.selectTime("option2","option1","2pm-8pm")}><p className={styles.selected_date}> {new Date(this.state.date.year,this.state.date.month-1,this.state.date.day,0,0,0).toLocaleString('en-US', {
                day: 'numeric', 
                year: 'numeric', 
                month: 'long', 
            })} </p> <p className={styles.selected_date}>2pm - 8pm</p></button>
              </div> : 
              <>
              <div className={styles.timeOptions_container}>
                {this.before10am(date) === true ? 
                <button id="beforenoonOption" className={styles.timeOption_btn} onClick={()=>{this.selectTime("beforenoonOption","futureOption","Before Noon")}}> <p className={styles.selected_date}>Today</p> <p className={styles.selected_date}>Before Noon</p></button> :
                <button id="within2hrOption" className={styles.timeOption_btn} onClick={()=>{this.selectTime("within2hrOption","futureOption","Within 2 hours")}}> <p className={styles.selected_date}>Today</p> <p className={styles.selected_date}>Within 2 hours</p></button>
                }
                <button id="futureOption" className={styles.timeOption_btn} onClick={()=>{this.showCalender()}}><p className={styles.selected_date}>Future Date</p></button>
              </div> 
              </>
            }  
          </div>
          {this.state.showCalender===true?
            <div className={styles.section_container} id="calendar">
              <Calendar
                value={date} 
                onChange={this.onChange} 
                colorPrimary="#2B08A0"
                calendarClassName={styles.calendar}
                minimumDate={startDate(this.props.offset)}
                calendarTodayClassName={styles.today}
              />
            </div>:
            <></>
          }
          <div className={styles.save_btn_container}>
            <button id="save" className={styles.save_btn} onClick={()=>this.saveChange()}>Save</button>
          </div>
        </div>
      </div>
      ) ;
    }
}

setDateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};
