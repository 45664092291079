// SaveButton.js

import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import styles from "./CreditCardInput.module.css";

function SaveButton({ savingCard, onSave }) {
	const [loading, setLoading] = useState(false);

	const handleSaveClick = (event) => {
		console.log("clikded");
		// Make sure to check if onSave is a function before calling it
		if (typeof onSave === "function") {
			setLoading(true);
			// Call the onSave function from the parent component
			onSave(event);
		}
	};

	useEffect(() => {
		if (savingCard === false) {
			setLoading(false);
		}
	}, [savingCard]);
	return (
		<div className={styles.btnContainer}>
			{savingCard || loading ? (
				<div className={styles.loading}>
					<ClipLoader size={30} color="#2b08a0" />
				</div>
			) : (
				<button
					className={styles.btn}
					type="button"
					onClick={handleSaveClick}
					// disabled={ loading}
				>
					Save
				</button>
			)}
		</div>
	);
}

export default SaveButton;
