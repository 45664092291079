import React, { useState, useEffect } from 'react';
import {
  sendVerificationEmailAPICall,
  verifyEmailCodeAPICall,
} from '../../env/APIManager';
import { AnimatePresence } from 'framer-motion';
import ClipLoader from 'react-spinners/ClipLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppBar from '../appbar/AppBar';
import ConfirmModal from './ConfirmModal';
import styles from './VerifyEmail.module.css';

const VerifyEmail = () => {
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [sendEmailError, setSendEmailError] = useState(false);
  const [email, setEmail] = useState('');
  const [codeInput, setCodeInput] = useState('');
  const [resendClicked, setResendClicked] = useState(false);
  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [verifyData, setVerifyData] = useState(null);

  let search = window.location.search;
  let params = new URLSearchParams(search);
  const token = params.get('token');
  const skuId = params.get('skuId');

  const errorToastOptions = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  useEffect(() => {
    sendVerificationEmail();
  }, []);

  const sendVerificationEmail = async () => {
    setSendEmailLoading(true);
    const sendEmailRes = await sendVerificationEmailAPICall(token);
    if (sendEmailRes.code !== 'SUCCESS') {
      setSendEmailError(true);
    } else {
      setEmail(sendEmailRes.email);
    }
    setSendEmailLoading(false);
  };

  const handleCodeInputChange = (e) => {
    if (!isNaN(e.target.value)) {
      setCodeInput(e.target.value.trim());
    }
  };

  const handleVerifyButtonClick = async () => {
    if (codeInput.length !== 6) {
      toast.error('Code must be 6 digits long.', errorToastOptions);
    } else {
      setVerifyCodeLoading(true);
      const verifyCodeRes = await verifyEmailCodeAPICall(
        token,
        codeInput,
        skuId
      );
      if (!verifyCodeRes) {
        toast.error(
          'There was a problem verifying your email. Please go back and try again.',
          errorToastOptions
        );
      } else if (verifyCodeRes.code !== 'SUCCESS') {
        toast.error(verifyCodeRes.message, errorToastOptions);
      } else {
        setVerifyData(verifyCodeRes.data);
        setConfirmModalOpen(true);
      }
      setVerifyCodeLoading(false);
    }
  };

  const handleResendButtonClick = async () => {
    setResendClicked(true);
    sendVerificationEmail();
  };

  return (
    <section>
      {verifyCodeLoading && (
        <div className={styles['verify-code-loading']}>
          <ClipLoader size={100} color='#8585ff' />
        </div>
      )}
      {sendEmailLoading ? (
        <div className={styles['send-email-loading']}>
          <ClipLoader size={100} color='#8585ff' />
        </div>
      ) : (
        <>
          <ToastContainer />
          <AnimatePresence
            initial={false}
            exitBeforeEnter={true}
            onExitComplete={() => {
              window.scrollTo(0, 0);
              document.body.style.overflow = 'unset';
            }}
          >
            {confirmModalOpen && <ConfirmModal verifyData={verifyData} />}
          </AnimatePresence>
          <AppBar />
          <div className={styles['verify-email-container']}>
            {sendEmailError ? (
              <p className={styles['verify-text']}>
                There was a problem sending the verification email. Please go
                back and try again.
              </p>
            ) : (
              <>
                <p className={styles['verify-text']}>
                  We've sent a 6 digit verification code to{' '}
                  <strong>{email}</strong>
                </p>
                <input
                  className={styles['code-input']}
                  type='text'
                  inputMode='numeric'
                  maxLength={6}
                  value={codeInput}
                  onChange={handleCodeInputChange}
                />
                <button
                  className={styles['verify-button']}
                  onClick={handleVerifyButtonClick}
                >
                  Verify
                </button>
                {resendClicked ? (
                  <p className={styles['code-sent-text']}>Code Sent</p>
                ) : (
                  <button
                    className={styles['resend-button']}
                    onClick={handleResendButtonClick}
                  >
                    Resend Code
                  </button>
                )}
              </>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default VerifyEmail;
