import React from "react";
import "./NavBar.Styles.css";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

import back from "../../assets/back.png";

import styles from "./NavBar.module.css";

let search, params, tokenId, view, orderId;

class NavBar extends React.Component {

	WhereTo = (renderview) => {
		if (renderview === "recipient") {
			return "history";
		} else if (renderview === "consumer") {
			return "history";
		}
	};

	componentDidMount() {
		search = window.location.search;
		params = new URLSearchParams(search);
		tokenId = params.get("tokenId");
		view = params.get("view");
		orderId = params.get("orderId");
	}

	handleChat = () => {
		this.props.history.push(
			`/webchat?orderId=${orderId}&tokenId=${tokenId}&appbar=true&view=consumer`
		);
	};
	render() {
		return (
			<>
				<div className={styles.heading}>
					<div className={styles.contentContainer}>
						<div className={styles.headingimg}>
							<Link
								to={`/${this.WhereTo(
									view
								)}?tokenId=${tokenId}&appbar=${false}&view=${view}&refreshbar=true`}
								style={{ textDecoration: "none", color: "#8585ff" }}
							>
								<img
									alt="back arrow"
									src={back}
									className={styles.back_btn}
								></img>{" "}
							</Link>
						</div>
						{view === "consumer" ? (
							<div>
								<span  className={styles.headerText} onClick={this.handleChat}>Help</span>
							</div>
						) : null}

						{/* <span className={styles.headerText}>Help</span> */}
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(NavBar);
