import React from "react";
import styles from "./AppBar.module.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const AppBar = (props) => {
	return (
		<div className={styles.mainContainer}>
			<div className={styles["appbar-left"]}>
				<ArrowBackIosIcon
					className={styles["close-button"]}
					fontSize="medium"
					onClick={() => props.onClickAction()}
				/>
			</div>
			<h1 className={styles.pageTitle}>{props.title}</h1>
			<div className={styles.rightNav}></div>
		</div>
	);
};

export default AppBar;
