import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./InputNumber.module.css";

const InputNumber = (props) => {
  
  const handleChange = (event) => {
    if(event.target.value.length <= 10  ) {
      props.setValue(
        event.target.value.replaceAll(/\s/g, "").replaceAll(/[{()}[\]]/g, "").replaceAll(/\D/g, "")
      );
    }
    props.validate();
  };
  const handleClear = (event) => {
    props.setValue("");
    props.validate();
  };

  return (
    <>
      <div className={styles["field"]}>
        <TextField
          required
          id="standard-text"
          label={`Phone`}
          value={props.value}
          onChange={handleChange}
          variant="standard"
          fullWidth={true}
          type={"phonehumber"}
          error={props?.error ? true : false}
          helperText={props?.error}
          // onKeyDown={(event) => {
          // if (!/^[0-9]+$/.test(event.key)) {
          //   event.preventDefault();
          //   }
          //   // if(props?.value?.length===0){
          //   //   props.setValue("(")
          //   // }
          //   // if(props.value?.length===4){
          //   //   props.setValue(props.value+") ")
          //   // }
          //   // if(props.value?.length===9){
          //   //   props.setValue(props.value+" ")
          //   // }
          //   if (props?.value?.length >= 10) {
          //     if (event.key === "Backspace") {
          //       props.setValue(props.value.slice(0, -1));
          //     } else {
          //       event.preventDefault();
          //     }
          //   } else {
          //     if (event.key === "Backspace") {
          //       props.setValue(props.value.slice(0, -1));
          //     }
          //   }
          // }}
          inputProps={{ inputMode: 'numeric' }}
          InputProps={{
            style: { width: props?.width },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear text"
                  onClick={handleClear}
                  edge="end"
                >
                  {" "}
                  {props.value ? (
                    <CancelIcon />
                  ) : (
                    <CancelIcon color="disabled" sx={{ opacity: 0 }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};
export default InputNumber;
