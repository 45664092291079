import axios from 'axios';
import { emptyBase64Image } from '../components/utils';
import defaultSelfie from '../assets/default-selfie.png';

import { mainAPIUrl } from '../../../settings';

const baseURL = `${mainAPIUrl}/gservices_3`;


export const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export const fetchEditMessageAPICall = async (tokenId) => {
  const url = `${baseURL}/messages/${tokenId}`;

  const res = await axios.get(url);
  const currentMessage = res.data.data;

  if (currentMessage) {
    currentMessage.selfie = currentMessage.selfie
      ? await getBase64FromUrl(currentMessage.selfie)
      : defaultSelfie;
    currentMessage.doodle = currentMessage.doodle
      ? await getBase64FromUrl(currentMessage.doodle)
      : emptyBase64Image;
  }

  return currentMessage;
};

export const createMessageAPICall = async (messageData, occasionId) => {
  messageData.selfieBase64 =
    messageData.selfieBase64 === defaultSelfie
      ? null
      : messageData.selfieBase64.split(',')[1];
  messageData.drawingBase64 =
    messageData.drawingBase64 === emptyBase64Image
      ? null
      : messageData.drawingBase64.split(',')[1];
  if (!occasionId || occasionId === 'null' || occasionId === 'undefined') {
    try {
      const res = await axios.post(`${baseURL}/messages`, messageData);
      return res.data.data.messageId;
    } catch (err) {
      return null;
    }
  } else {
    try {
      const res = await axios.post(
        `${baseURL}/messages?occasionId=${occasionId}`,
        messageData
      );
      return res.data.data.messageId;
    } catch (err) {
      return null;
    }
  }
};

export const fetchOccasionMessagesAPICall = async (occasionId) => {
  const url = `${baseURL}/occasions/messages/${occasionId}`;

  try {
    const res = await axios.get(url);
    return res.data.messages;
  } catch (err) {
    return null;
  }
};

export const searchImageAPICall = async (query) => {
  const url = `${baseURL}/messages/selfie/images?query=${query}`;
  try {
    const res = await axios.get(url);
    return res.data;
  } catch (err) {
    return null;
  }
};

export const getPostCardDesigns = async (tokenId) => {

  let url = `${baseURL}/messages/designs`

  if (!tokenId || tokenId === 'null') {
    url += '/null';
  }
  else if (tokenId === 'undefined') {
    url += '/undefined';
  }
  else {
    url += `/${tokenId}`;
  }

  let availableDesigns = null;

  try {
    const res = await axios.get(url);
    availableDesigns = {
      postCardDesignSections: res.data.data.postCardDesignSections,
      defaultDesign: res.data.data.defaultDesign
    }
  }
  catch (err) {
    //return null;
  }

  if (!availableDesigns || !availableDesigns.postCardDesignSections) {
    return null;
  }

  return availableDesigns;
}

export const POSTTrackDownload = async (download_location) => {
  const url = `${baseURL}/messages/selfie/images/trackDownload`;
  const data = await fetch(url, {
    method: "POST",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(download_location)
  });
  const json = await data.json();
  return json;
}