import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

import { getBusinessUserDetails } from "../../env/APIManager";
import styles from "./BusinessUserDetails.module.css";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharp from "@mui/icons-material/ArrowDropUpSharp";

import { ToastContainer, toast } from "react-toastify";

import BusinessUserOrders from "./BusinessUserOrders";
import CRMNote from "../crm-notes/crmNote"


const ExpandableDiv = ({ title, children }) => {
	const [isExpanded, setIsExpanded] = React.useState(false);

	const handleToggle = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const showStyle = {
		height: "auto",
	};
	const hideStyle = {
		paddingTop: 4,
		paddingBottom: 4,
	};

	return (
		<div
			style={isExpanded ? showStyle : hideStyle}
			className={styles.collapseSection}
			key={title}
		>
			<div onClick={handleToggle} className={styles.mainTitleContainer}>
				{isExpanded ? <ArrowDropUpSharp /> : <ArrowDropDownSharpIcon />}
				<div className={styles.mainTitle}>{title}</div>
			</div>

			{isExpanded && <div className={styles.mainBodyContainer}>{children}</div>}
		</div>
	);
};
function BusinessUserDetails() {
	const [loading, setLoading] = useState(false);
	const [userDetails, setUserDetails] = useState({});

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const id = params.get("id");
	const key = params.get("key");
	const nextPageToken = params.get("nextPageToken");
	let history = useHistory();

	const handleGoBack = () => {
		if (nextPageToken && key) {
			history.push(`/logistics/crm?key=${key}&nextPageToken=${nextPageToken}`);
		} else if (key && key !== undefined && key !== null) {
			history.push(`/logistics/crm?key=${key}`);
		} else {
			history.push(`/logistics/crm`);
		}
	};

	async function getUser(token, id) {
		setLoading(true);

		let data = await getBusinessUserDetails(token, id);

		if (data?.code === "SUCCESS") {
			console.log(data);
			setUserDetails(data?.data);
			setLoading(false);
		} else if (data?.code === "UNAUTHORIZED") {
			localStorage.clear();
			setLoading(false);
			history.push({ pathname: "/logistics/login" });
			toast.error(
				"You have been logged out. This may have been caused by using more than one device or browser"
			);
		} else {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
		}
	}

	useEffect(() => {
		let token =
			localStorage.getItem("token") == null ||
			localStorage.getItem("token") === undefined ||
			localStorage.getItem("token") === ""
				? ""
				: JSON.parse(localStorage.getItem("token"));

		if (!id) {
			if (nextPageToken && key) {
				history.push(
					`/logistics/crm?key=${key}&nextPageToken=${nextPageToken}`
				);
			} else {
				history.push(`/logistics/crm?key=${key}`);
			}
			return;
		}

		getUser(token, id);
	}, []);

	return (
		<>
			<ToastContainer />
			<div className={styles.mainContainer}>
				{loading ? (
					<div className={styles.clipLoader}>
						<ClipLoader color="#000" size={50} />
					</div>
				) : userDetails && Object.keys(userDetails)?.length > 0 ? (
					<>
						<div className={styles.headerContainer}>
							<ArrowBackIcon
								fontSize="large"
								style={{ marginLeft: "-5px", cursor: "pointer" }}
								onClick={handleGoBack}
							/>
							<div className={styles.titleContainer}>
								<div className={styles.pageTitle}>
									User #{id?.substring(id?.length - 5)}{" "}
								</div>
							</div>
						</div>

						{/* user information section */}
						<ExpandableDiv title="User Information">
							<div className={styles.container}>
								<div className={styles.contentContainer}>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>Created</div>
										<div>
											{userDetails?.createdAt
												? new Date(userDetails?.createdAt).toLocaleDateString(
														"en-US"
												  ) +
												  " " +
												  new Date(userDetails?.createdAt).toLocaleTimeString(
														[],
														{
															hour: "2-digit",
															minute: "2-digit",
														}
												  )
												: "N/A"}
										</div>
									</div>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>Company</div>
										<div>
											{userDetails?.company ? userDetails?.company : "N/A"}
										</div>
									</div>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>Company Size</div>
										<div>
											{userDetails?.size_of_company
												? userDetails?.size_of_company
												: "N/A"}
										</div>
									</div>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>First Name</div>
										<div>{userDetails?.first ? userDetails?.first : "N/A"}</div>
									</div>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>Last Name</div>
										<div>{userDetails?.last ? userDetails?.last : "N/A"}</div>
									</div>

									<div className={styles.bodyInfoContainer}>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Phone Number</div>
											<div>
												{userDetails?.phoneNumber
													? userDetails?.phoneNumber
													: "N/A"}
											</div>
										</div>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Email</div>
											<div>
												{userDetails?.email ? userDetails?.email : "N/A"}
											</div>
										</div>
									</div>
								</div>
								<div className={styles.contentContainer}>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>Plan Id</div>
										<div>
											{userDetails?.planId ? userDetails?.planId : "N/A"}
										</div>
									</div>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>Plan Name</div>
										<div>
											{userDetails?.planName ? userDetails?.planName : "N/A"}
										</div>
									</div>

									<div className={styles.bodyInfoContainer}>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Credit</div>
											<div>
												{userDetails?.credit ? userDetails?.credit : "N/A"}
											</div>
										</div>

										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>CC on file</div>
											<div>
												{userDetails?.last4 ? userDetails?.last4 : "N/A"}
											</div>
										</div>
									</div>
								</div>
							</div>
						</ExpandableDiv>
						<CRMNote id={id} />
						<BusinessUserOrders id={id} />
					</>
				) : (
					<div className={styles.headerContainer}>
						<ArrowBackIcon
							fontSize="large"
							style={{ marginLeft: "-5px", cursor: "pointer" }}
							onClick={handleGoBack}
						/>
						<div className={styles.titleContainer}>
							<div className={styles.pageTitle}>
								No information is available
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default BusinessUserDetails;
