import React, { useState } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/CloseRounded';

import { slideUp } from '../utils';
import styles from './FromEditCard.module.css';

const FromEditCard = ({ handleClose, initialValue, onValueChange }) => {
  const [from, setFrom] = useState(initialValue);
  const [emptyFrom, setEmptyFrom] = useState(false);

  const handleChange = (e) => {
    const newFrom = e.target.value;
    setFrom(newFrom);
    if (newFrom.trim()) {
      setEmptyFrom(false);
    } else {
      setEmptyFrom(true);
    }
  };

  const handleSubmit = () => {
    const trimmed = from.trim();
    if (trimmed) {
      onValueChange(trimmed);
    } else {
      onValueChange('');
    }
    handleClose();
  };

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className={styles['edit-card']}
      variants={slideUp}
      initial='hidden'
      animate='visible'
      exit='exit'
      onAnimationStart={(definition) => {
        if (definition === 'visible') {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        }
      }}
    >
      <CloseIcon
        className={styles['close-button']}
        fontSize='large'
        onClick={handleClose}
      />
      <div className={styles['content-container']}>
        <h1 className={styles['header-text']}>Edit Sender's Name</h1>
        <input
          className={styles['input-field']}
          type='text'
          value={from}
          onChange={handleChange}
          maxLength='50'
          placeholder="Type sender's name..."
        />
        {emptyFrom && (
          <p className={styles['required-warning']}>Sender name is required</p>
        )}
      </div>
      <button
        className={styles['save-button']}
        onClick={handleSubmit}
        disabled={emptyFrom}
      >
        Save
      </button>
    </motion.div>
  );
};

export default FromEditCard;
