import React from "react";
import styles from "./AppBar.module.css";
import refreshIcon from "../../assets/refresh-60.png";

function AppBar(props) {
	return (
		<div className={styles.mainContainer}>
			<div className={styles.contentContainer}>
				<h3 className={styles.mainTitle}>Order History</h3>{" "}
				<img
					alt="refresh"
					onClick={function () {
						window?.ReactNativeWebView?.postMessage("refresh");
						props.handleUpdate();
					}}
					className={styles.refreshIcon}
					src={refreshIcon}
				/>
			</div>
		</div>
	);
}

export default AppBar;
