import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { GETUserToken } from "../env/APIManager";
import styles from "./WebChat.module.css";

import ClipLoader from "react-spinners/ClipLoader";

let search, params, tokenId, view, orderId;

function WebChat() {
	search = window.location.search;
	params = new URLSearchParams(search);
	orderId = params.get("orderId");
	tokenId = params.get("tokenId");
	view = params.get("view");
	const history = useHistory();

	const [loading, setLoading] = useState(false);

	const [message, setMessage] = useState("");
	const [error, setError] = useState(false);

	const widgetToggleEventHandler = function (data) {
		if (!data?.visible) {
			history.push(
				`/track?orderId=${orderId}&tokenId=${tokenId}&appbar=${true}&view=${view}`
			);
		}
	};
	window.Helpshift(
		"addEventListener",
		"widgetToggle",
		widgetToggleEventHandler
	);

	useEffect(() => {
		setLoading(true);
		GETUserToken(tokenId).then((res) => {
			if (res?.code === "SUCCESS") {
				let authToken = res?.data?.hmac;
				let userId = res?.data?.id;
				let name = res?.data?.fullName;
				let email = res?.data?.email;
				console.log(email)

				setLoading(false);
				setError(false);
				window["openChat"]();
				window["sendOrderId"](orderId);
				window["updateUser"](authToken, userId, name, email);
			} else if (res?.code === "ERROR") {
				setLoading(false);
				setError(true);
				setMessage(res?.data);
				setTimeout(() => {
					console.log(tokenId);
					history.push(
						`/track?orderId=${orderId}&tokenId=${tokenId}&appbar=${true}&view=${view}`
					);
				}, 1000);
			}
		});
	}, []);

	console.log(loading);

	return (
		<>
			{loading && (
				<div className={styles.loaderContainer}>
					<ClipLoader size={50} color="#6C40FC" />
				</div>
			)}
			{!loading && error && message?.trim()?.length > 0 && (
				<div className={styles.loaderContainer}>
					<span>{message} </span>
				</div>
			)}
		</>
	);
}

export default WebChat;
