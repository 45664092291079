import React, { useState } from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/CloseRounded';

import { slideUp } from '../utils';
import styles from './MessageEditCard.module.css';

const MessageEditCard = ({ handleClose, initialValue, onValueChange }) => {
  const [message, setMessage] = useState(initialValue ?? '');

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = () => {
    const trimmed = message.trim();
    if (trimmed) {
      onValueChange(trimmed);
    } else {
      onValueChange('');
    }
    handleClose();
  };

  const handleFocus = () => {
    document.body.classList.add(styles['fix-safari-position']);
  };

  const handleBlur = () => {
    document.body.classList.remove(styles['fix-safari-position']);
  };

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className={styles['edit-card']}
      variants={slideUp}
      initial='hidden'
      animate='visible'
      exit='exit'
      onAnimationStart={(definition) => {
        if (definition === 'visible') {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        }
      }}
    >
      <CloseIcon
        className={styles['close-button']}
        fontSize='large'
        onClick={handleClose}
      />
      <div className={styles['content-container']}>
        <h1 className={styles['header-text']}>Your Message</h1>
        <textarea
          className={styles['input-field']}
          value={message}
          onChange={handleChange}
          placeholder='Type your message...'
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
      <button className={styles['save-button']} onClick={handleSubmit}>
        Save
      </button>
    </motion.div>
  );
};

export default MessageEditCard;
