import React from 'react';
import { motion } from 'framer-motion';
import CloseIcon from '@mui/icons-material/CloseRounded';

import { slideUp } from '../utils';
import styles from './DesignEditCard.module.css';

const DesignEditCard = ({ handleClose, initialValue, onValueChange }) => {

  const handleClick = (selectedDesign) => {
    onValueChange(selectedDesign);
    handleClose();
  }

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className={styles['edit-card']}
      variants={slideUp}
      initial='hidden'
      animate='visible'
      exit='exit'
      onAnimationStart={(definition) => {
        if (definition === 'visible') {
          window.scrollTo(0, 0);
          document.body.style.overflow = 'hidden';
        }
      }}
    >
      <div className={styles['design-edit-card-header']}>
        <CloseIcon
          style={{
            left: 20,
            position: 'absolute',
          }}
          fontSize='large'
          onClick={handleClose}
        />
        <h1 style={{
          fontSize: '1.4em'
        }}>Edit Design</h1>

      </div>

      <div className={styles['content-container']}>
        <ul style={{padding:0}}>
          {initialValue.map(section => <li className={styles['section-selector']} key={section.header}>
            <span className={styles["section-header"]}>{section.header}</span>
            <ul  style={{padding:0}}>
              {section.designs.map(design => <li className={styles["design-section"]} key={design.id}>
                <span className={styles["design-title"]}>{design.title}</span>
                <div className={styles['image-container']}>
                  <img
                    className={styles['preview-image']}
                    src={design.previewImage}
                    alt='design preview'
                    onClick={() => handleClick(design)}
                  />
                </div>
              </li>)}
            </ul>
          </li>)}
        </ul>
      </div>
    </motion.div>
  );
};

export default DesignEditCard;
