import axios from 'axios';
import { mainAPIUrl } from '../../../settings';

const baseURL = `${mainAPIUrl}/gservices_3`;


export const sendVerificationEmailAPICall = async (tokenId) => {
  const url = `${baseURL}/verifyEmail/sendVerificationEmail/${tokenId}`;
  try {
    const res = await axios.post(url);
    if (res.data.code !== 'SUCCESS') {
      return { code: 'ERR' };
    } else {
      return { code: 'SUCCESS', email: res.data.data.email };
    }
  } catch (err) {
    return { code: 'ERR' };
  }
};

export const verifyEmailCodeAPICall = async (tokenId, code, skuId) => {
  const url = `${baseURL}/verifyEmail/verifyEmailCode/${tokenId}`;
  try {
    const res = await axios.post(url, { code, skuId });
    return res.data;
  } catch (err) {
    if (err.response) {
      return err.response.data.error;
    } else {
      return null;
    }
  }
};
