import { useState } from "react";
import { ClipLoader } from "react-spinners";
import { deleteAccount } from "../../env/API";
import styles from "./DeleteAccount.module.css";
import DeleteAccountModal from "./DeleteAccountModal/DeleteAccountModal";
const DeleteAccount = () => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	const tokenId = params.get("tokenId");
	const [showModal, setShowModal] = useState(false);
	const [isDeleted, setIsDeleted] = useState(false);
	const [loading, setLoading] = useState(false);
	const today = new Date();
	let yyyy = today.getFullYear();
	let mm = 0;
	if (today.getMonth() <= 10) mm = today.getMonth() + 2; // Months start at 0!
	else {
		yyyy++;
		mm = 1;
	}
	let dd = today.getDate();

	if (dd < 10) dd = "0" + dd;
	if (mm < 10) mm = "0" + mm;

	const formattedToday = mm + "/" + dd + "/" + yyyy;
	const handleOnClick = async () => {
		setShowModal(true);
	};
	const handleDeletion = async () => {
		setLoading(true);
		const res = await deleteAccount(tokenId, true);
		if (res?.data?.code === "SUCCESS") {
			setIsDeleted(true);
			window?.ReactNativeWebView?.postMessage(
				JSON.stringify({ command: "delete-account" })
			);
		}
		setLoading(false);
	};
	return (
		<>
			{" "}
			{loading ? (
				<div className={styles["loading"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<div className={styles["delete-account-container"]}>
					{showModal ? (
						<DeleteAccountModal
							isDeleted={isDeleted}
							show={showModal}
							setShow={setShowModal}
							handleDeletion={handleDeletion}
						/>
					) : (
						<></>
					)}
					<div className={styles["delete-account-text"]}>
						<span className={styles["delete-account-notice-top-text"]}>
							{" "}
							You can submit a request to delete your account with Gesture on
							this screen.
						</span>
						<br />
						<span className={styles["delete-account-notice-mid-text"]}>
							{" "}
							By submitting this request you will permanently lose the following
							information:
							<br />
							<ul>
								<li> Your account details</li>
								<li> Your order history</li>
							</ul>
						</span>
						<div className={styles["delete-account-notice-bot-text-container"]}>
							<span className={styles["delete-account-notice-bot-text"]}>
								If your choose to continue, your account will be deleted on{" "}
								{formattedToday}. If you want to keep your account, login again
								before the deletion date and your account will be re-activated.
							</span>
						</div>
						<div className={styles["delete-account-continue-div"]}>
							<button
								className={styles["delete-account-continue-div-button"]}
								onClick={handleOnClick}
							>
								<span
									className={styles["delete-account-continue-div-button-text"]}
								>
									{" "}
									Continue
								</span>
							</button>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default DeleteAccount;
