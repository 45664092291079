import React, { Component } from "react";
import PropTypes from 'prop-types';

import styles from './setDate.module.css';
import phonestyles from './verifyPhone.module.css';

export default class PModal extends Component {
    handleExit =()=>{
        this.props.onClose();
    }

    render(){
        const {
            show,
            children,
            link
          } = this.props
        return(
        <div id="pmodal" className={show ? styles.main_container: styles.notShow}>
            <div id="inmodal" className={phonestyles.modal} style={{ backgroundColor: "white", height: '100%', width: '100%'}}>
                <div className={styles.header}>
                    <h1 className={styles.header_text}> {children} </h1>
                    <button className={styles.exit_btn} onClick={()=>{this.handleExit()}}>X</button>
                </div>
                <iframe title="link" src={link} className={styles.frame} >
                </iframe>
                <div className={styles.close_btn_container}>
                    <button className={styles.close_btn} onClick={()=>this.handleExit()}>Close</button>
                </div>
            </div>
        </div>
        );
    }
}

PModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
    };