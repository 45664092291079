import React from 'react';
import { Dropdown } from 'react-bootstrap';

import styles from './Floater.module.css';

const Floater = ({
  appbar,
  fetchFeeds,
  fetchPrivateFeeds,
  tokenId,
  showPrivate,
}) => {
  //   let appbarCSS = styles['main-header-feed']
  //   let spaceBelowNavbarFeedCSS = styles['space-below-navbar-feed']

  //   if (appbar) {
  //     appbarCSS = styles['main-header-feed-show']
  //     spaceBelowNavbarFeedCSS = styles['space-below-navbar-feed-show']
  //   }

  const resetScrollAndFetchPublic = () => {
    sessionStorage.removeItem('scrollPosition');
    fetchFeeds();
  };

  const resetScrollAndFetchPrivate = () => {
    sessionStorage.removeItem('scrollPosition');
    fetchPrivateFeeds();
  };

  return (
    <>
      <header className={styles['main-header-feed-show']}>
        <div className={styles['main-header-feed-content']}>
          <Dropdown>
            <Dropdown.Toggle
              variant='success'
              id={styles['dropdown-basic-button']}
            >
              <span className='material-icons'>filter_list</span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className={styles['dropdown-options']}
                onClick={resetScrollAndFetchPrivate}
                disabled={tokenId === null || showPrivate}
                checked={showPrivate}
                style={{ color: showPrivate ? '#8585ff' : '#aaa' }}
              >
                My posts
              </Dropdown.Item>

              <Dropdown.Item
                className={styles['dropdown-options']}
                onClick={resetScrollAndFetchPublic}
                disabled={!showPrivate}
                checked={!showPrivate}
                style={{ color: !showPrivate ? '#8585ff' : '#aaa' }}
              >
                Public posts
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </header>

      <div className={styles['space-below-navbar-feed-show']}></div>
    </>
  );
};

export default Floater;
