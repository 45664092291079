import { mainAPIUrl } from "../../../settings";

const baseURL = `${mainAPIUrl}/crm/v1`;


export async function getOrderDetails(tokenId, orderId, type) {
	const url = `${baseURL}/orders/single?orderId=${orderId}&type=${type}`;

	var auth = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();
		return response;
	} catch (err) {
		return err.response;
	}
}
export async function getCRMData(tokenId, apiUrl) {
	const url = `${baseURL}/` + apiUrl;

	var auth = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();

		return response;
	} catch (err) {
		return err.response;
	}
}

export async function getConsumerUserDetails(tokenId, userId) {
	const url = `${baseURL}/consumer/users/single?userId=${userId}`;

	var auth = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();
		return response;
	} catch (err) {
		return err.response;
	}
}

export async function updateUser(tokenId, id, data) {
	const url = `${baseURL}/consumer/users/single?userId=${id}`;

	var auth = {
		method: "PATCH",
		headers: {
			Authorization: `Bearer ${tokenId}`,
			"Content-Type": "application/json",
		},
		body: JSON.stringify({ userInfo: data }),
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();

		return response;
	} catch (err) {
		return err.response;
	}
}

export async function getOrdersByUserId(tokenId, apiUrl) {
	const url = `${baseURL}/` + apiUrl;

	var auth = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();

		return response;
	} catch (err) {
		return err.response;
	}
}
export async function getBusinessOrdersByUserId(tokenId, apiUrl) {
	const url = `${baseURL}/` + apiUrl;

	var auth = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();

		return response;
	} catch (err) {
		return err.response;
	}
}

export async function getBusinessUserDetails(tokenId, userId) {
	const url = `${baseURL}/business/users/single?userId=${userId}`;

	var auth = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();

		return response;
	} catch (err) {
		return err.response;
	}
}
export async function completeOrder(tokenId, orderId, type) {
	const url = `${baseURL}/orders/single/complete?orderId=${orderId}&type=${type}`;

	var auth = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();
		return response;
	} catch (err) {
		return err.response;
	}
}
export async function cancelOrder(tokenId, orderId, type, data) {
	const url = `${baseURL}/orders/single/cancel?orderId=${orderId}&type=${type}`;

	var auth = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
		body: JSON.stringify({ cancelInfo: data }),
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();
		return response;
	} catch (err) {
		return err.response;
	}
}

export async function getCRMNotes(tokenId, apiUrl) {
	const url = `${baseURL}` + apiUrl;

	var auth = {
		method: "GET",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();

		return response;
	} catch (err) {
		return err.response;
	}
}

export async function addCrmNote(tokenId, id, note) {
	const url = `${baseURL}/note/single?primaryId=${id}`;

	var auth = {
		method: "POST",
		headers: {
			Authorization: `Bearer ${tokenId}`,
		},
		body: JSON.stringify({ noteInfo: note }),
	};

	try {
		const res = await fetch(url, auth);
		let response = await res.json();
		return response;
	} catch (err) {
		return err.response;
	}
}
