import styles from "./PendingVerification.module.css"

const PendingVerification = () => {
    const sendEmail = () => {
        window.open("mailto:support@gesture.vip?");
    };

    return (<div className={styles['content']}>
        <div className={styles['info-container']}>
            <h1 className={styles['info-title']}>Verification Pending</h1>
            <p className={styles['info-message']}>There seems to have been an issue with your registration process, we will reach out to you in 24-48 hours.</p>
            <button
                type="button"
                className={styles['info-button']}
                onClick={sendEmail}
            >
                Send email
            </button>
        </div>
    </div>)
}

export default PendingVerification;