import axios from "axios";
import { mainAPIUrl } from "../../../settings";

const baseURL = `${mainAPIUrl}/grunnerv3`;


export const createGRunnerUser = async (
	name,
	firstName,
	lastName,
	email,
	password
) => {
	try {
		const registrationData = {
			registrationData: { name, firstName, lastName, email, password },
		};
		const res = await axios.post(
			baseURL + "/users/signup/initial",
			registrationData
		);
		return res;
	} catch (err) {
		return err?.response;
	}
};
export const sendCode = async (tokenId, phoneNumber) => {
	try {
		let phoneNumberObject = { phoneNumber: phoneNumber };
		const res = await axios.post(
			baseURL + `/users/signup/sms_verification/dispatch/${tokenId}`,
			phoneNumberObject
		);
		return res;
	} catch (err) {
		return err?.response;
	}
};
export const verifyCode = async (tokenId, phoneNumber, code) => {
	try {
		const codeObject = {
			phoneNumber: phoneNumber,
			code: code,
		};
		const res = await axios.post(
			baseURL + `/users/signup/sms_verification/verify/${tokenId}`,
			codeObject
		);
		return res;
	} catch (err) {
		return err?.response;
	}
};
export const getAllTasksByTokenId = async (tokenId) => {
	try {
		const res = await axios.get(baseURL + `/tasks/${tokenId}`);
		return res;
	} catch (err) {
		return null;
	}
};

export const getUserByTokenId = async (tokenId) => {
	try {
		const res = await axios.get(baseURL + `/users/${tokenId}`);
		return res;
	} catch (err) {
		return null;
	}
};

export const getIdentityVerificationUrl = async (tokenId) => {
	let url = `${baseURL}/users/urls/identity/${tokenId}`;
	try {
		const res = await axios.get(url);
		return res.data;
	} catch (err) {
		return null;
	}
};

export const getBackgroundCheckUrl = async (tokenId) => {
	let url = `${baseURL}/users/urls/background_check/${tokenId}`;
	try {
		const res = await axios.get(url);
		return res.data;
	} catch (err) {
		return null;
	}
};

export const getPaymentsSetupUrl = async (tokenId) => {
	let url = `${baseURL}/users/urls/payments_setup/${tokenId}`;
	try {
		const res = await axios.get(url);
		return res.data;
	} catch (err) {
		return null;
	}
};
export const updateUserAddress = async (tokenId, address) => {
	let url = `${baseURL}/users/${tokenId}`;
	try {
		const patchData = { address: address };
		const command = "verificationState";
		const res = await axios.patch(url, { patchData, command });
		return res;
	} catch (err) {
		return null;
	}
};
export const loginGRunner = async (email, password) => {
	try {
		const loginData = {
			email,
			password,
		};
		const res = await axios.post(baseURL + "/users/login", loginData);
		return res;
	} catch (err) {
		return err?.response;
	}
};

export function resetPassword(data) {
	return axios.post(baseURL + "/users/reset_password/password", data).catch(e => e?.response || e);
};

export const getTaskByTaskIdAndTokenId = async (taskId, tokenId) => {
	try {
		const res = await axios.get(baseURL + `/tasks/single/${taskId}/${tokenId}`);
		return res;
	} catch (err) {
		console.log(err);
		return null;
	}
};

export const saveImage = async (image, tokenId, taskId) => {
	let img = image.split(",")[1];
	const data = {
		base64Image: img,
	};
	console.log(data);
	let url = `${baseURL}/tasks/${taskId}/dropoff_photo/${tokenId}`;

	try {
		const res = await axios.post(url, data);
		return res;
	} catch (err) {
		return err;
	}
};

export const getDeliveryOptions = async (taskId,tokenId) => {
	try {
		const res = await axios.get(
			baseURL + `/tasks/completion_options/${taskId}/${tokenId}`,
		);
		return res;
	} catch (err) {
		return null;
	}
};

export const completeDelivery = async (taskId, tokenId, id, title) => {
	let url = `${baseURL}/tasks/${taskId}/${tokenId}`;
	try {
		const patchData = { patchData: { coId: id, coTitle: title } };
		const res = await axios.patch(url, patchData);
		return res;
	} catch (err) {
		return null;
	}
};

// to update age verification

export const updateTaskData = async (
	taskId,
	tokenId,
	confirmed,
	confirmedTime
) => {
	let url = `${baseURL}/tasks/${taskId}/${tokenId}`;
	try {
		const patchData = {
			patchData: {
				verificationConfirmed: confirmed,
				verificationTime: confirmedTime,
			},
		};
		const res = await axios.patch(url, patchData);
		return res;
	} catch (err) {
		return err?.response;
	}
};

export const getPaymentOptionsStatistics = async (tokenId) => {
	let url = `${baseURL}/users/statistics/${tokenId}`;
	try {
		const res = await axios.get(url);
		console.log(res);
		return res;
	} catch (err) {
		console.log(err);
	}
};
export const deleteAccount = async (tokenId, deletionRequest) => {
	const url = `${baseURL}/users/${tokenId}`;
	const patchData = { patchData: { accountDeletionRequest: deletionRequest } };
	try {
		const res = await axios.patch(url, patchData);
		return res;
	} catch (err) {
		console.log(err);
		return null;
	}
};

// pickedup  grunnerv3/tasks/{taskID}/pickup
export const pickedUp = async (task , tokenId, merchantTaskId,pickupTime) => {
	let url = `${baseURL}/tasks/${task.id}/pickup/${tokenId}`;
	try {
		const res = await axios.post(url,{merchantTaskId:merchantTaskId, pickupTime:pickupTime});
		return res;
	} catch (err) {
		return err;
	}
}
