import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../DataTable";
import styles from "./BusinessUserOrders.module.css";
import ClipLoader from "react-spinners/ClipLoader";


import { getBusinessOrdersByUserId } from "../../env/APIManager";

function BusinessUserOrders({ id }) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [nextPageToken, setNextPageToken] = useState("");
	const [prevPageToken, setPrevPageToken] = useState("");
	const [hasNextPage, setHasNextPage] = useState(false);
	const [hasPrevPage, setHasPrevPage] = useState(false);
	const [token, setToken] = useState("");
	const [showActionBtn, setShowActionBtn] = useState(false);
	const [currentPageToken] = useState("");

	const history = useHistory();

	async function handleAPIResponse(res) {
		if (res?.code === "SUCCESS") {
			setData(res?.data);
			setNextPageToken(res?.data?.nextPageToken);
			setPrevPageToken(res?.data?.prevPageToken);
			setHasNextPage(res?.data?.hasMore);
			setHasPrevPage(res?.data?.hasLess);
			setLoading(false);
		} else if (res?.code === "UNAUTHORIZED") {
			localStorage.clear();
			setLoading(false);
			history.push({ pathname: "/logistics/login" });
			toast.error(
				"You have been logged out. This may have been caused by using more than one device or browser"
			);
		} else {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setData([]);
		}
	}

	const getBusinessUserOrders = async (token, string) => {
		let url;
		let data;
		try {
			setLoading(true);
			if (string === "next") {
				url = `business/users/single/sent?userId=${id}&nextToken=${nextPageToken}`;
			} else if (string === "prev") {
				url = `business/users/single/sent?userId=${id}&prevToken=${prevPageToken}`;
			} else if (string === "currentPageToken") {
				url = `business/users/single/sent?userId=${id}&currentToken=${currentPageToken}`;
			} else {
				url = `business/users/single/sent?userId=${id}`;
			}
			data = await getBusinessOrdersByUserId(token, url);

			await handleAPIResponse(data);
			setShowActionBtn(true);
		} catch (error) {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setData([]);
		}
	};

	useEffect(() => {
		let token =
			localStorage.getItem("token") == null ||
			localStorage.getItem("token") === undefined ||
			localStorage.getItem("token") === ""
				? ""
				: JSON.parse(localStorage.getItem("token"));
		setToken(token);
		if (
			currentPageToken &&
			currentPageToken !== null &&
			currentPageToken !== undefined
		) {
			getBusinessUserOrders(token, "currentPageToken");
		} else {
			getBusinessUserOrders(token);
		}
	}, []);

  const handleNextPage = () => {
		getBusinessUserOrders(token, "next");
	};

	const handlePreviousPage = () => {
		getBusinessUserOrders(token, "prev");
	};

	const handleFirstPage = () => {
		getBusinessUserOrders(token);
	};
	const handleView = (data) => {
		let orderId = data["Order ID"];
		let type = data["Type"];
		let link = "";

		if (
			prevPageToken &&
			prevPageToken !== null &&
			prevPageToken !== undefined
		) {
			link = `/logistics/crm/order-details?id=${orderId}&type=${type}`;
		} else {
			link = `/logistics/crm/order-details?id=${orderId}&type=${type}`;
		}
		window.open(link, "_blank");
	};

	return (
		<div>
			{" "}
			{loading ? (
				<div className={styles.clipLoader}>
					<ClipLoader color="#000" size={50} />
				</div>
			) : (
				<div>
					<DataTable
						data={data}
						onNextPage={handleNextPage}
						onPreviousPage={handlePreviousPage}
						onFirstPage={handleFirstPage}
						hasNextPage={hasNextPage}
						hasPrevPage={hasPrevPage}
						onView={handleView}
						showActionBtn={showActionBtn}
					/>
				</div>
			)}
		</div>
	);
}

export default BusinessUserOrders;
