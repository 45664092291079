import React from 'react';

import './Footer.css';
import appStoreLink from '../../assets/app-store-link.png';
import playStoreLink from '../../assets/play-store-link.png';

const Footer = ({ clickDisabled }) => {
  return (
    <div
      className={
        clickDisabled ? 'footer-container disabled' : 'footer-container'
      }
    >
      <div className='app-store-text'>
        <p>Sent by Gesture App, available on</p>
      </div>
      <div className='app-store-images-row'>
        <a href='https://play.google.com/store/apps/details?id=com.yourgesture.gestureuser&hl=en_US&gl=US'>
          <img src={playStoreLink} alt='play store link' />
        </a>
        <a href='https://apps.apple.com/us/app/gesture-on-demand-delivery/id1421880269'>
          <img src={appStoreLink} alt='app store link' />
        </a>
      </div>
      <div className='coupon-text'>
        <p>Here's $20 off your first Gesture to return the favor.</p>
        <p>Use promo code: RTF20</p>
      </div>
    </div>
  );
};

export default Footer;
