import React from 'react';
import styles from './AppBar.module.css';
import BackButton from '@mui/icons-material/ArrowBackIosNewOutlined';

const AppBar = (props) => {
  return (
    <div className={styles['appbar']}>
      <div className={styles['appbar-left']}>
      {!props.hide && <BackButton
          alt='back button'
          className={styles['back-button']}
          onClick={() =>props.onClickAction(false) }
        />}
      </div>
      <h1 className={styles['title']}>{props.title}</h1>
      <div className={styles['appbar-right']}></div>
    </div>
  );
};

export default AppBar;
