import React, { useEffect } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ClipLoader from "react-spinners/ClipLoader";

import styles from './InputFieldAddress.module.css';
let lonLat_value="";
function InputFieldAddress ({toggle, onAddressSelect, value}){
  const [address, setAddress] = React.useState("");
  const [locality, setLocality] = React.useState("");
  const [state, setState] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [lonLat, setLonLat] = React.useState("");
  const [address2] = React.useState('');

  const handleSelect = async (value)=>{
    const results = await geocodeByAddress(value);
    const lngLat= await getLatLng(results[0]);
    setLonLat(lngLat);
    lonLat_value=lngLat;
    const address_com=results[0].address_components;
    let address1="";
    let postcode="";
    setPostcode("")
    setCountry("")
    setLocality("")
    setState("")
    let country="";
    let locality="";
    let state="";
    
    address_com.forEach(com=>{
      console.log(com)
      if(com.types.includes("street_number")){
        address1+=com.long_name;
        
      }
      else if(com.types.includes("route")){
        address1=address1+" "+com.short_name;
      }
      else if(com.types.includes("postal_code")){
        setPostcode(com.long_name)
        postcode+=com.long_name;
    }
      else if(com.types.includes("locality") || com.types.includes("sublocality")){
        setLocality(com.long_name);
        locality+=com.long_name;
      }
      else if(com.types.includes("administrative_area_level_1")){
        setState(com.short_name);
        state+=com.long_name;
      }
      else if(com.types.includes("country")){
        setCountry(com.short_name);
        country=com.short_name;
      }
      
    } )
    if(address1 === ""){
      setAddress(results[0].formatted_address.split(",")[0]);
    }else{
      setAddress(address1);
    }
    var ad=
      {address1: address, locality: locality, state: state, postcode: postcode, country: country};
      
    setPostcode(postcode);
      // saveAddress()
      if(address1 !== ""&& locality !=="" && state !=="" && country!==""&&postcode!=="")
    setAddress(ad)
    
    
  }

  console.log("adress",address)
  useEffect(() => {
    saveAddress()
  },[address])

  useEffect(() => {
  },[address])
  const renderSuggestions = (sugg,pro) =>{
    const suggestions = sugg.map(suggestion => {
      const style={
        backgroundColor: suggestion.active ? "#F5F5FF" : "white",
        fontFamily: 'Mulish',
        fontSize: '15',
        padding: '10',
      };
      return (
        <div key={suggestion?.placeId} {...pro(suggestion, {style})}>
          <span >{suggestion.description}</span>
        </div>
      );
    })
    return (
      <>
      {suggestions}

      </>
    )
  }

  const saveAddress =()=>{
    if(address !== "" && locality!=="" && state!=="" && country!==""){
      var ad=
      {address1: address,address2:address2, locality: locality, state: state, postcode: postcode, country: country};
      if(lonLat===""){
        onAddressSelect(ad,lonLat_value);
      }else{
        onAddressSelect(ad,lonLat);
      }
      toggle();
    }
  }

  const clearInputs =(e)=>{
      //if(e.key === "Backspace"){
        // document.querySelector("#locality").value="";
        // setLocality("");
        // document.querySelector("#state").value="";
        // setState("");
        // document.querySelector("#country").value="";
        // setCountry("");
        // document.querySelector("#postcode").value="";
        // setPostcode("");
        // document.querySelector("#address2").value="";
      //} 
  }

    return (
    <div className={styles.main_container}>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div style={{width:"100%"}}>
            <form id="address-form" action="" method="get" autoComplete="off" className={styles.form_container}>
            <div className={styles.header}> 
              <button className={styles.exit_btn} onClick={()=>{toggle()}}>X</button>
            </div>
              <label className={styles.full_field}>
                <span className={styles.form_label}>Address*</span>
                <input
                  className={styles.address_input}
                  id="ship-address"
                  name="ship-address"
                  required
                  onKeyDownCapture={(e)=>clearInputs(e)}
                  autoComplete="off"
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                  })}
                />
                {suggestions.length !== 0 ? <div className={styles.autocomplete_dropdown_container}>
                  {loading && <div><ClipLoader color={'#8585ff'} size={40} /></div>}
                  {renderSuggestions(suggestions, getSuggestionItemProps)}   
                </div> : <div></div>
                }
              </label>
              
            </form>
          </div>
        )}
      </PlacesAutocomplete>
      <ToastContainer />
    </div>
    );
}


export default InputFieldAddress;
