import React from 'react';
import { motion } from 'framer-motion';

import Backdrop from './Backdrop';
import { dropIn } from '../utils';
import styles from './DeleteModal.module.css';

const DeleteModal = ({ handleClose }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={styles['modal']}
        variants={dropIn}
        initial='hidden'
        animate='visible'
        exit='exit'
        onAnimationStart={(definition) => {
          if (definition === 'visible') {
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
          }
        }}
      >
        <div className={styles['text-container']}>
          <p className={styles['text']}>
            This will delete your Personalized E-Message
          </p>
        </div>
        <div className={styles['buttons-container']}>
          <button className={styles['cancel-button']} onClick={handleClose}>
            Cancel
          </button>
          <button
            className={styles['okay-button']}
            onClick={() => {
              window?.ReactNativeWebView?.postMessage('delete');
            }}
          >
            Okay
          </button>
        </div>
      </motion.div>
    </Backdrop>
  );
};

export default DeleteModal;
