import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import { postMessageAPICall } from '../../env/APImanager';

import styles from './Chatroom.module.css';

const ChatroomTextInput = ({
  tokenid,
  feedid,
  name,
  uid,
}) => {
  const [posting, setPosting] = useState(false); // for the spinner when users click Post button
  const [message, setMessage] = useState(''); // message - text input

  let search = window.location.search;
  let parameter = new URLSearchParams(search);
  const appbarRes = parameter.get('appbar') || false;

  const appbar = appbarRes === 'true';

  const postMessageHandler = async e => {
    e.preventDefault();
    setPosting(true);

    
    try {
      if (
        tokenid === '' ||
        tokenid === undefined ||
        tokenid?.length === 0
      ) {
        window.alert('Please check if you are logged in.');
        setPosting(false);
        return;
      } else {
        const newMessage = {
          message,
          creationTime: Date.now(),
          sorter: Date.now() * -1,
          composerName: name,
          ownerUid: uid,
        };

        await postMessageAPICall(feedid, newMessage);

        setMessage('');

        document.getElementById('comments-scrollable-area').scrollTo(0, 0); // scroll to top after a new message is posted
        setPosting(false);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <div className={styles['main-comments-top']}>
        <div className={styles['main-comments-go-back']}>
          <Link
            to={`/feed?tokenId=${tokenid}&appbar=${appbar}`}
            className={styles['go-back-btn']}
          >
            <div className={styles['go-back']}>
              <i className='fas fa-chevron-circle-left fa-2x'></i>
            </div>
          </Link>

          <div className={styles['main-comments-sender-info']}>
            <p className='post-fromto-info my-1 comments-sender-info'>
              Comments
            </p>
          </div>
        </div>

        <div className={styles['comment-form']}>
          <Form
            className={styles['comment-send-form']}
            onSubmit={postMessageHandler}
          >
            <input
              type='text'
              name='text'
              value={message}
              style={{ fontSize: '16px' }}
              placeholder='Say something...'
              className={styles['comment-form-input']}
              onChange={e => setMessage(e.target.value)}
            />

            <Button
              className={styles['msg-send-button']}
              type='submit'
              disabled={message.length === 0 || posting}
            >
              {posting ? (
                <span>
                  <ClipLoader size={20} color='#8585ff' />
                </span>
              ) : (
                <span>Post</span>
              )}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChatroomTextInput;
