import React from 'react';
import { timeSince } from '../utils';

import styles from './Chatroom.module.css';

const ChatroomMessages = ({ msg }) => {
  return (
    <>
      <div className={styles['com-each-comment']} key={msg.creationTime}>
        <div className={styles['comment-profile-image']}>
          <img
            src={
              msg.profileUrl
                ? msg.profileUrl
                : //: 'https://i.imgur.com/f5f4aJl.jpg'
                  'https://firebasestorage.googleapis.com/v0/b/gesture-dev.appspot.com/o/default_user%2Fuser.png?alt=media&token=8d17ba8f-d0cf-4bb1-a024-4831872849a1'
            }
            alt='default avatar or user customized'
            style={{ width: '48px', height: '48px' }}
          />
        </div>

        <div className={styles['screenName-and-message']}>
          <div className={styles['user-and-comment']}>
            <div className={styles['username-and-screenname']}>
              <p className={styles['username-display']}>
                <span>{msg.composerName ? msg.composerName : 'Anonymous'}</span>{' '}
                <span
                  style={{
                    color: '#aaa',
                    fontSize: '0.8rem',
                    fontWeight: 'normal',
                  }}
                >
                  {msg.screenName && '@' + msg.screenName}
                </span>
              </p>
            </div>

            <div className={styles['text-comment-container']}>
              <p className={styles['com-text-comment']}>{msg.message}</p>
            </div>
          </div>

          <div className={styles['comment-footer']}>
            <small className={styles['comment-timestamp']}>
              {timeSince(msg.creationTime)}
            </small>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatroomMessages;
