import React from 'react';
import styles from './ContainerTitle.module.css'

const ContainerTitle = (props) => {
    return(
        <>
            <h1 className={styles["grunner-title"]}>{props.title}</h1>
        </>
    )
}
export default ContainerTitle;