import { useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import ReportModal from './ReportModal';
// import { handleFeedPrivacyAPICall } from '../../env/APImanager';

import './EditFeedModal.css';

const EditFeedModal = props => {
  const [reportModalShow, setReportModalShow] = useState(false);
  const [feedID, setFeedID] = useState(props.feedid);
  const [isPostPublic, setIsPostPublic] = useState(props.ispublic);

  const openReportFeedModal = () => {
    props.onHide();
    setFeedID(props.feedid);
    setReportModalShow(true);
  };

  const handleFeedPrivacy = async () => {
    await axios.post(
      `https://us-central1-gesture-dev.cloudfunctions.net/social/content/${props.feedid}/privacy?tokenId=${props.tokenid}`,
      { privacy: isPostPublic }
    );

    // const res = await handleFeedPrivacyAPICall(props)

    setIsPostPublic(!isPostPublic);

    props.onHide();
    // window.location.reload()
  };

  return (
    <>
      <ReportModal
        show={reportModalShow}
        feedid={feedID}
        tokenid={props.tokenid}
        onHide={() => setReportModalShow(false)}
      />

      <Modal
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        className='edit-feed-modal-container'
        animation={false}
      >
        <div className='edit-feed-modal-buttons-container'>
          <Modal.Body>
            {props.uid === props.feedowneruid ? (
              <button
                className='edit-feed-modal-each-button block'
                onClick={handleFeedPrivacy}
              >
                {isPostPublic ? 'Make Private' : 'Make Public'}
              </button>
            ) : (
              <button
                className='edit-feed-modal-each-button block'
                onClick={() => openReportFeedModal()}
              >
                Report Post
              </button>
            )}

            <button
              className='edit-feed-modal-each-button block'
              onClick={props.onHide}
            >
              Cancel
            </button>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default EditFeedModal;
