import React, { useEffect, useState } from "react";
import styles from "./PromoCode.module.css";

import logo from "../assets/simpleLogo.png";

import appStore from "../assets/app-store-link.png";
import playStore from "../assets/play-store-link.png";

import ClipLoader from "react-spinners/ClipLoader";

function PromoCode({ match }) {
	const [btnText, setBtnText] = useState("Copy Code");
	const [copied, setCopied] = useState(false);
	const [loading, setLoading] = useState(true);

	console.log(match);
	useEffect(() => {
		if (match?.params?.code) {
			setLoading(false);
		} else {
			window.location.replace("https://gesture.vip");
		}
	}, []);
	const handleClick = () => {
		var copyText = document.getElementById("code");
		var textArea = document.createElement("textarea");
		textArea.value = copyText.textContent;
		document.body.appendChild(textArea);
		textArea.select();
		document.execCommand("Copy");
		textArea.remove();

		setBtnText("Copied");
		setCopied(true);

		setTimeout(function () {
			setBtnText("Copy Code");
			setCopied(false);
		}, 1200);
	};
	return (
		<div className={styles.mainContainer}>
			{loading ? (
				<div className={styles.loading}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<>
					<div className={styles.logoContainer}>
						<img alt="logo" className={styles.logo} src={logo} />
					</div>
					<div className={styles.textContainer}>
						{" "}
						At checkout, enter this code:
					</div>
					<div id="code" className={styles.code}>
						{match?.params?.code}
					</div>
					<div
						className={copied ? styles.copiedBtn : styles.copyBtn}
						onClick={() => handleClick()}
					>
						<span className={styles.copyBtnText}>{btnText}</span>
					</div>
					<div className={styles.nextStepsContainer}>
						<span className={styles.hr}></span>

						<div className={styles.mainTitle}>What To Do Next:</div>
						<div className={styles.stepsContainer}>
							<span className={styles.stepsTitle}> STEP 1</span>
							<span className={styles.stepsText}>
								Open the Gesture app available on
							</span>

							<div className={styles.links}>
								<a
									target="_blank"
									href="https://play.google.com/store/apps/details?id=com.yourgesture.gestureuser&hl=en_US&gl=US" rel="noreferrer"
								>
									<img
										className={styles.storeLogos}
										src={playStore}
										alt="play store link"
									/>
								</a>
								<a
									target="_blank"
									href="https://apps.apple.com/us/app/gesture-on-demand-delivery/id1421880269" rel="noreferrer"
								>
									<img
										className={styles.storeLogos}
										src={appStore}
										alt="app store link"
									/>
								</a>
							</div>
						</div>
						<span className={styles.hrLine}></span>
						<div className={styles.stepsContainer}>
							<span className={styles.stepsTitle}> STEP 2</span>
							<span className={styles.stepsText}>
								{" "}
								Add a Gesture to your cart
							</span>
						</div>
						<span className={styles.hrLine}></span>
						<div className={styles.stepsContainer}>
							<span className={styles.stepsTitle}> STEP 3</span>
							<span className={styles.stepsText}>
								{" "}
								Enter your promo code at checkout and enjoy the discount.
							</span>
						</div>

						<div className={styles.noteContainer}>
							<p className={styles.noteTitle}>
								Note: This code will work for the first time orders only
							</p>
						</div>
						<div className={styles.linksContainer}>
							<p className={styles.link}>
								<a
									className={styles.aLink}
									target="_blank"
									href="https://help.gesture.vip/m-terms-of-use/" rel="noreferrer"
								>
									Gesture Terms of Service
								</a>
							</p>
							<p className={styles.link}>
								<a
									className={styles.aLink}
									target="_blank"
									href="https://help.gesture.vip/m-privacy-policy/" rel="noreferrer"
								>
									Privacy Policy
								</a>
							</p>
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default PromoCode;
