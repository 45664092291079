import axios from "axios";
import { mainAPIUrl } from "../../../settings";

const baseURL = `${mainAPIUrl}/vendors`;

export const getAllOrdersByTokenId = async (tokenId) => {
	
	try {
		const res = await axios.get(baseURL + `/orders/all/${tokenId}`);
		return res;
	} catch (err) {
		return null;
	}
};
export const getOrderDetailsByOrderId = async (orderId) => {
	
	try {
		const res = await axios.get(baseURL + `/orders/single/${orderId}`);
		return res;
	} catch (err) {
		return null;
	}
};