import React, { useState } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import styles from "./TaskView.module.css";

const TaskView = (props) => {
  const [deliveryTime] = useState(
    new Date(props.task.deliveredTime)
  );
  const [timeToDelivery] = useState(
    new Date(props.task.acceptedTime - props.task.deliveredTime)
  );
  
  return (
    <ListItem
      className={styles["tasks-list-item"]}
      key={"1"}
      component="div"
      disablePadding
    >
      <ListItemButton className={styles["tasks-list-item-button"]}>
        
        <div className={styles["tasks-list-item-button-div"]}>
          <ListItemText primaryTypographyProps={{fontSize: '1.6em'}}
            sx={{ color: "blue" }}
            primary={` Task #${props?.task?.id?.slice(-5)}`}
          />
          <ListItemText secondaryTypographyProps={{fontSize: '1em'}}
            secondary={` ${deliveryTime.toLocaleDateString("en-us", {
              month: "short",
              weekday: "short",
              day: "numeric",
              time: "numeric",
            })} ${deliveryTime.toLocaleTimeString("en-us")}`}
          />
          <ListItemText secondaryTypographyProps={{fontSize: '1em'}}
            secondary={
              timeToDelivery.getMinutes() > 0
                ? timeToDelivery.getMinutes() > 1
                  ? `Delivery Completed in ${timeToDelivery.getMinutes()} minutes`
                  : "Delivery Completed in 1 minute"
                : "Delivery Completed in over due"
            }
          />
        </div>
        <div className="task-list-item-button-div-price" style={{display:'flex', justifyContent:'flex-end'}}>
          <ListItemText  style={{display:'flex', justifyContent:'flex-end'}} primaryTypographyProps={{fontSize: '1.6em'}} primary={`$${props.task.amountPayedOut}`} />
          </div>
      </ListItemButton>
    </ListItem>
  );
};
export default TaskView;
