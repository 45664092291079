import React from 'react';
import styles from './AppBar.module.css';

const AppBar = (props) => {
  return (
    <div className={styles['appbar']}>
      <div className={styles['appbar-left']}>
      {/* <CloseOutlinedIcon onClick={handleClickClose}/> */}
        
      </div>
      <h1 className={styles['title']}>{props.title}</h1>
      <div className={styles['appbar-right']}>
      </div>
    </div>
  );
};

export default AppBar;
