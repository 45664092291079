import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ContainerTitle from "./ContainerTitle/ContainerTitle";
import InputField from "./InputFields/InputField";
import styles from "./LoginStyle.module.css";
import { loginGRunner } from "../../env/API";
import ClipLoader from "react-spinners/ClipLoader";
import validator from "validator";
import { motion } from "framer-motion";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import desktopStyling from "../DesktopStyles.module.css";

const LogIn = () => {
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const { push } = useHistory();
	const [toastActive, setToastActive] = useState(false);
	const [loadingWheel, setLoadingWheel] = useState(false);

	let search = window.location.search;
	let params = new URLSearchParams(search);
	const inapp = params.get("inapp") || false;
	const linkingUri = params.get("linkingUri")

	const handleNext = (event) => {
		const gRunnerUserData = {
			email: userEmail.trim(),
			password: userPassword,
		};

		setUserEmail(gRunnerUserData.email);
		setUserPassword(gRunnerUserData.password);

		setLoadingWheel(true);

		let errorMessages = {};
		errorMessages.userEmail = gRunnerUserData.email
			? validator.isEmail(gRunnerUserData.email)
				? ""
				: "Email is not valid."
			: "Email is required.";
		errorMessages.userPassword = gRunnerUserData.password
			? gRunnerUserData.password.length > 5
				? ""
				: "Password must be at least 6 characters long."
			: "Password is required.";

		let toastMessages = [];
		if (errorMessages.userEmail.length > 0) {
			toastMessages.push(
				errorMessages.userEmail ? errorMessages.userEmail : ""
			);
		}
		if (errorMessages.userPassword.length > 0) {
			toastMessages.push(
				errorMessages.userPassword ? errorMessages.userPassword : ""
			);
			setUserPassword(userPassword.trim());
		}

		if (toastMessages.length > 0) {
			for (let toastMessage of toastMessages) {
				if (toastMessage) {
					toast.error(toastMessage);
				}
			}
			setLoadingWheel(false);
			setToastActive(true);
		} else {
			handleGRunnerLogin(gRunnerUserData);
		}
	};

	const handleGRunnerLogin = async (userData) => {
		let userInfo = {
			email: userData.email,
			password: userData.password,
		};
		const res = await loginGRunner(userInfo.email, userInfo.password);
		if (res?.data?.code === "SUCCESS") {
			// push({
			// 	pathname: `/grunner/route/?tokenId=${res.data.data.user.tokenId}&inapp=${inapp}`,

			// });
			let tokenId = res.data.data.user.tokenId;
			if (linkingUri && tokenId) {
				window.location.href = `${linkingUri}?tokenId=${tokenId}`; 
			  }
			// window?.ReactNativeWebView?.postMessage(
			// 	JSON.stringify({
			// 		code: "SUCCESS",
			// 		tokenId,
			// 	})
			// );
		} else if (res?.data?.code === "ERROR") {
			if (res?.data?.data?.code === "NO_USER") {
				setToastActive(true);
				toast.error(
					"The email and password you entered did not match our records. Please try again or create a new account"
				);
				setLoadingWheel(false);
			} else if (res?.data?.data?.code === "WRONG_PASSWORD") {
				setToastActive(true);
				toast.error(
					"The password you entered did not match our records. Please try again."
				);
				setLoadingWheel(false);
			} else {
				toast.error("There was an error during login.");
				setLoadingWheel(false);
				setToastActive(true);
			}
		} else {
			toast.error("There was an error during login.");
			setLoadingWheel(false);
			setToastActive(true);
		}
	};

	const redirectPP = () => {
		push(`/grunner/privacy_policy?inapp=${inapp}`);
	};
	const redirectTOS = () => {
		push(`/grunner/terms_of_use?inapp=${inapp}`);
	};
	return (
		<>
			{loadingWheel ? (
				<div className={styles["loading-wheel"]}>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<div className={desktopStyling.mainContainer}>
					{/* <AppBar /> */}
					<motion.div className={styles["main-container"]}>
						<div>
							<ContainerTitle title="Log In" />
							<div className={styles["grunner-input-field"]}>
								<InputField
									value={userEmail}
									setValue={setUserEmail}
									field={"Email"}
									fullWidth={true}
									width={"100%"}
									type={"email"}
								/>
							</div>
							<div className={styles["grunner-input-field"]}>
								<InputField
									value={userPassword}
									setValue={setUserPassword}
									field={"Password"}
									fullWidth={true}
									width={"100%"}
									type={"password"}
								/>
							</div>
						</div>
						{toastActive ? <ToastContainer /> : <></>}

						<button className={styles["next-button"]} onClick={handleNext}>
							Next
						</button>
						<div className={styles["signup-signin-div"]}>
							<p>
								{" "}
								or{" "}
								<button
									className={styles["signup-signin-div-button"]}
									// onClick={() => push(`/grunner/signup/new?inapp=${inapp}`)}
									onClick={() => {
										window.location.href = `${linkingUri}?command=signup`; 

										// window?.ReactNativeWebView?.postMessage(
										// 	JSON.stringify({ command: "signup" })
										// );
									}}
								>
									<span className={styles["signup-signin-div-button-text"]}>
										Sign Up
									</span>
								</button>
							</p>
						</div>
						{inapp === "true" ? (
							<div className={styles.policyContainer}>
								By clicking “Next,” I agree to the{" "}
								<a className={styles.link} onClick={() => redirectTOS()}>
									Independent Contractor Agreement
								</a>{" "}
								and have read and agree to the{" "}
								<a className={styles.link} onClick={() => redirectPP()}>
									G-Runner Privacy Policy.
								</a>
							</div>
						) : (
							<div className={styles.policyContainer}>
								By clicking “Next,” I agree to the{" "}
								<a
									href="https://help.gesture.vip/m-ica-terms-of-use/"
									className={styles.link}
									target="_blank" rel="noreferrer"
								>
									Independent Contractor Agreement
								</a>{" "}
								and have read and agree to the{" "}
								<a
									className={styles.link}
									href="https://help.gesture.vip/m-grunner-privacy-policy/"
									target="_blank" rel="noreferrer"
								>
									G-Runner Privacy Policy.		
								</a>
							</div>
						)}
					</motion.div>
				</div>
			)}
		</>
	);
};

export default LogIn;
