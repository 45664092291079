import React, { useState } from "react";
import { motion } from "framer-motion";
import CloseIcon from "@mui/icons-material/CloseRounded";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import ClipLoader from "react-spinners/ClipLoader";

import { slideUp } from "../utils";
import styles from "./SearchImageCard.module.css";
import { searchImageAPICall } from "../../env/APImanager";

const SearchImageCard = ({
	handleClose,
	onValueChange,
	setDownloadLocation,
}) => {
	const [inputTxt, setInputTxt] = useState("");
	const [searchResults, setSearchResults] = useState([]);
	const [noResult, setNoResult] = useState(false);
	const [loaded, setLoaded] = useState(true);

	const handleClick = (selectedImg) => {
		onValueChange(selectedImg);
		handleClose();
	};
	const clearInput = () => {
		setInputTxt("");
		setNoResult(false);
	};
	const handleSearchClick = () => {
		let data = sessionStorage.getItem(inputTxt);
		if (!inputTxt) {
			setNoResult(false);
			setSearchResults([]);
		} else if (data === "no result") {
			setNoResult(true);
		} else if (data) {
			setNoResult(false);
			setSearchResults(JSON.parse(data));
		} else {
			setLoaded(false);
			searchImageAPICall(inputTxt)
				.then((res) => {
					setLoaded(true);
					if (res.data.images.length === 0) {
						setNoResult(true);
						sessionStorage.setItem(inputTxt, "no result");
					} else {
						setSearchResults(res.data.images);
						sessionStorage.setItem(inputTxt, JSON.stringify(res.data.images));
						setNoResult(false);
					}
				})
				.catch((err) => console.log(err));
		}
		var myDiv = document.getElementById("ImgSection");
		myDiv.scrollTop = 0;
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter") {
			handleSearchClick();
		}
	};

	const getBase64FromUrl = async (url) => {
		const data = await fetch(url);
		const blob = await data.blob();
		return new Promise((resolve) => {
			const reader = new FileReader();
			reader.readAsDataURL(blob);
			reader.onloadend = () => {
				const base64data = reader.result;
				resolve(base64data);
			};
		});
	};

	const handleImgOnClick = (data) => {
		setDownloadLocation(data.downloadUrl);
		getBase64FromUrl(data.url).then((base64) => {
			handleClick(base64);
		});
	};

	//   const [webOpen, setWebOpen] = useState(false);
	//   const [url, setURL] = useState(false);
	//   const handleWebOpenClick = () => {
	//     setWebOpen(!webOpen);
	//   };

	return (
		<motion.div
			onClick={(e) => e.stopPropagation()}
			className={styles["edit-card"]}
			variants={slideUp}
			initial="hidden"
			animate="visible"
			exit="exit"
			onAnimationStart={(definition) => {
				if (definition === "visible") {
					window.scrollTo(0, 0);
					document.body.style.overflow = "hidden";
				}
			}}
		>
			<div className={styles["header"]}>
				<CloseIcon
					className={styles["close-button"]}
					fontSize="large"
					onClick={handleClose}
				/>
				<h1 className={styles["header-text"]}>Search Image</h1>
			</div>
			<div style={{ height: "60px" }}>
				<div className={styles["search-bar-container"]}>
					<iframe
						title="iframe"
						id="iframe"
						name="my_iframe"
						style={{ display: "none" }}
					></iframe>
					<form
						action="."
						className={styles["search-bar-form"]}
						target="my_iframe"
					>
						<input
							type="search"
							className={styles["search-bar"]}
							value={inputTxt}
							placeholder={"search ..."}
							onChange={(event) => setInputTxt(event.target.value)}
							onKeyDown={handleKeyDown}
						/>
					</form>
					<ClearIcon className={styles["clear"]} onClick={clearInput} />
					<SearchIcon className={styles["icon"]} onClick={handleSearchClick} />
				</div>
			</div>
			{!loaded ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						marginTop: "30vh",
					}}
				>
					<ClipLoader size={100} color="#8585ff" />
				</div>
			) : (
				<div className={styles["content-container"]} id="ImgSection">
					{noResult ? (
						<span className={styles["no-results-txt"]}>
							No results for "{inputTxt}"
						</span>
					) : (
						searchResults.map((img) => (
							<div className={styles["image-container"]}>
								<img
									onClick={() => handleImgOnClick(img)}
									className={styles["image"]}
									src={img.url}
									alt={`By ${img.authorName} on ${img.service}`}
								/>
								{/* <label className={styles['image-txt']}>Photo by <span onClick={()=>{setURL(img.authorBackUrl);handleWebOpenClick();}}>{img.authorName}</span> on <span onClick={()=>{setURL(img.serviceBackUrl);handleWebOpenClick()}}>{img.service}</span></label> */}
								<label className={styles["image-txt"]}>
									Photo
									{img.authorName ? (
										<>
											{" "}
											by{" "}
											<span
												className={styles["link"]}
												onClick={() =>
													// window.open(img?.authorBackUrl, "_blank")
													{
														window?.ReactNativeWebView?.postMessage(
															JSON.stringify({
																url: img?.authorBackUrl,
															})
														);
													}
												}
											>
												{img.authorName}{" "}
											</span>{" "}
										</>
									) : (
										<> </>
									)}
									{img?.serviceBackUrl ? (
										<>
											on{" "}
											<span
												className={styles["link"]}
												onClick={() =>
													// window.open(img?.serviceBackUrl, "_blank")
                          {
														window?.ReactNativeWebView?.postMessage(
															JSON.stringify({
																url: img?.serviceBackUrl,
															})
														);
													}
												}
											>
												{img.service}
											</span>
										</>
									) : null}
								</label>
								<div className={styles["h-divider"]}>
									<div className={styles["shadow"]}></div>
								</div>
							</div>
						))
					)}
				</div>
			)}
			{/* {webOpen && (
          <WebOpenCard
            handleClose={handleWebOpenClick}
            url={url}
          />
        )} */}
		</motion.div>
	);
};

export default SearchImageCard;
