export const selfieVariant = {
  standard: {
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    top: '5%',
    width: '50%',
    height: '84%',
    borderRadius: '100%',
  },
  full: {
    zIndex: 4,
    display: 'block',
    alignItems: 'normal',
    overflow: 'visible',
    top: 0,
    width: '100%',
    height: '100%',
    borderRadius: '0',
  },
};

export const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
};

export const slideUp = {
  hidden: {
    y: '100vh',
  },
  visible: {
    y: '0',
    transition: {
      type: 'spring',
      damping: 30,
      stiffness: 200,
    },
  },
  exit: {
    y: '100vh',
  },
};

export const errorToastOptions = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const emptyBase64Image =
  'data:image/gif;base64,R0lGODlhAQABAIAAAP7//wAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==';

export const convertBlobToBase64 = async (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};
