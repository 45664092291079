import React, { useState, useEffect } from "react";
// import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";

import AppBar from "./AppBar/AppBar";

import styles from "./CreditCardInput.module.css";

import styled from "styled-components";

import { updateDefaultCard } from "./env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";

import SaveButton from "./SaveButton";

function CreditCardInput() {
	const stripe = useStripe();
	const elements = useElements();
	const search = window.location.search;
	const params = new URLSearchParams(search);
	const tokenId = params.get("token");

	const [pageLoading, setPageLoading] = useState(false);
	const [savingCard, setSavingCard] = useState(false);

	useEffect(() => {
		if (!stripe || !elements) {
			setPageLoading(true);
			return;
		} else {
			setPageLoading(false);
		}
	}, [stripe, elements]);

	const handleSave = async (event) => {
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet, wait for it to load
			return;
		}

		// Create a PaymentMethod with the card details
		const result = await stripe.createPaymentMethod({
			type: "card",
			card: elements.getElement(CardNumberElement),
		});
		setSavingCard(true);
		if (result?.error) {
			toast.error(result?.error?.message);
			setSavingCard(false);
			return;
		} else {
			setSavingCard(true);
			// PaymentMethod created successfully
			const paymentMethodId = result?.paymentMethod?.id;
			let nickName = `Card ending in ${result?.paymentMethod?.card?.last4}`;
			try {
				if (paymentMethodId) {
					let response = await updateDefaultCard(
						paymentMethodId,
						nickName,
						tokenId
					);
					if (response?.data?.code === "TOKEN_INVALID") {
						toast.error(
							"Your session has expired. Please reopen the app and try again"
						);

						return;
					}

					if (response.code === "SUCCESS") {
						toast.success("Card added successfully");
						const messageObject = {
							action: "close",
							nickName: nickName,
						};
						const messageString = JSON.stringify(messageObject);
						window?.ReactNativeWebView?.postMessage(messageString);
					} else if (response.code === "ERR") {
						toast.error(`Error: ${response.message}`);
					} else {
						toast.error(
							"An error occurred while adding your payment method. Please try again later."
						);
					}
				}
			} catch (error) {
				console.error("API call error:", error);
				toast.error(
					"An error occurred while adding your payment method. Please try again later."
				);
			} finally {
				setSavingCard(false);
			}
		}
	};

	const options = {
		style: {
			base: {
				fontSize: "16px",
				color: "#424770",
				letterSpacing: "0.025em",
				fontFamily: "Mulish",
				"::placeholder": {
					color: "#aab7c4",
				},
				background: "white", // White background for inputs
				border: "none", // Remove the border
				boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)", // Add a shadow to the form
				padding: "10px", // Add some padding for better spacing
				width: "100%", // Make the inputs wider
			},
			invalid: {
				color: "#9e2146",
			},
		},
	};

	const inputStyle = {
		showIcon: true,
		iconStyle: "solid",
		style: {
			color: "#000",
			base: {
				fontWeight: "500",
				fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
				fontSize: "16px",
				fontSmoothing: "antialiased",
			},

			"::placeholder": {
				color: "grey",
			},
		},
	};
	const CardInputWrapper = styled.div`
		border: 0.5px solid #2b08a0;
		border-radius: 5px;
		padding: 10px 4px;
	`;
	return (
		<>
			{pageLoading ? (
				<div className={styles.pageloading}>
					<ClipLoader size={50} color="#2b08a0" />
				</div>
			) : (
				<>
					<AppBar />
					<ToastContainer />
					<div className={styles.container}>
						<form className={styles.form} onSubmit={handleSave}>
							<div className={styles.input}>
								<div>Card number</div>
								<CardInputWrapper>
									<CardNumberElement options={inputStyle} />
								</CardInputWrapper>
							</div>
							<div className={styles.row}>
								<div className={styles.inputRow}>
									Expiration date
									<CardInputWrapper>
										<CardExpiryElement options={options} />
									</CardInputWrapper>
								</div>
								<div className={styles.inputRow}>
									CVC
									<CardInputWrapper>
										<CardCvcElement options={options} />
									</CardInputWrapper>
								</div>
							</div>
							{/* {savingCard ? (
								<div className={styles.loading}>
									<ClipLoader size={30} color="#2b08a0" />
								</div>
							) : (
								<div className={styles.btnContainer}>
									<button
										className={styles.btn}
										type="submit"
										disabled={savingCard || !stripe || !elements}
									>
										Save
									</button>
								</div>
							)} */}
							<SaveButton savingCard={savingCard} onSave={handleSave} />
						</form>
					</div>
				</>
			)}
		</>
	);
}

export default CreditCardInput;
